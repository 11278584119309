export const remakeCols = [
  {
    id: 'invoice',
    labelContent: 'Original Invoice *',
    className: 'col-14 col-lg-7 order-3',
  },
  {
    id: 'reason',
    labelContent: 'Reason *',
    className: 'col-14 col-lg-7 order-3',
  },
];
