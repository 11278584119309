import * as Yup from 'yup';

import {
  address,
  city,
  email,
  firstName,
  lastName,
  phone,
} from 'constants/checkout/shippingAddress/shippingAddressCols';

import isEmptyField from '../errorMessages/isEmptyField';

const ShippingAddressSchema = Yup.object().shape({
  [city.id]: isEmptyField(),
  [email.id]: isEmptyField(),
  [phone.id]: isEmptyField(),
  [address.id]: isEmptyField(),
  [lastName.id]: isEmptyField(),
  [firstName.id]: isEmptyField(),
});

export default ShippingAddressSchema;
