import OPTION_STATES from 'constants/sidePanel/optionStates';
import {
  IProductOption,
  ISelectedOption,
  OptionGroup,
  OptionState,
} from 'types/general';

class ProductOptionService {
  getFilteredProductOptionGroups(
    optionGroups: OptionGroup[],
    exceptions: string[],
  ): OptionGroup[] {
    return optionGroups.filter(
      ({ productOptionGroupType }) =>
        !exceptions.includes(productOptionGroupType),
    );
  }

  getOptionGroupByType(
    optionGroups: OptionGroup[],
    productOptionGroupTypeParam: string,
  ): OptionGroup | null {
    return (
      optionGroups.find(
        ({ productOptionGroupType, displayName }) =>
          productOptionGroupType === productOptionGroupTypeParam ||
          displayName === productOptionGroupTypeParam,
      ) || null
    );
  }

  getOptionStateValue(
    optionsStates: OptionState[],
    optionStateParam: string,
  ): boolean {
    return optionsStates.some(
      ({ optionState, value }) =>
        optionState === optionStateParam && value,
    );
  }

  getProductOptionById(
    optionGroups: OptionGroup[],
    productOptionGroupType: string,
    productOptionIdParam: string,
  ): IProductOption | null {
    const optionGroup = this.getOptionGroupByType(
      optionGroups,
      productOptionGroupType,
    );

    return (
      optionGroup?.productOptions.find(
        ({ productOptionId }) =>
          productOptionId === productOptionIdParam,
      ) || null
    );
  }

  getSelectedProductOptionByType(
    optionGroups: OptionGroup[],
    productOptionGroupType: string,
  ): IProductOption | null {
    const optionGroup = this.getOptionGroupByType(
      optionGroups,
      productOptionGroupType,
    );

    return (
      optionGroup?.productOptions.find(({ optionsStates }) =>
        this.getOptionStateValue(
          optionsStates,
          OPTION_STATES.IS_SELECTED,
        ),
      ) || null
    );
  }

  getSelectedProductOptions(
    optionGroups: OptionGroup[],
  ): ISelectedOption[] {
    return optionGroups.reduce(
      (
        acc: ISelectedOption[],
        { productOptionGroupId, productOptions },
      ): ISelectedOption[] => {
        const selectedProductOption = productOptions.find(
          ({ optionsStates }) =>
            this.getOptionStateValue(
              optionsStates,
              OPTION_STATES.IS_SELECTED,
            ),
        );

        if (!selectedProductOption) return acc;

        const dynamicValue =
          selectedProductOption.productOptionDynamicValue;

        const selectedOption = {
          productOptionGroupId,
          productOptionId: selectedProductOption.productOptionId,
        };

        const updSelectedOption = dynamicValue
          ? {
              ...selectedOption,
              productOptionDynamicValue: dynamicValue,
            }
          : selectedOption;

        return [...acc, updSelectedOption];
      },
      [],
    );
  }

  prepareSelectedOptions(
    selectedOptions: ISelectedOption[],
  ): ISelectedOption[] {
    return selectedOptions.map(
      ({ productOptionId, productOptionGroupId }) => ({
        productOptionId,
        productOptionGroupId,
      }),
    );
  }
}

export default new ProductOptionService();
