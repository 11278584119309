// custom corners for showing rotate icons by hover

const cornerSize = {
  width: 50,
  height: 50,
  fill: 'red',
};

const opacity = 0;

export const topLeftCorner = {
  id: 'topLeftCorner',
  opacity,
  ...cornerSize,
};

export const topRightCorner = {
  id: 'topRightCorner',
  opacity,
  ...cornerSize,
};

export const bottomRightCorner = {
  id: 'bottomRightCorner',
  opacity,
  ...cornerSize,
};

export const bottomLeftCorner = {
  id: 'bottomLeftCorner',
  opacity,
  ...cornerSize,
};

const customCorners = [
  topLeftCorner,
  topRightCorner,
  bottomRightCorner,
  bottomLeftCorner,
];

export default customCorners;
