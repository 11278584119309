import { ISize } from 'types/editor';

class ImageSizeService {
  isVerticalShape({ width, height }: ISize): boolean {
    if (width > height) return false;

    return true;
  }

  getResponsiveHorizontalImageSize(
    containerSize: ISize,
    imageSize: ISize,
    padding: number,
  ): ISize {
    const neededWidth = containerSize.width - padding;

    const newImageHeight =
      (imageSize.height * neededWidth) / imageSize.width;

    if (newImageHeight > containerSize.height) {
      const neededHeight = containerSize.height - padding;

      const newImageWidth =
        (imageSize.width * neededHeight) / imageSize.height;

      return { width: newImageWidth, height: neededHeight };
    }

    return { width: neededWidth, height: newImageHeight };
  }

  getResponsiveVerticalImageSize(
    containerSize: ISize,
    imageSize: ISize,
    padding: number,
  ): ISize {
    const neededHeight = containerSize.height - padding;

    const newImageWidth =
      (imageSize.width * neededHeight) / imageSize.height;

    return { width: newImageWidth, height: neededHeight };
  }

  getResponsiveImageSizeByOrientation(
    imageSize: ISize,
    containerSize: ISize,
    padding = 0,
  ): ISize {
    const isVerticalImage = this.isVerticalShape(imageSize);

    const responsiveImageSize = isVerticalImage
      ? this.getResponsiveVerticalImageSize(
          containerSize,
          imageSize,
          padding,
        )
      : this.getResponsiveHorizontalImageSize(
          containerSize,
          imageSize,
          padding,
        );

    const isImageWidthMore =
      responsiveImageSize.width >= containerSize.width;
    const isImageHeightMore =
      responsiveImageSize.height >= containerSize.height;

    const widthDiff = isImageWidthMore
      ? 0
      : Math.abs(containerSize.width - responsiveImageSize.width);

    const heightDiff = isImageHeightMore
      ? 0
      : Math.abs(containerSize.height - responsiveImageSize.height);

    if (heightDiff <= widthDiff) {
      return this.getResponsiveVerticalImageSize(
        containerSize,
        imageSize,
        padding,
      );
    }

    return this.getResponsiveHorizontalImageSize(
      containerSize,
      imageSize,
      padding,
    );
  }
}

export default new ImageSizeService();
