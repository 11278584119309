const tax = {
  id: 'tax',
  title: 'Tax',
  value: 0,
};

const shipping = {
  id: 'shipping',
  title: 'Shipping',
  value: 0,
};

const total = {
  id: 'total',
  title: 'Total',
  value: 0,
};

const cartSidebarItems = [tax, shipping, total];

export default cartSidebarItems;
