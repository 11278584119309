import { IDropdownItem } from 'types/checkout/general';

const getUpdatedDropDownItems = (
  dropdownItems: IDropdownItem[],
  dropdownItemId: string,
): IDropdownItem[] =>
  dropdownItems.map((dropdownItem: IDropdownItem) => {
    if (dropdownItem.id === dropdownItemId) {
      return {
        ...dropdownItem,
        isSelected: true,
      };
    }

    return {
      ...dropdownItem,
      isSelected: false,
    };
  });

export default getUpdatedDropDownItems;
