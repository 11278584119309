import { useCallback } from 'react';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppSelector from 'hooks/redux/useAppSelector';

import {
  canvasWrapsBorder,
  edgeOptionsId,
} from 'constants/editor/canvasWraps';
import {
  safetyPixelValuesSelector,
  selectedEdgeSelector,
  trimPixelValuesSelector,
} from 'redux/sidePanel';

import useEditMode from '../useEditMode';

const useRenderProduct = (): any => {
  const { mainLayerRef } = useEditorRefs();

  const safetyPixelValues = useAppSelector(safetyPixelValuesSelector);
  const trimPixelValues = useAppSelector(trimPixelValuesSelector);
  const selectedEdgeOption = useAppSelector(selectedEdgeSelector);

  const [isEditMode] = useEditMode();

  const renderCanvasWraps = useCallback(
    (ctx, width, height) => {
      const mainLayer = mainLayerRef.current;

      if (!mainLayer) return null;

      const [IMAGE] = edgeOptionsId;

      const showBorders =
        IMAGE === selectedEdgeOption?.productOptionId;

      if (!showBorders) {
        return ctx.rect(0, 0, width, height);
      }

      const [
        topCtxWrapperPixels,
        rightCtxWrapperPixels,
        bottomCtxWrapperPixels,
        leftCtxWrapperPixels,
      ] = safetyPixelValues;

      const [
        topTrimPixels,
        rightTrimPixels,
        bottomTrimPixels,
        leftTrimPixels,
      ] = trimPixelValues;

      const faceWidth =
        width -
        rightCtxWrapperPixels -
        leftCtxWrapperPixels -
        rightTrimPixels -
        leftTrimPixels;

      const faceHeight =
        height -
        topCtxWrapperPixels -
        bottomCtxWrapperPixels -
        topTrimPixels -
        bottomTrimPixels;

      if (isEditMode) {
        const scale = mainLayer.scaleX();

        const border = canvasWrapsBorder / scale;

        const halfBorder = border / 2;

        // top rect
        ctx.rect(
          leftCtxWrapperPixels + leftTrimPixels,
          topTrimPixels - halfBorder,
          faceWidth,
          topCtxWrapperPixels,
        );

        // right rect
        ctx.rect(
          leftCtxWrapperPixels +
            faceWidth +
            leftTrimPixels +
            halfBorder,
          topCtxWrapperPixels + topTrimPixels,
          rightCtxWrapperPixels,
          faceHeight,
        );

        // middle(face) rect
        ctx.rect(
          leftCtxWrapperPixels + leftTrimPixels,
          topCtxWrapperPixels + topTrimPixels,
          faceWidth,
          faceHeight,
        );

        ctx.lineWidth = border;
        ctx.strokeStyle = 'white';

        ctx.strokeRect(
          leftCtxWrapperPixels + leftTrimPixels,
          topCtxWrapperPixels + topTrimPixels,
          faceWidth,
          faceHeight,
        );

        // bottom rect
        ctx.rect(
          leftCtxWrapperPixels + leftTrimPixels,
          topCtxWrapperPixels +
            faceHeight +
            topTrimPixels +
            halfBorder,
          faceWidth,
          bottomCtxWrapperPixels,
        );

        // left rect
        ctx.rect(
          leftTrimPixels - halfBorder,
          leftCtxWrapperPixels + topTrimPixels,
          leftCtxWrapperPixels,
          faceHeight,
        );

        ctx.lineWidth = border;
        ctx.strokeStyle = 'white';
        return ctx.stroke();
      }

      // product rect
      return ctx.rect(
        topCtxWrapperPixels + topTrimPixels,
        leftCtxWrapperPixels + leftTrimPixels,
        faceWidth,
        faceHeight,
      );
    },
    [
      isEditMode,
      mainLayerRef,
      trimPixelValues,
      safetyPixelValues,
      selectedEdgeOption,
    ],
  );

  const renderMetalPrint = useCallback(
    (ctx, width, height) => {
      const [
        topTrimPixels,
        rightTrimPixels,
        bottomTrimPixels,
        leftTrimPixels,
      ] = trimPixelValues;

      const totalTrimWidth = rightTrimPixels + leftTrimPixels;
      const totalTrimHeight = topTrimPixels + bottomTrimPixels;

      ctx.rect(
        leftTrimPixels,
        topTrimPixels,
        width - totalTrimWidth,
        height - totalTrimHeight,
      );
    },
    [trimPixelValues],
  );

  const renderAcrylicPrint = useCallback(
    (ctx, width, height) => ctx.rect(0, 0, width, height),
    [],
  );

  return {
    renderCanvasWraps,
    renderAcrylicPrint,
    renderMetalPrint,
  };
};

export default useRenderProduct;
