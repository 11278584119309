import { Dispatch } from '@reduxjs/toolkit';

import { setIsCreatedCart } from 'redux/auth/authReducer';
import { GetState } from 'redux/types';

import CartService from 'api/CartService';
import { ILineItem } from 'types/cart';

import {deleteCart, setCart} from './cartReducer';

export const createCartAsync =
  (sessionId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const cart = await CartService.createCart(sessionId);

    if (!cart) return;

    dispatch(setCart(cart));
    dispatch(setIsCreatedCart(true));
  };

export const getCartAsync =
  () =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { sessionId } = getState().auth.session;

    if (!sessionId) return;

    const cart = await CartService.getCart(sessionId);

    if (!cart) return;

    dispatch(setCart(cart));
  };

export const addLineItemAsync =
  (lineItem: ILineItem) =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { auth } = getState();

    const { sessionId } = auth.session;

    if (!sessionId) return;

    const cart = await CartService.addLineItem(sessionId, lineItem);

    if (!cart) return;

    dispatch(setCart(cart));
  };

export const deleteCartAsync =
  (sessionId: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      const cart = await CartService.deleteCart(sessionId);

      dispatch(deleteCart())
    }

export const deleteLineItemAsync =
  (lineItemId: number) =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { sessionId } = getState().auth.session;

    if (!sessionId) return;

    const cart = await CartService.deleteLineItem(
      sessionId,
      lineItemId,
    );

    if (!cart) return;

    dispatch(setCart(cart));
  };

export const updateLineItemAsync =
  (lineItem: any) =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { sessionId } = getState().auth.session;

    if (!sessionId) return;

    const cart = await CartService.updateLineItem(
      sessionId,
      lineItem,
    );

    if (!cart) return;

    dispatch(setCart(cart));
  };
