import { FC, useCallback, useState } from 'react';
import { Form } from 'formik';

import { accountInformationCols } from 'constants/checkout/orderInformation/accountInformationCols';
import { orderInformationCols } from 'constants/checkout/orderInformation/orderInformationCols';
import { remakeCols } from 'constants/checkout/orderInformation/remakeCols';
import { SECOND_FAZE } from 'constants/temp';

import CheckoutTitle from 'pages/Checkout/components/CheckoutTitle';
import ColumnList from 'pages/Checkout/components/ColumnList';

import { Children } from 'types/general';

import AttachFiles from '../AttachFiles';
import PayCheck from '../PayCheck';

interface IProps {
  children: Children;
}

const OrderInformationForm: FC<IProps> = ({ children }) => {
  const [isRemake, setIsRemake] = useState(false);

  const changeRemakeHandler = useCallback(
    () => setIsRemake(!isRemake),
    [isRemake],
  );

  return (
    <Form>
      <div className="check-shipping-inputs">
        <ColumnList columnList={accountInformationCols} />
        <div
          style={{
            width: '100%',
            order: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 20,
          }}
        >
          <a
            href="https://myaccount.hhcolorlab.com/PasswordReset"
            target="_blank"
            rel="noreferrer"
            className="check-link"
          >
            Forgot Password
          </a>
        </div>
        <div className="col-14 check-col-middle check-col order-1">
          <CheckoutTitle title="Order Information" />
        </div>
        <ColumnList columnList={orderInformationCols} />
        {children}
        <PayCheck
          isRemake={isRemake}
          handleChangeRemake={changeRemakeHandler}
        />
        {isRemake && <ColumnList columnList={remakeCols} />}
      </div>
      {SECOND_FAZE && <AttachFiles />}
    </Form>
  );
};

export default OrderInformationForm;
