import { FC, useRef, useState } from 'react';

import { IConfirmPopupState } from 'types/editor/confirmPopupState';
import { IContextProps } from 'types/contextProps';
import { IRef } from 'types/ref';

import { ConfirmPopupContext } from 'context/contexts/editor/general';
import ConfirmModal from 'components/ConfirmModal';

const ConfirmPopupContextProvider: FC<IContextProps> = ({
  children,
}) => {
  const [confirmPopupState, setConfirmPopupState] =
    useState<IConfirmPopupState | null>(null);

  const ref = useRef<IRef>(null);

  const handleShowPopup = (state: IConfirmPopupState) => {
    setConfirmPopupState(state);

    ref?.current?.show();
  };

  const handleHidePopup = () => {
    ref?.current?.hide();

    setConfirmPopupState(null);
  };

  const text = confirmPopupState?.text || '';

  const confirmHandler =
    confirmPopupState?.confirmHandler || (() => {});

  const updatedConfirmHandler = () => {
    confirmHandler();

    ref?.current?.hide();
  };

  return (
    <ConfirmPopupContext.Provider value={handleShowPopup}>
      <ConfirmModal
        ref={ref}
        text={text}
        cancelHandler={handleHidePopup}
        confirmHandler={updatedConfirmHandler}
      />
      {children}
    </ConfirmPopupContext.Provider>
  );
};

export default ConfirmPopupContextProvider;
