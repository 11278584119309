import { useCallback, useEffect, useState, FC } from 'react';
import { Formik, FormikValues } from 'formik';
import { useHistory } from 'react-router-dom';

import OrderInformationSchema from 'utils/validations/schemes/OrderInformationSchema';
import getUpdatedDropDownItems from 'utils/checkout/getUpdatedDropdownItems';
import setTouchedFields from 'utils/checkout/setTouchedFields';
import getInitialValues from 'utils/formik/getInitialValues';

import { accountInformationCols } from 'constants/checkout/orderInformation/accountInformationCols';
import { orderInformationCols } from 'constants/checkout/orderInformation/orderInformationCols';
import dropDownsList from 'constants/checkout/orderInformation/dropDownFields';
import CHECKOUT_ROUTES from 'constants/routes/checkout/routes';
import { ROUTES } from 'constants/routes/app/routes';

import Dropdown from 'components/Dropdown/Dropdown';
import StepControls from 'components/StepControls';

import { sessionIdSelector } from 'redux/auth';
import { cartIdSelector } from 'redux/cart';

import orderInformationService from 'api/checkout/OrderInformationService';
import { IOrderInformation } from 'types/checkout/orderInformation';
import ErrorPopup from 'pages/Checkout/components/ErrorPopup';
import useAppSelector from 'hooks/redux/useAppSelector';

import CheckoutTitle from '../../components/CheckoutTitle';
import OrderInformationForm from './components/OrderInformationForm';

const OrderInformation: FC = () => {
  const [orderInformation, setOrderInformation] =
    useState<IOrderInformation | null>(null);

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const sessionId = useAppSelector(sessionIdSelector);
  const cartId = useAppSelector(cartIdSelector);

  const history = useHistory();

  const nextStepHandler = useCallback(
    () => history.push(CHECKOUT_ROUTES.SHIPPING_ADDRESS),
    [history],
  );

  const previousStepHandler = useCallback(
    () => history.push(ROUTES.CART),
    [history],
  );

  const hideLoginErrorHandler = useCallback(
    () => setLoginError(false),
    [],
  );

  const hideErrorPopupHandler = useCallback(
    () => setShowErrorPopup(false),
    [],
  );

  const selectDropdownItemHandler = useCallback(
    (dropdownId: string, dropdownItemId: string) => {
      if (!orderInformation) return;

      const dropdownItems = orderInformation[dropdownId];

      if (!Array.isArray(dropdownItems)) return;

      const updatedDropdownItems = getUpdatedDropDownItems(
        dropdownItems,
        dropdownItemId,
      );

      const updatedOrderInformation = {
        ...orderInformation,
        [dropdownId]: updatedDropdownItems,
      };

      setOrderInformation(updatedOrderInformation);
    },
    [orderInformation],
  );

  const submitHandler = useCallback(
    async ({
      userName,
      password,
      studio,
      description,
      coupon,
    }: FormikValues) => {
      if (!orderInformation || !sessionId || !cartId) return null;

      const { rush, colorCorrection } = orderInformation;

      const rushItem = rush.find(({ isSelected }) => isSelected);

      const colorCorrectionItem = colorCorrection.find(
        ({ isSelected }) => isSelected,
      );

      if (!rushItem || !colorCorrectionItem) return null;

      const requestBody = {
        userName,
        password,
        couponCode: coupon,
        rush: rushItem.id,
        studioOrderNumber: studio,
        orderDescription: description,
        colorCorrection: colorCorrectionItem.id,
      };

      const { loggedIn } =
        await orderInformationService.putOrderInformation(
          sessionId,
          cartId,
          requestBody,
        );

      if (!loggedIn) {
        return setLoginError(true);
      }

      return nextStepHandler();
    },
    [cartId, nextStepHandler, orderInformation, sessionId],
  );

  const validateHandler = useCallback(
    async (handleSubmit, validateForm, setTouched) => {
      const errors = await validateForm();

      const isError = Object.values(errors).length;

      if (isError) {
        setShowErrorPopup(true);

        return setTouched(
          setTouchedFields([
            ...accountInformationCols,
            ...orderInformationCols,
          ]),
        );
      }

      setShowErrorPopup(false);

      return handleSubmit();
    },
    [],
  );

  const createInitialValues = () => {
    const defaultAccountInformation = getInitialValues(
      accountInformationCols,
    );

    const defaultOrderInformation = getInitialValues(
      orderInformationCols,
    );

    if (!orderInformation) {
      return {
        ...defaultAccountInformation,
        ...defaultOrderInformation,
      };
    }

    return {
      ...defaultAccountInformation,
      studio: orderInformation.studioOrderNumber,
      description: orderInformation.orderDescription,
      coupon: orderInformation.coupon.couponCode,
    };
  };

  const DropDownList = dropDownsList.map(
    ({ id, className, labelContent }) => {
      const dropDownItems = orderInformation?.[id] || [];

      const updatedDropdownItems = Array.isArray(dropDownItems)
        ? dropDownItems
        : [];

      return (
        <Dropdown
          id={id}
          key={id}
          name={id}
          label={labelContent}
          className={className}
          dropDownItems={updatedDropdownItems}
          selectDropdownItem={selectDropdownItemHandler}
        />
      );
    },
  );

  useEffect(() => {
    if (!sessionId) return;

    orderInformationService
      .getOrderInformation(sessionId)
      .then((data) => data && setOrderInformation(data));
  }, [sessionId]);

  return (
    <Formik
      enableReinitialize
      validateOnBlur={false}
      onSubmit={submitHandler}
      initialValues={createInitialValues()}
      validationSchema={OrderInformationSchema}
    >
      {({ handleSubmit, validateForm, setTouched }) => (
        <>
          <div className="check-body">
            {loginError && (
              <ErrorPopup
                message="Invalid username or password!"
                hideErrorMessageHandler={hideLoginErrorHandler}
              />
            )}
            {showErrorPopup && (
              <ErrorPopup
                message="Please fill in all required fields."
                hideErrorMessageHandler={hideErrorPopupHandler}
              />
            )}
            <div className="check-titles-container">
              <CheckoutTitle title="H&H Account information" />
              <a
                href="https://myaccount.hhcolorlab.com/customersignup"
                target="_blank"
                className="check-link"
                rel="noreferrer"
              >
                Don&apos;t have an H&amp;H account? Click here
              </a>
            </div>
            <OrderInformationForm>
              {DropDownList}
            </OrderInformationForm>
          </div>
          <StepControls
            isSubmit
            nextStepHandler={() =>
              validateHandler(handleSubmit, validateForm, setTouched)
            }
            className="check-footer"
            previousStepContent="Back to Cart"
            nextStepContent="Shipping ->"
            previousStepHandler={previousStepHandler}
          />
        </>
      )}
    </Formik>
  );
};

export default OrderInformation;
