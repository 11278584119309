import cn from 'classnames';
import { animated } from '@react-spring/web';
import { FC } from 'react';

import productOptionsService from 'utils/sidePanel/ProductOptionService';
import OPTION_STATES from 'constants/sidePanel/optionStates';
import { IProductOption } from 'types/general';

interface IProps {
  disable: boolean;
  isClicked: boolean;
  animations: any;
  setIsClicked: (isClicked: boolean) => void;
  setCardId: (id: string) => void;
  productOptions: IProductOption;
  selectProductOptionHandler: (
    productOptions: IProductOption,
  ) => void;
}

const SizeCard: FC<IProps> = ({
  disable,
  isClicked,
  setCardId,
  animations,
  setIsClicked,
  productOptions,
  selectProductOptionHandler,
}: IProps) => {
  const { optionsStates, price, displayName, productOptionId } =
    productOptions;

  const isActive =
    productOptionsService.getOptionStateValue(
      optionsStates,
      OPTION_STATES.IS_ACTIVE,
    ) && !disable;

  const isSelected = productOptionsService.getOptionStateValue(
    optionsStates,
    OPTION_STATES.IS_SELECTED,
  );
  const finalPrice = price && `$${price.minPrice}`;

  const handleClick = () => {
    if (!isActive) return;

    setCardId(productOptionId);
    setIsClicked(true);

    setTimeout(() => {
      setIsClicked(false);
      selectProductOptionHandler(productOptions);
    }, 500);
  };

  return (
    <animated.div
      style={{ ...animations }}
      onClick={handleClick}
      className={cn('product-size-card', {
        'is-active': isSelected,
        'is-disabled': !isActive,
        'is-animated': isClicked,
      })}
    >
      <span className="product-size-scale">{displayName}</span>
      <span className="product-size-price">{finalPrice}</span>
    </animated.div>
  );
};

export default SizeCard;
