/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IThemeState {
  isDarkTheme: boolean;
}

const initialState: IThemeState = {
  isDarkTheme: false,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setIsDarkTheme: (state, { payload }: PayloadAction<boolean>) => {
      state.isDarkTheme = payload;
    },
  },
});

export const { setIsDarkTheme } = themeSlice.actions;

export default themeSlice.reducer;
