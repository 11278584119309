import { ICoordinates } from 'types/editor';

class RotateService {
  getDegrees(rad: number): number {
    const degrees = Math.round(rad * (180 / Math.PI));

    return degrees;
  }

  getRadians(degrees: number): number {
    const rad = degrees * (Math.PI / 180);

    return rad;
  }

  convertRotationConception(rotation: number): number {
    const convertedDegrees = (rotation % 360) + 360;

    return convertedDegrees;
  }

  getDegreesDependingOnCursor(
    shapeCenterPoint: ICoordinates,
    cursorPosition: ICoordinates,
  ): number {
    const rad = Math.atan2(
      cursorPosition.x - shapeCenterPoint.x,
      shapeCenterPoint.y - cursorPosition.y,
    );

    const newDegrees = this.getDegrees(rad);

    return newDegrees;
  }

  rotationValidation(degrees: number): number {
    if (degrees >= 360) {
      return degrees % 360;
    }

    if (degrees <= 360) {
      return degrees % -360;
    }

    return degrees;
  }

  getRotatePoint(
    { x, y }: ICoordinates,
    radians: number,
  ): ICoordinates {
    const rcos = Math.cos(radians);
    const rsin = Math.sin(radians);

    const rotatePoint = {
      x: x * rcos - y * rsin,
      y: y * rcos + x * rsin,
    };

    return rotatePoint;
  }

  getShiftRotationPoint = (
    freePoint: ICoordinates,
    degrees: number,
    byDegrees: number,
  ): ICoordinates => {
    const negativeFreePoint = {
      x: -freePoint.x,
      y: -freePoint.y,
    };

    const current = this.getRotatePoint(
      negativeFreePoint,
      this.getRadians(degrees),
    );

    const rotated = this.getRotatePoint(
      negativeFreePoint,
      this.getRadians(byDegrees),
    );

    const dx = rotated.x - current.x;
    const dy = rotated.y - current.y;

    return { x: dx, y: dy };
  };
}

export default new RotateService();
