import { FC } from 'react';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useAppSelector from 'hooks/redux/useAppSelector';

import { lineItemsSelector } from 'redux/cart';
import { ILineItem } from 'types/cart';

import CartTableBody from './CartTableBody';
import CartTableHead from './CartTableHead';

const CartTable: FC = () => {
  const lineItems = useAppSelector(lineItemsSelector);

  const { isMobile } = useDefineDeviceByWindowSize();

  const LineItemsList = lineItems.map((lineItem: ILineItem) => (
    <CartTableBody key={lineItem.lineItemId} {...lineItem} />
  ));

  return (
    <table className="cart-table">
      {!isMobile && <CartTableHead />}
      {LineItemsList}
    </table>
  );
};

export default CartTable;
