import { KonvaEventObject } from 'konva/lib/Node';
import { useCallback, useEffect, useState } from 'react';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useRotation from 'hooks/editor/rotation/useRotation';

import rotateService from 'utils/editor/rotate/RotateService';
import shapePointsService from 'utils/editor/coordinates/ShapePointsService';
import getActualShapePoints from 'utils/editor/intersectionChecks/getActualShapePoints';
import getActualShapeSize from 'utils/editor/intersectionChecks/getActualShapeSize';

import rotateIconsData from 'constants/editor/rotateIcons';
import customCorners from 'constants/editor/customCorners';

import { ImageRefType, RectRefType } from 'types/editor/refTypes';

import useEditMode from '../useEditMode';

const useRotateIcons = (
  rotateIconsRefs: ImageRefType[],
  customCornerRefs: RectRefType[],
): void => {
  const [degreesDiff, setDegreesDiff] = useState(0);
  const [isDown, setIsDown] = useState(false);

  const { stageRef, mainLayerRef, imageWrapperRef } = useEditorRefs();

  const [, setRotation] = useRotation();
  const [isEditMode, setIsEditMode] = useEditMode();

  const getCornersUnitedRotateIcons = useCallback(
    () =>
      customCorners.map((corner, index) => {
        const rotateIconRef = rotateIconsRefs[index];

        const rotateIcon = rotateIconRef.current;

        return {
          ...corner,
          rotateIcon,
        };
      }),
    [rotateIconsRefs],
  );

  const hideRotateIcons = useCallback(() => {
    rotateIconsRefs.forEach((iconRef: ImageRefType) => {
      const icon = iconRef.current;

      if (!icon) return;

      const visible = icon.visible();

      if (!visible) return;

      icon.visible(!visible);
    });
  }, [rotateIconsRefs]);

  const getNewDegrees = useCallback(() => {
    const imageWrapper = imageWrapperRef.current;
    const mainLayer = mainLayerRef.current;
    const stage = stageRef.current;

    if (!imageWrapper || !mainLayer || !stage) return 0;

    const imageWrapperScale = imageWrapper.scale();
    const mainLayerScale = mainLayer.scale();

    if (!mainLayerScale || !imageWrapperScale) return 0;

    const currentDegrees = imageWrapper.rotation();

    const rad = rotateService.getRadians(currentDegrees);

    const imageSize = getActualShapeSize(
      imageWrapper.size(),
      imageWrapperScale,
      mainLayerScale,
    );

    const shapePoints = getActualShapePoints({
      rad,
      ...imageSize,
      ...imageWrapper.getAbsolutePosition(),
    });

    const shapeCenterPoint =
      shapePointsService.getShapeCenterPoint(shapePoints);

    const cursorPosition = stage.getPointerPosition();

    if (!cursorPosition) return 0;

    const degrees = rotateService.getDegreesDependingOnCursor(
      shapeCenterPoint,
      cursorPosition,
    );

    const newDegrees = degrees - currentDegrees;

    return newDegrees;
  }, [imageWrapperRef, mainLayerRef, stageRef]);

  const iconMouseEnterHandler = useCallback(() => {
    const degrees = getNewDegrees();

    setDegreesDiff(degrees);
  }, [getNewDegrees]);

  const iconMouseDownHandler = useCallback(() => {
    const imageWrapper = imageWrapperRef.current;

    if (!imageWrapper) return;

    imageWrapper.draggable(false);
    setIsDown(true);
  }, [imageWrapperRef]);

  const showIconHandler = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const id = e.target.id();

      getCornersUnitedRotateIcons().forEach((corner) => {
        if (id !== corner.id) return;

        corner.rotateIcon?.visible(true);
      });
    },
    [getCornersUnitedRotateIcons],
  );

  const stageMouseupHandler = useCallback(() => {
    const imageWrapper = imageWrapperRef.current;

    if (!imageWrapper || !isDown) return;

    setIsDown(false);
    setIsEditMode(true);
    imageWrapper.draggable(true);
  }, [imageWrapperRef, isDown, setIsEditMode]);

  const stageMouseMoveHandler = useCallback(
    (e) => {
      const imageWrapper = imageWrapperRef.current;

      if (!imageWrapper) return null;

      const id = e.target.id();

      const customCornersId = customCorners.map(
        (customCorner) => customCorner.id,
      );

      const rotateIconsId = rotateIconsData.map(
        (rotateIcon) => rotateIcon.id,
      );

      const isCustomCorner = customCornersId.includes(id);
      const isRotateIcon = rotateIconsId.includes(id);

      if (!isDown) {
        if (!isCustomCorner && !isRotateIcon)
          return hideRotateIcons();

        return null;
      }

      const degrees = getNewDegrees();

      const convertedRotation =
        rotateService.convertRotationConception(
          degrees - degreesDiff,
        );

      return setRotation(convertedRotation);
    },
    [
      degreesDiff,
      getNewDegrees,
      hideRotateIcons,
      imageWrapperRef,
      isDown,
      setRotation,
    ],
  );

  useEffect(() => {
    if (isEditMode) return;

    hideRotateIcons();
  }, [hideRotateIcons, isEditMode]);

  // add stage handlers
  useEffect(() => {
    const stage = stageRef.current;

    if (!stage || !isEditMode) return () => {};

    stage.on('mousemove', stageMouseMoveHandler);
    stage.on('mouseup', stageMouseupHandler);

    return () => {
      stage.off('mousemove', stageMouseMoveHandler);
      stage.off('mouseup', stageMouseupHandler);
    };
  }, [
    stageMouseMoveHandler,
    stageMouseupHandler,
    stageRef,
    isEditMode,
  ]);

  // add imageWrapper handlers
  useEffect(() => {
    const imageWrapper = imageWrapperRef.current;

    if (!imageWrapper || !isEditMode) return () => {};

    imageWrapper.on('dragstart', hideRotateIcons);

    return () => {
      imageWrapper.off('dragstart', hideRotateIcons);
    };
  }, [hideRotateIcons, imageWrapperRef, isEditMode]);

  // add custom corners handlers
  useEffect(() => {
    if (!isEditMode) return () => {};

    customCornerRefs.forEach((cornerRef) => {
      const corner = cornerRef.current;

      if (!corner) return;

      corner.on('mouseenter', showIconHandler);
    });

    return () => {
      customCornerRefs.forEach((cornerRef) => {
        const corner = cornerRef.current;

        if (!corner) return;

        corner.off('mouseenter', showIconHandler);
      });
    };
  }, [customCornerRefs, isEditMode, showIconHandler]);

  // add rotate icons handlers
  useEffect(() => {
    if (!isEditMode) return () => {};

    rotateIconsRefs.forEach((iconRef: ImageRefType) => {
      const icon = iconRef.current;

      if (!icon) return;

      icon.on('mouseenter', iconMouseEnterHandler);
      icon.on('mousedown', iconMouseDownHandler);
    });

    return () => {
      rotateIconsRefs.forEach((iconRef: ImageRefType) => {
        const icon = iconRef.current;

        if (!icon) return;

        icon.off('mouseenter', iconMouseEnterHandler);
        icon.off('mousedown', iconMouseDownHandler);
      });
    };
  }, [
    iconMouseDownHandler,
    iconMouseEnterHandler,
    imageWrapperRef,
    isEditMode,
    rotateIconsRefs,
  ]);
};

export default useRotateIcons;
