const getNextStepTitle = (
  currentStep: string,
  steps: string[],
): string => {
  const indexOfCurrentStep = steps.indexOf(currentStep);

  const nextStepTitle = steps[indexOfCurrentStep + 1];

  return nextStepTitle;
};

export default getNextStepTitle;
