import { FC, KeyboardEvent, useEffect, useRef } from 'react';

import { IInputProps, InputTypes } from 'types/input';
import { Event } from 'types/general';

interface IProps extends IInputProps {
  defaultValue: number;
}

const LazyInput: FC<IProps> = ({
  disabled,
  className,
  changeHandler,
  defaultValue,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const checkChange = (e: Event<HTMLInputElement>) => {
    const currentValue = e.currentTarget.value;

    const isEqualValue = `${defaultValue}` === currentValue;

    if (isEqualValue || !changeHandler) return;

    changeHandler(e);
  };

  const keyPressHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;

    checkChange(e);
  };

  useEffect(() => {
    const input = inputRef.current;

    if (!input) return;

    input.value = `${defaultValue}`;
  }, [defaultValue]);

  return (
    <input
      ref={inputRef}
      disabled={disabled}
      onBlur={checkChange}
      className={className}
      type={InputTypes.NUMBER}
      onKeyPress={keyPressHandler}
      defaultValue={defaultValue}
    />
  );
};

export default LazyInput;
