import { ISize } from 'types/editor';

import convertInchesToMeters from './convertInchesToMeters';
import convertMetersToPixels from './convertMetersToPixels';

const calcProductSize = (
  size: ISize,
  roomSize: ISize,
  stageSize: ISize,
): ISize => {
  const { width: roomWidth } = roomSize;
  const { width: stageWidth } = stageSize;

  const coefficient = stageWidth / roomWidth;

  const { width: widthInches, height: heightInches } = size;

  const [widthInMeters] = convertInchesToMeters(widthInches);
  const [heightInMeters] = convertInchesToMeters(heightInches);

  const [widthInPixels] = convertMetersToPixels(widthInMeters);
  const [heightInPixels] = convertMetersToPixels(heightInMeters);

  const finalWidth = widthInPixels * coefficient;
  const finalHeight = heightInPixels * coefficient;

  return {
    width: finalWidth,
    height: finalHeight,
  };
};

export default calcProductSize;
