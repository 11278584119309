import React, { useCallback } from 'react';

import SidebarControlIcon from 'components/Icons/SidebarControlIcon';
import Button from 'components/Buttons/Button';

import { activeImageObjectSelector } from 'redux/gallery';
import { productCategorySelector } from 'redux/sidePanel';

import useAppSelector from 'hooks/redux/useAppSelector';
import { SECOND_FAZE } from 'constants/temp';

interface Props {
  showProductCategories: boolean;
  showProductCategoriesHandler: (isShow: boolean) => void;
}

const SidebarHeader: React.FC<Props> = ({
  showProductCategories,
  showProductCategoriesHandler,
}: Props) => {
  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const productCategory = useAppSelector(productCategorySelector);

  const onShowProductCategory = useCallback(
    () => showProductCategoriesHandler(!showProductCategories),
    [showProductCategories, showProductCategoriesHandler],
  );

  return (
    <header className="sidebar-header">
      <div className="sidebar-header-info">
        <h2 className="sidebar-header-title">
          {productCategory?.displayName}
        </h2>
        <Button isSecondary clickHandler={onShowProductCategory}>
          {showProductCategories ? 'Cancel' : 'Change Product'}
        </Button>
      </div>
      {SECOND_FAZE && (
        <div className="sidebar-header-controls">
          <Button
            className="sidebar-header-btn"
            isDisabled={!activeImageObject}
            isSecondary
          >
            <SidebarControlIcon className="sidebar-header-icon icon-grid" />
            Select Preset
          </Button>
          <Button
            className="sidebar-header-btn"
            isDisabled={!activeImageObject}
            isSecondary
          >
            <SidebarControlIcon className="sidebar-header-icon icon-bookmark" />
            Save Preset
          </Button>
        </div>
      )}
    </header>
  );
};

export default SidebarHeader;
