import { FormikValues, useFormikContext } from 'formik';
import { FC } from 'react';

import { IField } from 'types/ui/checkout';
import { InputTypes } from 'types/input';

import ShippingCol from '../ShippingCol';

interface IProps {
  columnList: IField[];
}

const ColumnList: FC<IProps> = ({ columnList }) => {
  const { values, errors, touched, handleChange } =
    useFormikContext<FormikValues>();

  const ShippingColList = columnList.map(
    ({ id, type, className, labelContent }) => {
      const isPassword = type === InputTypes.PASSWORD;

      const isError = !!(errors[id] && touched[id]);

      return (
        <ShippingCol
          id={id}
          key={id}
          type={type}
          value={values[id]}
          isError={isError}
          className={className}
          handleChange={handleChange}
          isPassword={isPassword}
          labelContent={labelContent}
        />
      );
    },
  );

  return <>{ShippingColList}</>;
};

export default ColumnList;
