import { forwardRef, useState, useImperativeHandle } from 'react';

import Button from 'components/Buttons/Button';
import Icon from 'components/Icons/Icon';
import { Void } from 'types/general';
import { IRef } from 'types/ref';

interface IProps {
  text: string;
  cancelHandler: Void;
  confirmHandler: Void;
}

const ConfirmModal = forwardRef<IRef, IProps>(
  ({ text, cancelHandler, confirmHandler }, ref) => {
    const [state, setState] = useState(false);

    useImperativeHandle<IRef, IRef>(ref, () => ({
      show() {
        setState(true);
      },

      hide() {
        setState(false);
      },

      state,
    }));

    return state ? (
      <>
        <div className="popup popup-confirm">
          <button className="popup-close" onClick={cancelHandler}>
            <Icon className="popup-icon icon-times" />
          </button>
          <span className="popup-message">{text}</span>
          <div className="d-flex a-center">
            <Button
              className="popup-apply-btn btn-main"
              clickHandler={confirmHandler}
            >
              Yes
            </Button>
            <Button
              className="popup-cancel-btn"
              clickHandler={cancelHandler}
            >
              No
            </Button>
          </div>
        </div>
        <div className="popup-shadow" />
      </>
    ) : (
      <></>
    );
  },
);

export default ConfirmModal;
