import { DropzoneInputProps } from 'react-dropzone';
import cn from 'classnames';
import { FC } from 'react';

import ContentButton from 'components/Buttons/ContentButton';
import { SECOND_FAZE } from 'constants/temp';

interface IProps {
  inputProps: DropzoneInputProps;
}

const DesktopBlock: FC<IProps> = ({ inputProps }) => (
  <>
    <span className="content-subtitle">
      Click to browse for files or Drag and Drop
    </span>
    <div className="content-drag">
      <label htmlFor="drag-drop" className="content-btn">
        <i className="icon-monitor" />
        <input
          id="drag-drop"
          {...inputProps}
          className={cn({
            hide: true,
          })}
        />
      </label>
      {SECOND_FAZE && (
        <>
          <ContentButton>
            <i className="icon-google-drive" />
          </ContentButton>
          <ContentButton>
            <i className="icon-dropbox" />
          </ContentButton>
        </>
      )}
    </div>
  </>
);

export default DesktopBlock;
