import { FC, memo } from 'react';

import { IUiSelectedOption } from 'types/general';

interface IProps {
  selectedOptions: IUiSelectedOption[];
}

const SelectedOptionsPriceList: FC<IProps> = memo(
  ({ selectedOptions }: IProps) => (
    <>
      {selectedOptions.map(
        ({ optionTotal, productOptionGroupId }) => (
          <span key={productOptionGroupId} className="cart-file-text">
            {`$ ${optionTotal}`}
          </span>
        ),
      )}
    </>
  ),
);

export default SelectedOptionsPriceList;
