import { createSelector } from 'reselect';

import { State } from 'redux/types';
import { IAuthState } from './types';

export const authSelector = (state: State): IAuthState => state.auth;

export const sessionIdSelector = createSelector(
  authSelector,
  ({ session }) => session.sessionId,
);

export const isCreatedCartSelector = createSelector(
  authSelector,
  ({ isCreatedCart }) => isCreatedCart,
);

export const isAuthPendingSelector = createSelector(
  authSelector,
  ({ isAuthPending }) => isAuthPending,
);
