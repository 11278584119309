import axios from 'axios/config';
import urls from 'api/urls';

import {
  IOrderInformationResponse,
  IOrderInformation,
} from 'types/checkout/orderInformation';

class OrderInformationService {
  async getOrderInformation(
    sessionId: string,
  ): Promise<IOrderInformation | void> {
    try {
      const { data } = await axios.get<IOrderInformationResponse>(
        urls.cart.getOrderInformation,
        {
          params: {
            CartSessionId: sessionId,
          },
        },
      );

      return data.orderInformation;
    } catch (error) {
      return console.log(error);
    }
  }

  async putOrderInformation(
    sessionId: string,
    cartId: number,
    requestBody: any,
  ): Promise<any> {
    try {
      const requestConfig = {
        params: {
          CartSessionId: sessionId,
          CartId: cartId,
        },
      };

      const { data } = await axios.put(
        urls.cart.putOrderInformation,
        requestBody,
        requestConfig,
      );

      return {
        loggedIn: data.isValid,
      };
    } catch (error) {
      return { loggedIn: false };
    }
  }
}

export default new OrderInformationService();
