import { FC, useCallback, useContext } from 'react';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';
import useResetEditor from 'hooks/editor/useResetEditor';
import useEditorState from 'hooks/editor/useEditorState';
import useEditMode from 'hooks/editor/useEditMode';

import ControlButton from 'components/Buttons/ControlButton';
import FooterIcon from 'components/Icons/FooterIcon';
import Button from 'components/Buttons/Button';

import {
  setIsShowGallery,
  isShowGallerySelector,
} from 'redux/gallery';
import {
  getProductAsync,
  isSelectedProductSizeOptionSelector,
  setShowOptions,
} from 'redux/sidePanel';

import { SettingsContext } from 'context/contexts/editor/general';
import { Event } from 'types/general';

import FooterControls from './FooterControls';
import FooterSettings from './FooterSettings';

import 'rc-slider/assets/index.css';

const Footer: FC = () => {
  const { showSettings, showSettingsHandler } =
    useContext(SettingsContext);

  const isShowGallery = useAppSelector(isShowGallerySelector);
  const isSelectedProductSize = useAppSelector(
    isSelectedProductSizeOptionSelector,
  );

  const dispatch = useAppDispatch();

  const { isDesktop } = useDefineDeviceByWindowSize();
  const [isEditMode, setIsEditMode] = useEditMode();
  const [, undoEditorChanges] = useEditorState();
  const resetEditor = useResetEditor();

  const setIsEditModeHandler = useCallback(() => {
    if (!isSelectedProductSize) return;

    setIsEditMode(!isEditMode);
  }, [isEditMode, isSelectedProductSize, setIsEditMode]);

  const hideSettingsHandler = useCallback(
    () => showSettingsHandler(!showSettings),
    [showSettingsHandler, showSettings],
  );

  const showGalleryHandler = useCallback(
    () => dispatch(setIsShowGallery(!isShowGallery)),
    [dispatch, isShowGallery],
  );

  const stopPropagationHandler = (e: Event<HTMLDivElement>) =>
    e.stopPropagation();

  // reset editor view and selected side panel options
  const resetEditorViewHandler = useCallback(() => {
    resetEditor();
    setIsEditMode(false);

    dispatch(setShowOptions(true));
    dispatch(getProductAsync({}));
  }, [dispatch, setIsEditMode, resetEditor]);

  return (
    <footer className="footer col">
      <div
        className="footer-controls-container"
        onClick={stopPropagationHandler}
      >
        {isDesktop && (
          <Button
            className="footer-btn"
            isActive={showSettings}
            clickHandler={hideSettingsHandler}
          >
            <FooterIcon className="icon-settings" />
          </Button>
        )}
      </div>
      {showSettings && isDesktop && <FooterSettings />}
      {isEditMode && <FooterControls />}
      <div className="footer-controls-container">
        {isSelectedProductSize && (
          <Button
            className="footer-controls-btn"
            clickHandler={undoEditorChanges}
          >
            Undo Change
          </Button>
        )}
        <Button
          className="footer-btn btn-crop"
          isActive={isEditMode}
          clickHandler={setIsEditModeHandler}
        >
          <FooterIcon className="icon-crop" />
          <span className="footer-btn-title">Edit mode</span>
        </Button>
        <Button
          className="footer-btn"
          isActive={isShowGallery}
          clickHandler={showGalleryHandler}
        >
          <FooterIcon className="icon-gallery" />
          <span className="footer-btn-title">Light box</span>
        </Button>
        <ControlButton
          className="footer-controls-btn"
          clickHandler={resetEditorViewHandler}
        >
          Reset
        </ControlButton>
      </div>
    </footer>
  );
};

export default Footer;
