import { useSpring } from '@react-spring/web';
import { useState } from 'react';

const useAnimation = (): any => {
  const [isClicked, setIsClicked] = useState(false);

  const animations = useSpring({
    from: { background: 'transparent' },
    to: {
      top: isClicked ? '0' : '50%',
      right: isClicked ? '0' : '50%',
      background: isClicked ? 'white' : 'transparent',
    },
  });

  return [animations, isClicked, setIsClicked];
};

export default useAnimation;
