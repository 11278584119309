import { FC, useCallback } from 'react';

import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import FooterIcon from 'components/Icons/FooterIcon';
import Button from 'components/Buttons/Button';

import { SECOND_FAZE } from 'constants/temp';
import {
  setIsShowGallery,
  isShowGallerySelector,
} from 'redux/gallery';

const FooterMobile: FC = () => {
  const isShowGallery = useAppSelector(isShowGallerySelector);

  const dispatch = useAppDispatch();

  const showGalleryHandler = useCallback(
    () => dispatch(setIsShowGallery(!isShowGallery)),
    [dispatch, isShowGallery],
  );

  return (
    <footer className="footer col">
      <div className="d-flex a-center">
        <Button className="footer-btn" clickHandler={() => {}}>
          <FooterIcon className="icon-wall is-light" />
        </Button>
        {SECOND_FAZE && (
          <Button className="footer-btn" clickHandler={() => {}}>
            <FooterIcon className="icon-room" />
          </Button>
        )}
      </div>
      <div className="d-flex a-center">
        {SECOND_FAZE && (
          <Button className="footer-btn">
            <FooterIcon className="icon-shape" />
          </Button>
        )}
        <Button className="footer-btn">
          <FooterIcon
            className="icon-gallery is-light"
            clickHandler={showGalleryHandler}
          />
        </Button>
      </div>
    </footer>
  );
};

export default FooterMobile;
