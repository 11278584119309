export const studio = {
  id: 'studio',
  labelContent: 'Ship to my studio',
};

export const dropShip = {
  id: 'drop-ship',
  labelContent: 'Drop Ship to another address',
};

export const shippingOptions = [studio, dropShip];
