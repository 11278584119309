import { FC } from 'react';

interface IProps {
  title: string;
  value: string | number;
}

const CartSidebarItem: FC<IProps> = ({ title, value }) => (
  <li className="check-sidebar-item cart-sidebar-item">
    <span className="cart-sidebar-item-text">{title}</span>
    <span className="cart-sidebar-item-text isBold">{value}</span>
  </li>
);

export default CartSidebarItem;
