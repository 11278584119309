import { useCallback, useContext } from 'react';

import { setIsEditMode as setIsEditModeAction } from 'redux/editor/editorReducer';
import { isEditModeSelector } from 'redux/editor';

import { CropStartContext } from 'context/contexts/editor/canvas';

import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import usePrevValues from './intersections/usePrevValues';
import useEditorState from './useEditorState';

type EditModeControls = [
  isEditMode: boolean,
  handleEditMode: (editMode: boolean) => void,
];

const useEditMode = (): EditModeControls => {
  const cropStart = useContext(CropStartContext);

  const isEditMode = useAppSelector(isEditModeSelector);

  const dispatch = useAppDispatch();

  const { setPrevValues } = usePrevValues();
  const [saveEditorState] = useEditorState();

  const setIsEditMode = useCallback(
    (isEditModeParam: boolean) => {
      if (isEditModeParam) {
        cropStart();
        setPrevValues();

        return dispatch(setIsEditModeAction(isEditModeParam));
      }
      saveEditorState();

      return dispatch(setIsEditModeAction(isEditModeParam));
    },
    [dispatch, cropStart, setPrevValues, saveEditorState],
  );

  return [isEditMode, setIsEditMode];
};

export default useEditMode;
