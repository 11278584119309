import { SettingsContext } from 'context/contexts/editor/general';
import { FC, useCallback, useEffect, useState } from 'react';

import { IContextProps } from 'types/contextProps';

const SettingContextProvider: FC<IContextProps> = ({ children }) => {
  const [showSettings, setShowSettings] = useState(false);

  const showSettingsHandler = useCallback(
    (isShow: boolean) => setShowSettings(isShow),
    [],
  );

  const hideSettings = useCallback(() => setShowSettings(false), []);

  const settingsContextValue = {
    showSettings,
    showSettingsHandler,
  };

  useEffect(() => {
    document.addEventListener('click', hideSettings);

    return () => {
      document.removeEventListener('click', hideSettings);
    };
  }, [hideSettings]);

  return (
    <SettingsContext.Provider value={settingsContextValue}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingContextProvider;
