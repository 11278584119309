import { Switch, Route, Redirect } from 'react-router-dom';
import { FC } from 'react';

import { SIDE_PANEL_ROUTES } from '../constants/routes/app/routes';
import appRouteList from '../constants/routes/app/appRouteList';

const AppRouter: FC = () => (
  <Switch>
    {appRouteList.map(({ path, component }) => (
      <Route key={path} path={path} component={component} />
    ))}
    <Redirect from="*" to={SIDE_PANEL_ROUTES.METAL_PRINT} />
  </Switch>
);

export default AppRouter;
