import Checkout from 'pages/Checkout';
import Editor from 'pages/Editor';
import Cart from 'pages/Cart';

import { ROUTES } from './routes';

const appRouteList = [
  {
    path: ROUTES.EDITOR,
    component: Editor,
  },
  {
    path: ROUTES.CART,
    component: Cart,
  },
  {
    path: ROUTES.CHECKOUT,
    component: Checkout,
  },
];

export default appRouteList;
