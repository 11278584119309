import { FC, useCallback } from 'react';

import ControlButton from 'components/Buttons/ControlButton';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icons/Icon';

import useAppDispatch from 'hooks/redux/useAppDispatch';
import { setIsShowGallery } from 'redux/gallery';
import { Void } from 'types/general';

interface IProps {
  handleClick: Void;
  imageCounts: number;
  selectedImageCounts: number;
  handleSelectAllImages: Void;
  handleDeleteImages: Void;
}

const GalleryHeader: FC<IProps> = ({
  imageCounts,
  handleClick,
  selectedImageCounts,
  handleDeleteImages,
  handleSelectAllImages,
}) => {
  const dispatch = useAppDispatch();

  const hideGalleryHandler = useCallback(
    () => dispatch(setIsShowGallery(false)),
    [dispatch],
  );

  const selectedImageString = selectedImageCounts
    ? ` ${selectedImageCounts}`
    : '';

  return (
    <div className="gallery-header">
      <div className="d-flex a-center">
        <span className="gallery-header-title">
          <b>Library</b> {`${imageCounts} images`}
        </span>
        <Button
          className="gallery-header-btn"
          isSecondary
          clickHandler={handleSelectAllImages}
        >
          Select All
        </Button>
        <Button
          className="gallery-header-btn delete"
          isSecondary
          clickHandler={handleDeleteImages}
        >
          <Icon className="gallery-header-icon icon-delete" />
          Delete
          {selectedImageString}
        </Button>
        <Icon
          className="gallery-close-icon icon-close"
          clickHandler={hideGalleryHandler}
        />
      </div>
      <div className="gallery-header-controls">
        <ControlButton
          className="gallery-controls-btn"
          clickHandler={handleClick}
        >
          <Icon className="gallery-controls-icon gallery-arrow icon-arrow" />
        </ControlButton>
        <Icon
          className="gallery-controls-icon icon-close"
          clickHandler={hideGalleryHandler}
        />
      </div>
    </div>
  );
};

export default GalleryHeader;
