import { useCallback, useEffect, useState } from 'react';
import { KonvaEventObject } from 'konva/lib/Node';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';

import { ZOOM_VALUES } from 'constants/editor/zoom';
import { ICoordinates } from 'types/editor';

import { setIsTouchZoom } from 'redux/editor/editorReducer';

import useMainLayerZoom from './useMainLayerZoom';

const useMainLayerTouchZoom = (): void => {
  const [touchPoint1, setTouchPoint1] = useState<ICoordinates | null>(
    null,
  );
  const [touchPoint2, setTouchPoint2] = useState<ICoordinates | null>(
    null,
  );

  const { stageRef } = useEditorRefs();

  const dispatch = useAppDispatch();

  const { isDesktop } = useDefineDeviceByWindowSize();
  const [zoomInMainLayer, zoomOutMainLayer] = useMainLayerZoom();

  const touchZoomHandler = useCallback(
    (e: KonvaEventObject<TouchEvent>) => {
      e.evt.preventDefault();

      if (e.target.id() !== 'stage') return;

      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];

      if (!touch1 || !touch2) return;

      const currentTouchPoint1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      };

      const currentTouchPoint2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      };

      const xDiff = Math.abs(
        currentTouchPoint1.x - currentTouchPoint2.x,
      );
      const yDiff = Math.abs(
        currentTouchPoint1.y - currentTouchPoint2.y,
      );

      if (xDiff > yDiff) {
        const leftPoint =
          currentTouchPoint1.x < currentTouchPoint2.x
            ? currentTouchPoint1
            : currentTouchPoint2;

        const rightPoint =
          currentTouchPoint1.x > currentTouchPoint2.x
            ? currentTouchPoint1
            : currentTouchPoint2;

        if (!touchPoint1 && !touchPoint2) {
          setTouchPoint1(leftPoint);
          setTouchPoint2(rightPoint);
        }

        if (!touchPoint1 || !touchPoint2) return;

        const isDecreasedLeftPoint = leftPoint.x < touchPoint1.x;
        const isIncreasedRightPoint = rightPoint.x > touchPoint2.x;

        if (isDecreasedLeftPoint && isIncreasedRightPoint) {
          zoomInMainLayer(ZOOM_VALUES.ZOOM_MOBILE_VALUE);
        }

        if (!isDecreasedLeftPoint && !isIncreasedRightPoint) {
          zoomOutMainLayer(ZOOM_VALUES.ZOOM_MOBILE_VALUE);
        }

        setTouchPoint1(leftPoint);
        setTouchPoint2(rightPoint);
      }

      if (xDiff < yDiff) {
        const leftPoint =
          currentTouchPoint1.y < currentTouchPoint2.y
            ? currentTouchPoint1
            : currentTouchPoint2;

        const rightPoint =
          currentTouchPoint1.y > currentTouchPoint2.y
            ? currentTouchPoint1
            : currentTouchPoint2;

        if (!touchPoint1 && !touchPoint2) {
          setTouchPoint1(leftPoint);
          setTouchPoint2(rightPoint);
        }

        if (!touchPoint1 || !touchPoint2) return;

        const isDecreasedLeftPoint = leftPoint.y < touchPoint1.y;
        const isIncreasedRightPoint = rightPoint.y > touchPoint2.y;

        if (isDecreasedLeftPoint && isIncreasedRightPoint) {
          zoomInMainLayer(ZOOM_VALUES.ZOOM_MOBILE_VALUE);
        }

        if (!isDecreasedLeftPoint && !isIncreasedRightPoint) {
          zoomOutMainLayer(ZOOM_VALUES.ZOOM_MOBILE_VALUE);
        }

        setTouchPoint1(leftPoint);
        setTouchPoint2(rightPoint);
      }

      dispatch(setIsTouchZoom(true));
    },
    [
      dispatch,
      touchPoint1,
      touchPoint2,
      zoomInMainLayer,
      zoomOutMainLayer,
    ],
  );

  const clearTouchPoints = useCallback(() => {
    setTouchPoint1(null);
    setTouchPoint2(null);
  }, []);

  useEffect(() => {
    const stage = stageRef.current;

    if (!stage || isDesktop) return () => {};

    stage.on('touchmove', touchZoomHandler);
    stage.on('touchend', clearTouchPoints);

    return () => {
      stage.off('touchmove', touchZoomHandler);
      stage.off('touchend', clearTouchPoints);
    };
  }, [clearTouchPoints, isDesktop, stageRef, touchZoomHandler]);
};

export default useMainLayerTouchZoom;
