import { FourPoints, ISize } from 'types/editor';

const getDefaultPointsOfShape = (defaultSizes: ISize): FourPoints => {
  const { width, height } = defaultSizes;

  return {
    p1: {
      x: 0,
      y: 0,
    },
    p2: {
      x: width,
      y: 0,
    },
    p3: {
      x: width,
      y: height,
    },
    p4: {
      x: 0,
      y: height,
    },
  };
};

export default getDefaultPointsOfShape;
