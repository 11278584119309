const ONE_HUNDRED = 100;

class PercentService {
  public convertToPercent(
    valueToConvert: number,
    valueEqualOneHundred: number,
  ): number {
    return (valueToConvert * ONE_HUNDRED) / valueEqualOneHundred;
  }

  public convertFromPercent(
    valueInPercent: number,
    valueInNumber: number,
  ): number {
    return (valueInNumber / ONE_HUNDRED) * valueInPercent;
  }
}

export default PercentService;
