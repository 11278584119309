import Slider from 'rc-slider';
import { FC } from 'react';

import useImageWrapperZoom from 'hooks/editor/zoom/useImageWrapperZoom';
import useCroppedImageDpi from 'hooks/editor/view/useCroppedImageDpi';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppSelector from 'hooks/redux/useAppSelector';

import { isSelectedProductSizeOptionSelector } from 'redux/sidePanel';
import {
  maxSlideValueSelector,
  startImageWrapperScaleSelector,
} from 'redux/editor';

import { DPI_LIMIT } from 'constants/editor/general';

const FooterControlsZoom: FC = () => {
  const maxSlideValue = useAppSelector(maxSlideValueSelector);
  const startImageWrapperScale = useAppSelector(
    startImageWrapperScaleSelector,
  );
  const isSelectedProductSize = useAppSelector(
    isSelectedProductSizeOptionSelector,
  );

  const [dpi] = useCroppedImageDpi();
  const [zoomInImageWrapper, zoomOutImageWrapper] =
    useImageWrapperZoom();

  const { imageWrapperRef } = useEditorRefs();

  const [zoomIn, zoomOut] = useImageWrapperZoom();

  const isDpiLessLimit = dpi < DPI_LIMIT;

  const slideChangeHandler = (value: number | number[]) => {
    const imageWrapper = imageWrapperRef.current;

    if (!imageWrapper || !startImageWrapperScale || isDpiLessLimit)
      return null;

    const currentScale = imageWrapper.scale();

    if (!currentScale) return null;

    const neededScale =
      (maxSlideValue / -+value) * startImageWrapperScale.x;

    const scaleValue = Math.abs(currentScale.x - neededScale);

    if (neededScale > currentScale.x) {
      return zoomInImageWrapper(scaleValue);
    }

    return zoomOutImageWrapper(scaleValue);
  };

  const zoomInHandler = () => zoomIn();
  const zoomOutHandler = () => zoomOut();

  // slider values
  const min = isDpiLessLimit ? 0 : -maxSlideValue;
  const max = isDpiLessLimit ? 1 : -DPI_LIMIT;
  const value = isDpiLessLimit ? 1 : -dpi;

  return (
    <div className="footer-controls-angles footer-controls-borders d-flex a-center">
      <span className="footer-controls-title">Zoom Image</span>
      {isSelectedProductSize && (
        <Slider
          className="footer-zoom"
          min={min}
          max={max}
          value={value}
          onChange={slideChangeHandler}
        />
      )}
      <div className="footer-controls-resize">
        <button className="footer-resize" onClick={zoomOutHandler}>
          -
        </button>
        <button className="footer-resize" onClick={zoomInHandler}>
          +
        </button>
      </div>
    </div>
  );
};

export default FooterControlsZoom;
