import { IDropDownField } from 'types/ui/checkout';

const rush = {
  id: 'rush',
  labelContent: 'Rush',
  className: 'col-14 col-lg-7 order-1',
  dropdownItems: [],
};

const colorCorrection = {
  id: 'colorCorrection',
  labelContent: 'Color Correction',
  className: 'col-14 col-lg-7 order-2',
  dropdownItems: [],
};

const dropDownsList: IDropDownField[] = [rush, colorCorrection];

export default dropDownsList;
