import { FC } from 'react';

import { Children } from 'types/general';

interface IProps {
  children: Children;
  tooltip: string;
}

const Tooltip: FC<IProps> = ({ children, tooltip }) => (
  <span className="tooltip" data-tooltip={tooltip}>
    {children}
  </span>
);

export default Tooltip;
