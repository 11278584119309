const rotateIconSize = {
  width: 22,
  height: 22,
};

const rotateIcons = [
  {
    id: 'topLeftRotateIcon',
    rotation: -90,
    visible: false,
    ...rotateIconSize,
  },
  {
    id: 'topRightRotateIcon',
    rotation: 0,
    visible: false,
    ...rotateIconSize,
  },
  {
    id: 'bottomRightRotateIcon',
    rotation: 90,
    visible: false,
    ...rotateIconSize,
  },
  {
    id: 'bottomLeftRotateIcon',
    rotation: -180,
    visible: false,
    ...rotateIconSize,
  },
];

export default rotateIcons;
