import CHECKOUT_ROUTES from 'constants/routes/checkout/routes';

export const orderInformationStep = {
  id: CHECKOUT_ROUTES.ORDER_INFORMATION,
  labelContent: 'Order Information',
};

export const shippingStep = {
  id: CHECKOUT_ROUTES.SHIPPING_ADDRESS,
  labelContent: 'Shipping address',
};

export const confirmationStep = {
  id: CHECKOUT_ROUTES.SUMMARY,
  labelContent: 'Summary',
};

export const stepperItems = [
  orderInformationStep,
  shippingStep,
  confirmationStep,
];
