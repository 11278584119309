import { useCallback, useEffect, useState } from 'react';

import useAppSelector from 'hooks/redux/useAppSelector';
import { quantityRulesSelector } from 'redux/sidePanel';
import { Event, Void } from 'types/general';

type Func = (value: number) => void;

interface IResult {
  quantity: number;
  incrementQuantity: Void;
  decrementQuantity: Void;
  changeQuantityHandler: (e: Event<HTMLInputElement>) => void;
}

const useQuantity = (
  defaultQuantity = 1,
  callback?: Func,
): IResult => {
  const [quantity, setQuantity] = useState(defaultQuantity);

  const { min, max, step } = useAppSelector(quantityRulesSelector);

  const setValidQuantity = useCallback(
    (num: number) => {
      const validQuantity = Math.min(max, Math.max(num, min));

      if (callback) callback(validQuantity);

      setQuantity(validQuantity);
    },
    [callback, max, min],
  );

  const changeQuantityHandler = useCallback(
    (e: Event<HTMLInputElement>) => {
      const quantityValue = +e.currentTarget.value;

      setValidQuantity(quantityValue);
    },
    [setValidQuantity],
  );

  const incrementQuantity = useCallback(
    () => setValidQuantity(quantity + step),
    [quantity, setValidQuantity, step],
  );

  const decrementQuantity = useCallback(
    () => setValidQuantity(quantity - step),
    [quantity, setValidQuantity, step],
  );

  useEffect(() => {
    setQuantity(defaultQuantity);
  }, [defaultQuantity]);

  return {
    quantity,
    incrementQuantity,
    decrementQuantity,
    changeQuantityHandler,
  };
};

export default useQuantity;
