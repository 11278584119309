import { useState, FC, useCallback } from 'react';
import cn from 'classnames';

import { IDropdownItem } from 'types/checkout/general';

import Button from 'components/Buttons/Button';
import Icon from 'components/Icons/Icon';

import DropdownItem from './DropdownItem';

interface IProps {
  id: string;
  name: string;
  label: string;
  className: string;
  disabled?: boolean;
  selectDropdownItem: (
    dropdownId: string,
    dropdownItemId: string,
  ) => void;
  dropDownItems: IDropdownItem[];
}

const Dropdown: FC<IProps> = ({
  id,
  name,
  label,
  className,
  disabled,
  dropDownItems,
  selectDropdownItem,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = useCallback(
    () => !disabled && setIsOpen(!isOpen),
    [disabled, isOpen],
  );

  const selectDropdownItemHandler = useCallback(
    (dropdownItemId: string) => {
      selectDropdownItem(id, dropdownItemId);

      setIsOpen(false);
    },
    [selectDropdownItem, id],
  );

  const selectedItem = dropDownItems.find(
    ({ isSelected }) => isSelected,
  );

  const dropDownItemsList = dropDownItems.map(
    ({ id: dropdownItemId, description }) => (
      <DropdownItem
        key={dropdownItemId}
        description={description}
        clickHandler={() => selectDropdownItemHandler(dropdownItemId)}
      />
    ),
  );

  return (
    <div className={`${className} check-col`}>
      <span className="dropdown-label">{label}</span>
      <div
        className={cn('dropdown', {
          'is-active': isOpen,
          isDisabled: disabled,
        })}
      >
        <Button className="dropdown-btn" clickHandler={toggleIsOpen}>
          <span className="dropdown-text">
            {selectedItem?.description || name}
          </span>
          <Icon className="dropdown-btn-icon icon-arrow" />
        </Button>
        <div
          className={cn('dropdown-group', {
            'is-active': isOpen,
          })}
        >
          {dropDownItemsList}
        </div>
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  disabled: false,
};

export default Dropdown;
