import { ChangeEvent, FC, memo } from 'react';
import { Field } from 'formik';
import cn from 'classnames';

import { IField } from 'types/ui/checkout';
import { InputTypes } from 'types/input';

import PasswordField from '../PasswordField';

interface IProps extends IField {
  isError: boolean;
  isPassword: boolean;
  value: string;
  handleChange: (value: ChangeEvent<string>) => void;
}

const ShippingCol: FC<IProps> = memo(
  ({
    id,
    className,
    labelContent,
    isError,
    isPassword,
    value,
    handleChange,
  }: IProps) => (
    <div
      className={cn(className, 'check-col', {
        'check-error': isError,
      })}
    >
      <label htmlFor={id} className="dropdown-label">
        {labelContent}
      </label>
      <div className="check-input-container">
        {isPassword ? (
          <PasswordField
            id={id}
            value={value}
            changeHandler={handleChange}
          />
        ) : (
          <Field
            id={id}
            name={id}
            value={value}
            type={InputTypes.TEXT}
            className="check-input"
            onChange={handleChange}
          />
        )}
      </div>
    </div>
  ),
);

export default ShippingCol;
