import { Dispatch } from '@reduxjs/toolkit';

import sidePanelService from 'api/SidePanelService';
import {
  defaultGetProductBody,
  defaultProductCategoryType,
} from 'constants/sidePanel/productCategories';
import { GetState } from 'redux/types';

import { IGetProductAsyncBody } from './types';
import {
  setIsSidePanelFulfilled,
  setIsSidePanelPending,
  setProduct,
  setProductCatagoriesStatus,
} from './sidePanelReducer';

interface IParams {
  requestBodyParam?: IGetProductAsyncBody;
  productCategoryTypeParam?: string;
}

export const getProductAsync =
  ({ requestBodyParam, productCategoryTypeParam }: IParams) =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { sidePanel, auth } = getState();

    const { session } = auth;
    const sessionId = session?.sessionId;

    if (!sessionId) return;

    const { isSidePanelFulfilled, product } = sidePanel;
    const { productCategory } = product;

    const productCategoryType =
      productCategoryTypeParam ||
      productCategory?.productCategoryType ||
      defaultProductCategoryType;

    const requestBody = requestBodyParam || defaultGetProductBody;

    dispatch(setIsSidePanelPending(true));

    const productData = await sidePanelService.getProduct(
      sessionId,
      productCategoryType,
      requestBody,
    );

    if (!productData) return;

    dispatch(setProduct(productData));
    dispatch(setIsSidePanelFulfilled(true));
    dispatch(setIsSidePanelPending(false));
  };

export const getProductCatalogAsync =
  (sessionId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const productCategoriesStatus =
      await sidePanelService.getProductCategoriesStatus(sessionId);

    if (!productCategoriesStatus) return;

    dispatch(setProductCatagoriesStatus(productCategoriesStatus));
  };
