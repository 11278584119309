import { FC } from 'react';

interface IProps {
  className: string;
}

const FooterControlIcon: FC<IProps> = ({ className }) => {
  return <i className={`footer-controls-icon icon ${className}`} />;
};

export default FooterControlIcon;
