import { FC } from 'react';

import Icon from 'components/Icons/Icon';
import { Void } from 'types/general';

interface IProps {
  editLineItemHandler: Void;
  deleteLineItemHandler: Void;
}

const CartItemControls: FC<IProps> = ({
  deleteLineItemHandler,
  editLineItemHandler,
}: IProps) => (
  <>
    <button className="cart-btn" onClick={editLineItemHandler}>
      <Icon className="cart-icon icon-edit" />
    </button>
    <button className="cart-btn" onClick={deleteLineItemHandler}>
      <Icon className="cart-icon icon-times" />
    </button>
  </>
);

export default CartItemControls;
