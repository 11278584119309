import { useCallback, useEffect, useState } from 'react';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useAppSelector from 'hooks/redux/useAppSelector';

import { showOptionsSelector } from 'redux/sidePanel';

type Result = [
  showSidebarSettings: boolean,
  showSidebarSettingsHandler: (isShow: boolean) => void,
];

const useSidebarSettings = (): Result => {
  const [showSidebarSettings, setShowSidebarSettings] =
    useState(true);

  const showOptions = useAppSelector(showOptionsSelector);

  const { isDesktop } = useDefineDeviceByWindowSize();

  const showSidebarSettingsHandler = useCallback(
    (isShow: boolean) => {
      if (!isDesktop) return;

      setShowSidebarSettings(isShow);
    },
    [isDesktop],
  );

  useEffect(() => {
    if (!showOptions) return;

    setShowSidebarSettings(true);
  }, [showOptions]);

  return [showSidebarSettings, showSidebarSettingsHandler];
};

export default useSidebarSettings;
