import { IField } from 'types/ui/checkout';

interface IInitialValues {
  [key: string]: string;
}

const getInitialValues = (fields: IField[]): IInitialValues =>
  fields.reduce((acc, { id }) => {
    return {
      ...acc,
      [id]: '',
    };
  }, {});

export default getInitialValues;
