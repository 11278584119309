import { IDropDownField } from 'types/ui/checkout';

const shippingCarriers = {
  id: 'shippingCarriers',
  labelContent: 'Carrier',
  className: 'col-14 col-lg-7 order-1',
};

const dropDownList: IDropDownField[] = [shippingCarriers];

export default dropDownList;
