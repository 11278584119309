import { FC } from 'react';

import { productCategories } from 'constants/sidePanel/productCategories';

import { IProductCategoryStatus } from 'redux/sidePanel/types';
import useAppSelector from 'hooks/redux/useAppSelector';
import { productCategorySelector } from 'redux/sidePanel';

import CategoryCard from './CategoryCard';

interface IProps {
  selectProductCategoryHandler: (productCategory: string) => void;
  productCategoriesStatus: IProductCategoryStatus[];
}

const CategoryCards: FC<IProps> = ({
  selectProductCategoryHandler,
  productCategoriesStatus,
}) => {
  const productCategory = useAppSelector(productCategorySelector);

  const filteredProductCategories = productCategories.filter(
    ({ productCategoryType }, index) =>
      productCategoryType ===
        productCategoriesStatus[index].productCategoryType &&
      productCategoriesStatus[index].isActive,
  );

  const ProductCategoryCards = filteredProductCategories.map(
    (
      { displayName, productCategoryId, productCategoryType },
      index,
    ) => (
      <CategoryCard
        key={productCategoryType}
        displayName={displayName}
        url={productCategoriesStatus[index].url}
        productCategoryType={productCategoryType}
        selectProductCategoryHandler={selectProductCategoryHandler}
        isSelected={
          productCategory?.productCategoryId === productCategoryId
        }
      />
    ),
  );

  return <div className="product-grid">{ProductCategoryCards}</div>;
};

export default CategoryCards;
