import { FC, useState, useCallback } from 'react';

import { EditorWindowContext } from 'context/contexts/editor/general';
import { IContextProps } from 'types/contextProps';
import { EditModal } from 'pages/Editor/components/EditModal';

const EditorWindowContextProvider: FC<IContextProps> = ({
  children,
}) => {
  const [showEditorWindow, setShowEditorWindow] = useState(false);

  const showEditorWindowHandler = useCallback(
    (isShow: boolean) => setShowEditorWindow(isShow),
    [],
  );

  const editorWindowValue = {
    showEditorWindow,
    showEditorWindowHandler,
  };

  return (
    <EditorWindowContext.Provider value={editorWindowValue}>
      {showEditorWindow && <EditModal />}
      {children}
    </EditorWindowContext.Provider>
  );
};

export default EditorWindowContextProvider;
