import { IClientRect, ICoordinates } from 'types/editor';

const getNewPosOfShapeDuringDragIntersectWithoutRotation = (
  externalShapeClientRect: IClientRect,
  internalShapeClientRect: IClientRect,
): ICoordinates => {
  const {
    width: externalWidth,
    height: externalHeight,
    x: externalX,
    y: externalY,
  } = externalShapeClientRect;

  const {
    width: internalWidth,
    height: internalHeight,
    x: internalX,
    y: internalY,
  } = internalShapeClientRect;

  const exP1 = { x: externalX, y: externalY };

  const exP3 = {
    x: externalX + externalWidth,
    y: externalY + externalHeight,
  };

  const inP1 = { x: internalX, y: internalY };

  const inP3 = {
    x: internalX + internalWidth,
    y: internalY + internalHeight,
  };

  const newPos = { x: externalX, y: externalY };

  if (exP1.x >= inP1.x) {
    newPos.x = inP1.x;
  }

  if (exP1.y >= inP1.y) {
    newPos.y = inP1.y;
  }

  if (exP3.x <= inP3.x) {
    const diff = externalWidth - internalWidth;

    newPos.x = inP1.x - diff;
  }

  if (exP3.y <= inP3.y) {
    const diff = externalHeight - internalHeight;

    newPos.y = inP1.y - diff;
  }

  return newPos;
};

export default getNewPosOfShapeDuringDragIntersectWithoutRotation;
