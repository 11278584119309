/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { ChangeEvent, DragEvent, FC } from 'react';
import cn from 'classnames';

import { Event, Void } from 'types/general';
import useUpload from 'hooks/useUpload';

interface IProps {
  resetEditorPage: (callback?: Void) => void;
  hidePopupsHandler: (e: Event<HTMLElement>) => void;
}

const GalleryUpload: FC<IProps> = ({
  resetEditorPage,
  hidePopupsHandler,
}) => {
  const [rootProps, inputProps, isDragActive] = useUpload();

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = inputProps;

    if (!onChange) return;

    resetEditorPage();

    onChange(e);
  };

  const onDropHandler = (e: DragEvent<HTMLElement>) => {
    const { onDrop } = rootProps;

    if (!onDrop) return;

    resetEditorPage();

    onDrop(e);
  };

  return (
    <label
      {...rootProps}
      className={cn('gallery-drag', {
        'is-active': isDragActive,
      })}
      htmlFor="drag-gallery"
      onClick={hidePopupsHandler}
      onDrop={onDropHandler}
    >
      <input
        {...inputProps}
        id="drag-gallery"
        className="gallery-drag-input"
        onChange={onChangeHandler}
      />
      <span className="gallery-drag-text">Drag and drop here</span>
      <span className="gallery-drag-link">or browse to add</span>
    </label>
  );
};

export default GalleryUpload;
