import { IField } from 'types/ui/checkout';

export const email = {
  id: 'email',
  labelContent: 'Email Address *',
  className: 'col-14 col-lg-7 order-2',
};

export const firstName = {
  id: 'firstName',
  labelContent: 'First Name *',
  className: 'col-14 col-lg-7 order-2',
};

export const lastName = {
  id: 'lastName',
  labelContent: 'Last Name *',
  className: 'col-14 col-lg-7 order-2',
};

export const address = {
  id: 'address',
  labelContent: 'Street Address *',
  className: 'col-14 col-lg-10 order-2',
};

export const apt = {
  id: 'apt',
  labelContent: 'Apt/Suite',
  className: 'col-14 col-lg-4 order-2',
};

export const city = {
  id: 'city',
  labelContent: 'City *',
  className: 'col-14 col-lg-7 order-2',
};

export const zipCode = {
  id: 'zipCode',
  labelContent: 'Zip Code',
  className: 'col-14 col-lg-7 order-2',
};

export const phone = {
  id: 'phone',
  labelContent: 'Phone Number *',
  className: 'col-14 col-lg-7 order-2',
};

const shippingAddressCols: IField[] = [
  email,
  firstName,
  lastName,
  address,
  apt,
  city,
  zipCode,
  phone,
];

export default shippingAddressCols;
