const calcCroppedImageDpi = (
  originalProductWidth: number,
  selectedInchWidth: number,
  imgWrapperWidth: number,
  productWidth: number,
): number =>
  Math.ceil(
    originalProductWidth /
      selectedInchWidth /
      (imgWrapperWidth / productWidth),
  );

export default calcCroppedImageDpi;
