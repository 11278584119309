import { InputTypes } from 'types/input';
import { IField } from 'types/ui/checkout';

export const userName = {
  id: 'userName',
  labelContent: 'Username *',
  className: 'col-14 col-lg-7 order-1',
};

export const password = {
  id: 'password',
  labelContent: 'Password *',
  className: 'col-14 col-lg-7 order-1',
  type: InputTypes.PASSWORD,
};

export const accountInformationCols: IField[] = [userName, password];
