import { FC } from 'react';

interface IProps {
  description: string;
  clickHandler: () => void;
}

const DropdownItem: FC<IProps> = ({ description, clickHandler }) => (
  <div className="dropdown-item" onClick={clickHandler}>
    {description}
  </div>
);

export default DropdownItem;
