import { useCallback, FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import SidebarOrderInfo from 'components/SidebarOrderInfo';
import StepControls from 'components/StepControls';

import { productCategoryTypeSelector } from 'redux/sidePanel';
import { setIsCartContainer } from 'redux/cart/cartReducer';
import { setIsShowGallery } from 'redux/gallery';
import { isEmptyCartSelector } from 'redux/cart';

import CHECKOUT_ROUTES from 'constants/routes/checkout/routes';
import { ROUTES } from 'constants/routes/app/routes';

import toLowerCaseFirstChar from 'utils/string/toLowerCaseFirstChar';

import CartTable from './components/CartTable';

const Cart: FC = () => {
  const isEmptyCart = useAppSelector(isEmptyCartSelector);
  const productCategoryType = useAppSelector(
    productCategoryTypeSelector,
  );

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { isDesktop } = useDefineDeviceByWindowSize();

  const nextStepHandler = useCallback(
    () => history.push(CHECKOUT_ROUTES.ORDER_INFORMATION),
    [history],
  );

  const previousStepHandler = useCallback(() => {
    dispatch(setIsShowGallery(true));

    const nextRoute = `${ROUTES.EDITOR}/${toLowerCaseFirstChar(
      productCategoryType || '',
    )}`;

    history.push(nextRoute);
  }, [dispatch, history, productCategoryType]);

  const stepControlsProps = {
    nextStepHandler,
    nextStepContent: 'Checkout ->',
    previousStepHandler,
    className: 'step-controls',
    isHideNextStep: isEmptyCart,
    previousStepContent: 'Keep Ordering',
  };

  useEffect(() => {
    dispatch(setIsCartContainer(true));
  }, [dispatch]);

  return (
    <>
      <div
        className={cn('col cart', {
          'cart-empty': isEmptyCart,
        })}
      >
        <h2 className="cart-title">items in your cart</h2>
        {isEmptyCart ? (
          <h2 className="cart-message">
            There are no items in the cart.
          </h2>
        ) : (
          <CartTable />
        )}
        {isDesktop && <StepControls {...stepControlsProps} />}
      </div>
      <SidebarOrderInfo isShowContent={!isEmptyCart} />
      {!isDesktop && <StepControls {...stepControlsProps} />}
    </>
  );
};

export default Cart;
