import { FC } from 'react';

import Icon from 'components/Icons/Icon';
import { Void } from 'types/general';

interface IProps {
  message: string;
  hideErrorMessageHandler: Void;
}

const ErrorPopup: FC<IProps> = ({
  message,
  hideErrorMessageHandler,
}: IProps) => (
  <div className="err-message-container">
    <span className="err-message">{message}</span>
    <button
      className="err-message-btn"
      onClick={hideErrorMessageHandler}
    >
      <Icon className="err-message-icon icon-times is-light" />
    </button>
  </div>
);

export default ErrorPopup;
