import { FC } from 'react';

import { ButtonType } from '../enums';
import { IButtonProps } from '../types';

const ContentButton: FC<IButtonProps> = ({
  children,
  className,
  clickHandler,
}: IButtonProps) => (
  <button
    onClick={clickHandler}
    type={ButtonType.BUTTON}
    className={`content-btn ${className}`}
  >
    {children}
  </button>
);

export default ContentButton;
