import { useHistory } from 'react-router-dom';
import { FC } from 'react';

import { stepperItems } from 'constants/checkout/stepperItems';

import StepperItem from './StepperItem';

const Stepper: FC = () => {
  const { location } = useHistory();

  const stepIndex = stepperItems.findIndex(
    (step) => step.id === location.pathname,
  );

  const StepperItemsList = stepperItems.map(
    ({ id, labelContent }, index) => {
      const checked = stepIndex >= index;

      return (
        <StepperItem
          id={id}
          key={id}
          checked={checked}
          isActive={checked}
          labelContent={labelContent}
        />
      );
    },
  );

  return <ul className="stepper">{StepperItemsList}</ul>;
};

export default Stepper;
