import { Redirect, Route, Switch } from 'react-router-dom';
import { FC } from 'react';

import sidePanelRouteList from 'constants/routes/app/sidePanelRouteList';
import { SIDE_PANEL_ROUTES } from 'constants/routes/app/routes';

import { productCategoriesStatusSelector } from 'redux/sidePanel';
import useAppSelector from 'hooks/redux/useAppSelector';

const SidePanelRouter: FC = () => {
  const productCategoriesStatus = useAppSelector(
    productCategoriesStatusSelector,
  );

  const filteredSidePanelRouteList = sidePanelRouteList.filter(
    ({ path }, index) => {
      const current = productCategoriesStatus[index];

      if (!current) return true;

      const splittedArray = path.split('/');

      const categoryFromPath =
        splittedArray[splittedArray.length - 1];

      const normalizedCategory =
        categoryFromPath[0].toUpperCase() + categoryFromPath.slice(1);

      return (
        normalizedCategory ===
          productCategoriesStatus[index].productCategoryType &&
        productCategoriesStatus[index].isActive
      );
    },
  );

  return (
    <Switch>
      {filteredSidePanelRouteList.map(({ path, component }) => (
        <Route key={path} path={path} component={component} />
      ))}
      <Redirect from="*" to={SIDE_PANEL_ROUTES.METAL_PRINT} />
    </Switch>
  );
};

export default SidePanelRouter;
