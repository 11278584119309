import { IIntersectionChecksValues } from 'types/editor';

import isPointLessLineExpression from '../isPointLessLineExpression';
import isPointMoreLineExpression from '../isPointMoreLineExpression';
import getIntersectionChecksResult from '../getIntersectionChecksResult';

const intersectionChecks = ({
  internalPoints,
  externalPoints,
  k1,
  k2,
  rotation,
}: IIntersectionChecksValues): boolean => {
  const { p1: inP1, p2: inP2, p3: inP3, p4: inP4 } = internalPoints;
  const { p1: exP1, p2: exP2, p3: exP3, p4: exP4 } = externalPoints;

  if (!rotation) {
    const intersections = [
      isPointLessLineExpression(inP1, exP1, k1),
      isPointLessLineExpression(inP2, exP2, k2),
      isPointMoreLineExpression(inP3, exP3, k1),
      isPointMoreLineExpression(inP4, exP4, k2),
    ];

    if (getIntersectionChecksResult(intersections)) return false;
  }

  if (
    (rotation <= 90 && rotation > 0) ||
    (rotation > -360 && rotation < -270)
  ) {
    const intersections = [
      isPointLessLineExpression(inP1, exP1, k2),
      isPointLessLineExpression(inP2, exP2, k1),
      isPointMoreLineExpression(inP3, exP3, k2),
      isPointMoreLineExpression(inP4, exP4, k1),
    ];

    if (getIntersectionChecksResult(intersections)) return false;
  }

  if (
    (rotation > 270 && rotation < 360) ||
    (rotation >= -90 && rotation < 0)
  ) {
    const intersections = [
      isPointLessLineExpression(inP1, exP1, k1),
      isPointLessLineExpression(inP2, exP2, k2),
      isPointMoreLineExpression(inP3, exP3, k1),
      isPointMoreLineExpression(inP4, exP4, k2),
    ];

    if (getIntersectionChecksResult(intersections)) return false;
  }

  if (
    (rotation > 90 && rotation <= 180) ||
    (rotation >= -270 && rotation < -180)
  ) {
    const intersections = [
      isPointLessLineExpression(inP1, exP4, k1),
      isPointLessLineExpression(inP2, exP4, k2),
      isPointMoreLineExpression(inP3, exP1, k1),
      isPointMoreLineExpression(inP4, exP2, k2),
    ];

    if (getIntersectionChecksResult(intersections)) return false;
  }

  if (
    (rotation > 180 && rotation <= 270) ||
    (rotation >= -180 && rotation < -90)
  ) {
    const intersections = [
      isPointLessLineExpression(inP1, exP3, k2),
      isPointLessLineExpression(inP2, exP3, k1),
      isPointMoreLineExpression(inP3, exP1, k2),
      isPointMoreLineExpression(inP4, exP1, k1),
    ];

    if (getIntersectionChecksResult(intersections)) return false;
  }

  return true;
};

export default intersectionChecks;
