import { Dispatch } from '@reduxjs/toolkit';

import AuthService from 'api/AuthService';

import { setIsAuthPending, setSessionId } from './authReducer';

export const getSessionIdAsync =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsAuthPending(true));

    const session = await AuthService.getSessionId();

    if (!session) return;

    dispatch(setSessionId(session));

    dispatch(setIsAuthPending(false));
  };
