import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { isPendingSidePanelSelector } from 'redux/sidePanel';
import { isAuthPendingSelector } from 'redux/auth';

import useAppSelector from 'hooks/redux/useAppSelector';
import Loader from 'components/Loader';
import { IRef } from 'types/ref';

import SidebarFooter from '../components/SidebarFooter';
import SidebarHeader from '../components/SidebarHeader';
import SidebarBody from '../components/SidebarBody';

const SidebarContent: FC = () => {
  const ref = useRef<IRef>(null);

  const isAuthPending = useAppSelector(isAuthPendingSelector);
  const isSidePanelPending = useAppSelector(
    isPendingSidePanelSelector,
  );

  const [showProductCategories, setShowProductCategories] =
    useState(false);

  const showProductCategoriesHandler = useCallback(
    (isShow: boolean) => {
      setShowProductCategories(isShow);
    },
    [],
  );

  useEffect(() => {
    if (isAuthPending || isSidePanelPending) {
      return ref.current?.show();
    }

    return ref.current?.hide();
  }, [isAuthPending, isSidePanelPending]);

  return (
    <>
      <SidebarHeader
        showProductCategories={showProductCategories}
        showProductCategoriesHandler={showProductCategoriesHandler}
      />
      {isAuthPending ||
        (isSidePanelPending && (
          <Loader ref={ref} className="loader-flex" />
        ))}
      <SidebarBody
        showProductCategories={showProductCategories}
        showProductCategoriesHandler={showProductCategoriesHandler}
      />
      {!showProductCategories && <SidebarFooter />}
    </>
  );
};

export default SidebarContent;
