import { ISession } from 'redux/auth/types';
import axios from 'axios/config';
import urls from 'api/urls';

class AuthService {
  async getSessionId(): Promise<ISession | void> {
    try {
      const { data } = await axios.get<ISession>(
        urls.authentication.getSessionIdFunction,
      );

      return data;
    } catch (error) {
      return console.log(error);
    }
  }
}

export default new AuthService();
