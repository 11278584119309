import { useCallback, useEffect } from 'react';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';

import { ZOOM_VALUES } from 'constants/editor/zoom';
import { KonvaWheelEvent } from 'types/editor';

import useImageWrapperZoom from './useImageWrapperZoom';
import useMainLayerZoom from './useMainLayerZoom';

const useMouseWheelZoom = (): void => {
  const { stageRef } = useEditorRefs();

  const [zoomInMainLayer, zoomOutMainLayer] = useMainLayerZoom();
  const { isDesktop } = useDefineDeviceByWindowSize();
  const [zoomInImageWrapper, zoomOutImageWrapper] =
    useImageWrapperZoom();

  const mouseWheelHandler = useCallback(
    (e: KonvaWheelEvent) => {
      const id = e.target.id();

      const { deltaY } = e.evt;

      const isZoomIn = deltaY < 0;

      if (id === 'stage') {
        if (isZoomIn) {
          return zoomInMainLayer(ZOOM_VALUES.ZOOM_DESKTOP_VALUE);
        }

        return zoomOutMainLayer(ZOOM_VALUES.ZOOM_DESKTOP_VALUE);
      }

      if (isZoomIn) {
        return zoomInImageWrapper();
      }

      return zoomOutImageWrapper();
    },
    [
      zoomInImageWrapper,
      zoomInMainLayer,
      zoomOutImageWrapper,
      zoomOutMainLayer,
    ],
  );

  useEffect(() => {
    const stage = stageRef.current;

    if (!isDesktop || !stage) return () => {};

    stage.on('wheel', mouseWheelHandler);

    return () => stage.off('wheel', mouseWheelHandler);
  }, [isDesktop, mouseWheelHandler, stageRef]);
};

export default useMouseWheelZoom;
