import { createSelector } from 'reselect';

import { State } from '../types';
import { IGalleryState } from './types';

export const gallerySelector = (state: State): IGalleryState =>
  state.gallery;

export const activeImageObjectSelector = createSelector(
  gallerySelector,
  ({ activeImageObject }) => activeImageObject,
);

export const imageObjectsSelector = createSelector(
  gallerySelector,
  ({ imageObjects }) => imageObjects,
);

export const isGalleryPendingSelector = createSelector(
  gallerySelector,
  ({ isGalleryPending }) => isGalleryPending,
);

export const isShowGallerySelector = createSelector(
  gallerySelector,
  ({ isShowGallery }) => isShowGallery,
);

export const basedOrientationIdSelector = createSelector(
  gallerySelector,
  ({ basedOrientationId }) => basedOrientationId,
);
