const RBGAToHex = (
  rgba: string,
  forceRemoveAlpha = false,
): string => {
  return `#${rgba
    .replace(/^rgba?\(|\s+|\)$/g, '')
    .split(',')
    .filter(
      (string: string, index: number) =>
        !forceRemoveAlpha || index !== 3,
    )
    .map((string: string) => parseFloat(string))
    .map((number: number, index: number) =>
      index === 3 ? Math.round(number * 255) : number,
    )
    .map((number: number) => number.toString(16))
    .map((string: string) =>
      string.length === 1 ? `0${string}` : string,
    )
    .join('')}`;
};

export default RBGAToHex;
