import { FC, KeyboardEvent, useEffect, useRef } from 'react';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useRotation from 'hooks/editor/rotation/useRotation';

import FooterControlIcon from 'components/Icons/FooterControlIcon';
import rotateService from 'utils/editor/rotate/RotateService';
import { InputTypes } from 'types/input';
import { Simulate } from "react-dom/test-utils";
import error = Simulate.error;

const FooterControlsRotation: FC = () => {
  const inputRef = useRef<null | HTMLInputElement>(null);

  const { imageWrapperRef } = useEditorRefs();

  const [visualRotation, setRotation] = useRotation();

  const pressKeyHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    const imageWrapper = imageWrapperRef.current;

    if (e.key !== 'Enter' || !imageWrapper) return;

    const { value } = e.currentTarget;

    const currentDegrees = Math.round(imageWrapper.rotation());
    const inputDegrees = +value;

    const nextDegrees = inputDegrees - currentDegrees;

    const validatedDegrees =
      rotateService.rotationValidation(nextDegrees);

    setRotation(validatedDegrees);
  };

  const counterclockwiseRotateHandler = () => {
    const convertedRotation =
      rotateService.convertRotationConception(-90);

    setRotation(convertedRotation);
  };

  const clockwiseRotateHandler = () => {
    setRotation(90);
  };

  const incrementRotation = () => setRotation(1);
  const decrementRotation = () => setRotation(-1);

  // update value of uncontrolled input
  useEffect(() => {
    const input = inputRef.current;

    if (!input) return;

    input.value = `${visualRotation}`;
  }, [visualRotation]);

  return (
    <div className="d-flex a-center">
      <span className="footer-controls-title">Angle of rotation</span>
      <label htmlFor="angle" className="footer-controls-label">
        <input
          id="angle"
          ref={inputRef}
          type={InputTypes.NUMBER}
          defaultValue={visualRotation}
          onKeyDown={pressKeyHandler}
          className="footer-controls-input"
        />
      </label>
      <div className="footer-controls-resize footer-controls-borders">
        <button className="footer-resize" onClick={decrementRotation}>
          -
        </button>
        <button className="footer-resize" onClick={incrementRotation}>
          +
        </button>
      </div>
      <span
        className="footer-controls-angle"
        onClick={counterclockwiseRotateHandler}
      >
        90&#176;
        <FooterControlIcon className="icon-counterclockwise" />
      </span>
      <span
        className="footer-controls-angle"
        onClick={clockwiseRotateHandler}
      >
        <FooterControlIcon className="icon-clockwise" />
        90&#176;
      </span>
    </div>
  );
};

export default FooterControlsRotation;
