import { useCallback } from 'react';

import shapeIntersectionChecks from 'utils/editor/intersectionChecks/shapeIntersectionChecks';
import getActualShapePoints from 'utils/editor/intersectionChecks/getActualShapePoints';
import getActualShapeSize from 'utils/editor/intersectionChecks/getActualShapeSize';
import getKCoefficient from 'utils/editor/intersectionChecks/getKCoefficient';
import rotateService from 'utils/editor/rotate/RotateService';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';

type Result = () => boolean;

const useShapeIntersectionChecks = (): Result => {
  const { mainLayerRef, imageWrapperRef, croppedImageRef } =
    useEditorRefs();

  const getIsShapeIntersection = useCallback(() => {
    const mainLayer = mainLayerRef.current;
    const imageWrapper = imageWrapperRef.current;
    const product = croppedImageRef.current;

    if (!mainLayer || !product || !imageWrapper) return false;

    const imageRotation = imageWrapper.rotation();
    const productRotation = product.rotation();

    const productRad = rotateService.getRadians(productRotation);

    const radForKCoefficient1 =
      rotateService.getRadians(imageRotation);
    const radForKCoefficient2 = rotateService.getRadians(
      imageRotation + 90,
    );

    const imageWrapperScale = imageWrapper.scale();
    const mainLayerScale = mainLayer.scale();
    const productScale = product.scale();

    if (!imageWrapperScale || !mainLayerScale || !productScale)
      return false;

    const imageSize = getActualShapeSize(
      imageWrapper.size(),
      imageWrapperScale,
      mainLayerScale,
    );

    const productSize = getActualShapeSize(
      product.size(),
      productScale,
      mainLayerScale,
    );

    const internalPoints = getActualShapePoints({
      rad: productRad,
      ...productSize,
      ...product.getAbsolutePosition(),
    });

    const externalPoints = getActualShapePoints({
      rad: radForKCoefficient1,
      ...imageSize,
      ...imageWrapper.getAbsolutePosition(),
    });

    const k1 = getKCoefficient(radForKCoefficient1);
    const k2 = getKCoefficient(radForKCoefficient2);

    const shapeIntersection = shapeIntersectionChecks({
      internalPoints,
      externalPoints,
      k1,
      k2,
      rotation: imageRotation,
    });

    return shapeIntersection;
  }, [mainLayerRef, imageWrapperRef, croppedImageRef]);

  return getIsShapeIntersection;
};

export default useShapeIntersectionChecks;
