import { FC } from 'react';
import cn from 'classnames';

import { ButtonType } from '../enums';
import { IButtonProps } from '../types';

const ControlButton: FC<IButtonProps> = ({
  children,
  className,
  isActive,
  clickHandler,
}: IButtonProps) => (
  <button
    onClick={clickHandler}
    type={ButtonType.BUTTON}
    className={cn(className, {
      'is-active': isActive,
    })}
  >
    {children}
  </button>
);

ControlButton.defaultProps = {
  isActive: false,
  clickHandler: () => {},
};

export default ControlButton;
