/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAuthState, ISession } from './types';

const defaultSession = {
  sessionId: null,
  expirationUTC: null,
};

const initialState: IAuthState = {
  session: defaultSession,
  isCreatedCart: false,
  isAuthPending: false,
  isAuthFulfilled: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSessionId: (state, { payload }: PayloadAction<ISession>) => {
      state.session = payload;
      state.isAuthFulfilled = true;
    },
    setIsCreatedCart: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatedCart = payload;
    },
    setIsAuthPending: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isAuthPending = payload;
    },
    clearSessionId: (state) => {
      state.session = defaultSession;
    },
  },
});

export const {
  setSessionId,
  clearSessionId,
  setIsAuthPending,
  setIsCreatedCart,
} = authSlice.actions;

export default authSlice.reducer;
