import urls from 'api/urls';
import axios from 'axios/config';
import { ICart } from 'redux/cart/types';
import * as url from "url";

class CartService {
  async createCart(sessionId: string): Promise<ICart | void> {
    try {
      const { data } = await axios.post<ICart>(
        urls.cart.createCartFunction,
        {},
        {
          params: {
            CartSessionId: sessionId,
          },
        },
      );

      return data;
    } catch (error) {
      return console.log(error);
    }
  }

  async getCart(sessionId: string): Promise<ICart | void> {
    try {
      const { data } = await axios.get<ICart>(
        urls.cart.getCartFunction,
        {
          params: {
            CartSessionId: sessionId,
          },
        },
      );

      return data;
    } catch (error) {
      return console.log(error);
    }
  }

  async addLineItem(
    sessionId: string,
    requestBody: any,
  ): Promise<ICart | void> {
    try {
      const { data } = await axios.post<ICart>(
        urls.cart.addLineItemToCartFunction,
        requestBody,
        { params: { CartSessionId: sessionId } },
      );

      return data;
    } catch (error) {
      return console.log(error);
    }
  }

  async deleteCart(sessionId: string): Promise<ICart | void> {
    try {
      const  data  = await axios.delete<ICart>(
        urls.cart.deleteCartFunction,
        {
          params: {
            CartSessionId: sessionId,
          }
        }
      );

      return data.data;
    } catch (error) {
      return console.log(error);
    }
  }

  async deleteLineItem(
    sessionId: string,
    lineItemId: number,
  ): Promise<ICart | void> {
    try {
      const { data } = await axios.delete<ICart>(
        urls.cart.deleteLineItemFromCartFunction,
        {
          params: {
            CartSessionId: sessionId,
            LineItemId: lineItemId,
          },
        },
      );

      return data;
    } catch (error) {
      return console.log(error);
    }
  }

  async updateLineItem(
    sessionId: string,
    requestBody: any,
  ): Promise<ICart | void> {
    try {
      const { data } = await axios.put<ICart>(
        urls.cart.updateLineItem,
        requestBody,
        {
          params: {
            CartSessionId: sessionId,
          },
        },
      );

      return data;
    } catch (error) {
      return console.log(error);
    }
  }
}

export default new CartService();
