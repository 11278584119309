import { useCallback } from 'react';

import {
  setPrevImageWrapperPosition,
  setPrevImageWrapperScale,
} from 'redux/editor/editorReducer';
import {
  prevImageWrapperPositionSelector,
  prevImageWrapperScaleSelector,
} from 'redux/editor';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { ICoordinates } from 'types/editor';
import { Void } from 'types/general';

interface IPrevValuesService {
  setPrevValues: Void;
  prevImageWrapperPosition: ICoordinates | null;
  prevImageWrapperScale: ICoordinates | null;
}

const usePrevValues = (): IPrevValuesService => {
  const { mainLayerRef, imageWrapperRef } = useEditorRefs();

  const prevImageWrapperPosition = useAppSelector(
    prevImageWrapperPositionSelector,
  );
  const prevImageWrapperScale = useAppSelector(
    prevImageWrapperScaleSelector,
  );

  const dispatch = useAppDispatch();

  const setPrevValues = useCallback(() => {
    const imageWrapper = imageWrapperRef.current;
    const mainLayer = mainLayerRef.current;

    if (!mainLayer || !imageWrapper) return;

    const position = imageWrapper.getAbsolutePosition();
    const scale = imageWrapper.scale();
    const layerScale = mainLayer.scale();

    if (!scale || !layerScale) return;

    dispatch(setPrevImageWrapperScale(scale));
    dispatch(setPrevImageWrapperPosition(position));
  }, [mainLayerRef, imageWrapperRef, dispatch]);

  return {
    setPrevValues,
    prevImageWrapperPosition,
    prevImageWrapperScale,
  };
};

export default usePrevValues;
