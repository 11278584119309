import { useCallback } from 'react';

import calcCroppedImageDpi from 'utils/editor/dpi/calcCroppedImageDpi';

import { selectedProductSizeOptionSelector } from 'redux/sidePanel';
import { activeImageObjectSelector } from 'redux/gallery';
import { croppedImageDpiSelector } from 'redux/editor';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppSelector from 'hooks/redux/useAppSelector';

type CalcDpi = [number, () => number];

const useCroppedImageDpi = (): CalcDpi => {
  const { croppedImageRef, imageWrapperRef } = useEditorRefs();

  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const croppedImageDpi = useAppSelector(croppedImageDpiSelector);
  const selectedProductSizeOption = useAppSelector(
    selectedProductSizeOptionSelector,
  );

  const calcDpi = useCallback(() => {
    const imageWrapper = imageWrapperRef.current;
    const croppedImage = croppedImageRef.current;

    if (
      !croppedImage ||
      !imageWrapper ||
      !activeImageObject ||
      !selectedProductSizeOption
    )
      return 0;

    const size = selectedProductSizeOption?.size;

    if (!size) return 0;

    const imgWrapperWidth =
      imageWrapper.scaleX() * imageWrapper.width();

    const dpi = calcCroppedImageDpi(
      activeImageObject.originalImageWidthInPixels,
      +size.width,
      imgWrapperWidth,
      croppedImage.width(),
    );

    return dpi;
  }, [
    croppedImageRef,
    imageWrapperRef,
    activeImageObject,
    selectedProductSizeOption,
  ]);

  return [croppedImageDpi, calcDpi];
};

export default useCroppedImageDpi;
