import { FC } from 'react';

import { IIconProps } from '../types';

const FooterIcon: FC<IIconProps> = ({ className, clickHandler }) => (
  <i
    onClick={clickHandler}
    className={`footer-icon icon ${className}`}
  />
);

export default FooterIcon;
