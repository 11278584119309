import { FC, useRef, useState } from 'react';

import AlertModalContext from 'context/contexts/alertModalContext';
import { IContextProps } from 'types/contextProps';
import AlertModal from 'components/AlertModal';
import { IRef } from 'types/ref';

const AlertModalContextProvider: FC<IContextProps> = ({
  children,
}) => {
  const [modalText, setModalText] = useState('');

  const ref = useRef<IRef>(null);

  const handleShowModal = (text: string) => {
    setModalText(text);

    ref.current?.show();
  };

  const handleHideModal = () => {
    ref.current?.hide();

    setModalText('');
  };

  return (
    <AlertModalContext.Provider value={handleShowModal}>
      <AlertModal
        ref={ref}
        text={modalText}
        handleHideModal={handleHideModal}
      />
      {children}
    </AlertModalContext.Provider>
  );
};

export default AlertModalContextProvider;
