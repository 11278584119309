import { FC, memo } from 'react';

interface IProps {
  subTotal: number;
  discount?: number;
  total: number;
  itemsCount: number;
  rush: boolean;
  rushTotal: string;
}

const TotalOrder: FC<IProps> = memo(
  ({
    subTotal,
    discount,
    total,
    itemsCount,
    rushTotal,
    rush,
  }: IProps) => (
    <ul className="confirm-list cart-sidebar-list">
      <li className="check-sidebar-item cart-sidebar-item">
        <span className="cart-sidebar-item-text" />
        <span className="cart-sidebar-item-text">
          {itemsCount} {itemsCount === 1 ? 'item' : 'items'} for $
          {total}
        </span>
      </li>
      <li className="check-sidebar-item cart-sidebar-item">
        <span className="cart-sidebar-item-text">Sub Total</span>
        <span className="cart-sidebar-item-text">
          {`$ ${subTotal}`}
        </span>
      </li>
      {rush && (
        <li className="check-sidebar-item cart-sidebar-item">
          <span className="cart-sidebar-item-text">Rush</span>
          <span className="cart-sidebar-item-text">{`$ ${rushTotal}`}</span>
        </li>
      )}
      <li className="check-sidebar-item cart-sidebar-item">
        <span className="cart-sidebar-item-text">Discount</span>
        <span className="cart-sidebar-item-text">
          {`$ ${discount || 0}`}
        </span>
      </li>
      <li className="check-sidebar-item cart-sidebar-item">
        <span className="cart-sidebar-item-text isBold">
          Total Order
        </span>
        <span className="cart-sidebar-item-text isBold">
          {`$ ${total}`}
        </span>
      </li>
    </ul>
  ),
);

TotalOrder.defaultProps = {
  discount: 0.0,
};

export default TotalOrder;
