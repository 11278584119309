const AUTHENTICATION = 'authentication';
const PRODUCTS = 'products';
const IMAGES = 'images';
const CART = 'cart';

const urls = {
  [AUTHENTICATION]: {
    getSessionIdFunction: `/${AUTHENTICATION}/GetSessionIdFunction`,
  },
  [CART]: {
    addLineItemToCartFunction: `/${CART}/AddLineItemToCartFunction`,
    checkoutCartFunction: `/${CART}/CheckoutCartFunction`,
    createCartFunction: `/${CART}/CreateCartFunction`,
    deleteCartFunction: `/${CART}/DeleteCartFunction`,
    deleteLineItemFromCartFunction: `/${CART}/DeleteLineItemFromCartFunction`,
    getCartFunction: `/${CART}/GetCartFunction`,
    updateCartFunction: `/${CART}/UpdateCartFunction`,
    updateLineItem: `/${CART}/UpdateLineItem`,
    getOrderInformation: `/${CART}/GetOrderInformation`,
    getOrderShipping: `/${CART}/GetOrderShipping`,
    getOrderSummary: `/${CART}/GetOrderSummary`,
    putOrderInformation: `/${CART}/PutOrderInformation`,
    putOrderShipping: `/${CART}/PutOrderShipping`,
  },
  [IMAGES]: {
    getImages: `/${IMAGES}/GetImages`,
    getImage: `/${IMAGES}/GetImage`,
    getNewStorageUrl: `/${IMAGES}/GetNewStorageUrl`,
    deleteImage: `/${IMAGES}/DeleteImage`,
    deleteImages: `/${IMAGES}/DeleteImages`,
  },
  [PRODUCTS]: {
    getProduct: `/${PRODUCTS}/GetProduct`,
    getProductCategoriesStatus: `/${PRODUCTS}/GetHHProductCatalog`,
  },
};

export default urls;
