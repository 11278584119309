import { useRef, FC } from 'react';

import { IContextProps } from 'types/contextProps';
import { IRef } from 'types/ref';

import LoaderContext from 'context/contexts/loaderContext';
import Loader from 'components/Loader';

const LoaderContextProvider: FC<IContextProps> = ({ children }) => {
  const ref = useRef<IRef>(null);

  const handleShowLoader = () => {
    ref.current?.show();
  };

  const handleHideLoader = () => {
    ref.current?.hide();
  };

  return (
    <LoaderContext.Provider
      value={{ handleShowLoader, handleHideLoader }}
    >
      <Loader ref={ref} className="loader-fixed" />
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderContextProvider;
