import { FC, useEffect, useState } from 'react';
import { Children } from 'types/general';

interface ThinkBubbleProps {
  children: Children;
  className?: string;
}

const ThinkBubble: FC<ThinkBubbleProps> = ({
  children,
  className,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  return isVisible ? (
    <span className={className}>{children}</span>
  ) : null;
};

ThinkBubble.defaultProps = {
  className: '',
};

export default ThinkBubble;
