import { useContext } from 'react';

import AlertModalContext, {
  AlertModalContextType,
} from 'context/contexts/alertModalContext';

const useAlertModal = (): AlertModalContextType => {
  const context = useContext(AlertModalContext);

  if (!context) {
    throw new Error('useAlertModal used outside AlertModalContext');
  }

  return context;
};

export default useAlertModal;
