import { FC } from 'react';

import { InputTypes } from 'types/input';
import { Void } from 'types/general';

interface IProps {
  productOptionId: string;
  displayName: string;
  isChecked: boolean;
  changeOrientation: Void;
  isDisabled: boolean;
}

const OrientationOption: FC<IProps> = ({
  productOptionId,
  displayName,
  isChecked,
  changeOrientation,
  isDisabled,
}) => (
  <>
    <input
      name="orientation"
      checked={isChecked}
      value={displayName}
      id={productOptionId}
      disabled={isDisabled}
      type={InputTypes.RADIO}
      className="orientation-input"
      onChange={changeOrientation}
    />
    <span
      className={`orientation-btn orientation-${displayName.toLocaleLowerCase()}`}
    />
  </>
);

export default OrientationOption;
