import { useCallback, useState, FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import ShippingAddressSchema from 'utils/validations/schemes/ShippingAddressSchema';
import getUpdatedDropDownItems from 'utils/checkout/getUpdatedDropdownItems';
import setTouchedFields from 'utils/checkout/setTouchedFields';
import getInitialValues from 'utils/formik/getInitialValues';

import shippingAddressCols from 'constants/checkout/shippingAddress/shippingAddressCols';
import dropDownList from 'constants/checkout/shippingAddress/dropDownList';
import { statesList } from 'constants/checkout/shippingAddress/statesList';
import CHECKOUT_ROUTES from 'constants/routes/checkout/routes';
import {
  dropShip,
  shippingOptions,
  studio,
} from 'constants/checkout/shippingAddress/shippingOptions';

import StateDropdown from 'components/StateInput/StateDropdown';
import Dropdown from 'components/Dropdown/Dropdown';
import StepControls from 'components/StepControls';

import { sessionIdSelector } from 'redux/auth';
import { cartIdSelector } from 'redux/cart';

import { IShippingAddressResponse } from 'types/checkout/shippingAddress';
import shippingAddressService from 'api/checkout/ShippingAddressService';
import ErrorPopup from 'pages/Checkout/components/ErrorPopup';
import useAppSelector from 'hooks/redux/useAppSelector';

import ShippingOption from './components/ShippingOption';
import CheckBody from './components/ChekBody';

const ShippingAddress: FC = () => {
  const [shippingAddressResponse, setShippingAddressResponse] =
    useState<IShippingAddressResponse | null>(null);

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [shippingType, setShippingType] = useState(studio.id);
  const [isFulfilled, setIsFulfilled] = useState(false);
  const [stateValue, setStateValue] = useState('');

  const sessionId = useAppSelector(sessionIdSelector);
  const cartId = useAppSelector(cartIdSelector);

  const history = useHistory();

  const nextStepHandler = useCallback(
    () => history.push(CHECKOUT_ROUTES.SUMMARY),
    [history],
  );

  const handlePreviousStep = useCallback(
    () => history.push(CHECKOUT_ROUTES.ORDER_INFORMATION),
    [history],
  );

  const hideErrorPopupHandler = useCallback(
    () => setShowErrorPopup(false),
    [],
  );

  const isDropShip = shippingType === dropShip.id;

  const shipToMyStudio = useCallback(async () => {
    if (!shippingAddressResponse || !sessionId || !cartId) return;

    const { shippingAddress } = shippingAddressResponse;

    const requestBody = {
      ...shippingAddress,
      isDropShip: false,
    };

    await shippingAddressService.putOrderShipping(
      sessionId,
      cartId,
      requestBody,
    );

    nextStepHandler();
  }, [cartId, nextStepHandler, sessionId, shippingAddressResponse]);

  const submitHandler = useCallback(
    async (values) => {
      if (!sessionId || !cartId || !shippingAddressResponse) return;

      const { shippingCarriers } = shippingAddressResponse;

      const shippingCarriersItem = shippingCarriers.find(
        ({ isSelected }) => isSelected,
      );

      const finalStateValue = stateValue.slice(-2);

      if (!shippingCarriersItem) return;

      const requestBody = {
        shipCarrier: shippingCarriersItem.id,
        isDropShip: true,
        firstName: values.firstName,
        lastName: values.lastName,
        address1: values.address,
        address2: values.apt,
        city: values.city,
        state: finalStateValue,
        zip: values.zipCode,
        emailAddress: values.email,
        phone: values.phone,
      };

      await shippingAddressService.putOrderShipping(
        sessionId,
        cartId,
        requestBody,
      );

      nextStepHandler();
    },
    [
      cartId,
      stateValue,
      sessionId,
      nextStepHandler,
      shippingAddressResponse,
    ],
  );

  const selectDropdownItem = useCallback(
    (dropdownId: string, dropdownItemId: string) => {
      if (!shippingAddressResponse) return;

      const dropdownItems = shippingAddressResponse[dropdownId];

      const checkedDropdownItems = Array.isArray(dropdownItems)
        ? dropdownItems
        : [];

      const updatedDropdownItems = getUpdatedDropDownItems(
        checkedDropdownItems,
        dropdownItemId,
      );

      setShippingAddressResponse({
        ...shippingAddressResponse,
        [dropdownId]: updatedDropdownItems,
      });
    },
    [shippingAddressResponse],
  );

  const validateHandler = useCallback(
    async (handleSubmit, validateForm, setTouched) => {
      const errors = await validateForm();

      const isError = Object.values(errors).length;

      if (isError) {
        setShowErrorPopup(true);

        return setTouched(setTouchedFields(shippingAddressCols));
      }

      setShowErrorPopup(false);

      return handleSubmit();
    },
    [],
  );

  const createInitialValues = () => {
    const defaultValues = getInitialValues(shippingAddressCols);

    const shippingAddress = shippingAddressResponse?.shippingAddress;

    if (!shippingAddress) return defaultValues;

    return {
      email: shippingAddress.emailAddress,
      firstName: shippingAddress.firstName,
      lastName: shippingAddress.lastName,
      address: shippingAddress.address1,
      apt: shippingAddress.address2,
      phone: shippingAddress.phone,
      zipCode: shippingAddress.zip,
      city: shippingAddress.city,
    };
  };

  const ShippingOptionList = shippingOptions.map(
    ({ id, labelContent }) => (
      <ShippingOption
        id={id}
        key={id}
        labelContent={labelContent}
        shippingType={shippingType}
        setShippingType={setShippingType}
      />
    ),
  );

  const DropDownList = dropDownList.map(
    ({ id, labelContent, className }) => {
      const dropDownItems = shippingAddressResponse?.[id];

      const checkedDropdownItems = Array.isArray(dropDownItems)
        ? dropDownItems
        : [];

      return (
        <Dropdown
          key={id}
          id={id}
          name={id}
          label={labelContent}
          className={className}
          dropDownItems={checkedDropdownItems}
          selectDropdownItem={selectDropdownItem}
        />
      );
    },
  );

  useEffect(() => {
    if (!sessionId || isFulfilled) return;

    shippingAddressService
      .getOrderShipping(sessionId)
      .then((data) => {
        setIsFulfilled(true);
        setShippingAddressResponse({ ...data, state: statesList });
      });
  }, [isFulfilled, sessionId]);

  return (
    <Formik
      enableReinitialize
      validateOnBlur={false}
      onSubmit={submitHandler}
      initialValues={createInitialValues()}
      validationSchema={ShippingAddressSchema}
    >
      {({ handleSubmit, validateForm, setTouched }) => {
        const updNextStepHandler = isDropShip
          ? () =>
              validateHandler(handleSubmit, validateForm, setTouched)
          : shipToMyStudio;

        return (
          <>
            {showErrorPopup && (
              <ErrorPopup
                message="Please fill in all required fields."
                hideErrorMessageHandler={hideErrorPopupHandler}
              />
            )}
            <CheckBody isDropShip={isDropShip}>
              {ShippingOptionList}
              {DropDownList}
              <StateDropdown
                stateValue={stateValue}
                setStateValue={setStateValue}
              />
            </CheckBody>
            <StepControls
              isSubmit
              className="check-footer"
              nextStepContent="Summary ->"
              previousStepContent="Back"
              previousStepHandler={handlePreviousStep}
              nextStepHandler={updNextStepHandler}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default ShippingAddress;
