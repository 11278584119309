import { useCallback, useContext } from 'react';

import scaleFromCenterPoint from 'utils/editor/scale/scaleFromCenterPoint';
import calcCroppedImageDpi from 'utils/editor/dpi/calcCroppedImageDpi';

import { activeImageObjectSelector } from 'redux/gallery';
import { setCroppedImageDpi } from 'redux/editor/editorReducer';
import { selectedProductSizeOptionSelector } from 'redux/sidePanel';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { CropUpdateContext } from 'context/contexts/editor/canvas';
import { DPI_LIMIT } from 'constants/editor/general';

import useShapeIntersectionChecks from '../intersections/useShapeIntersectionChecks';
import usePrevValues from '../intersections/usePrevValues';
import useCroppedImageDpi from '../view/useCroppedImageDpi';

type ZoomHandler = (scale?: number) => void;

type ZoomHandlers = [ZoomHandler, ZoomHandler];

const useImageWrapperZoom = (): ZoomHandlers => {
  const { mainLayerRef, imageWrapperRef, croppedImageRef } =
    useEditorRefs();
  const cropUpdate = useContext(CropUpdateContext);

  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const selectedProductSizeOption = useAppSelector(
    selectedProductSizeOptionSelector,
  );

  const dispatch = useAppDispatch();

  const getIsShapeIntersection = useShapeIntersectionChecks();
  const [, calcDpi] = useCroppedImageDpi();
  const {
    setPrevValues,
    prevImageWrapperScale,
    prevImageWrapperPosition,
  } = usePrevValues();

  const zoomOutHandler = useCallback(
    (scaleValue?: number) => {
      const mainLayer = mainLayerRef.current;
      const imageWrapper = imageWrapperRef.current;

      if (
        !mainLayer ||
        !imageWrapper ||
        !prevImageWrapperScale ||
        !prevImageWrapperPosition
      )
        return;

      const imageWrapperScale = imageWrapper.scale();
      const mainLayerScale = mainLayer.scale();

      if (!imageWrapperScale || !mainLayerScale) return;

      const scale = 0.0008;

      const finalScale = scaleValue || scale;

      if (!finalScale) return;

      scaleFromCenterPoint({
        scaleObject: imageWrapper,
        isZoomOut: true,
        scaleX: finalScale,
      });

      const shapeIntersection = getIsShapeIntersection();

      if (shapeIntersection) {
        imageWrapper.setAbsolutePosition(prevImageWrapperPosition);
        imageWrapper.scale(prevImageWrapperScale);
      } else {
        setPrevValues();
      }

      cropUpdate();

      const currentDpi = calcDpi();

      dispatch(setCroppedImageDpi(currentDpi));
    },
    [
      calcDpi,
      cropUpdate,
      dispatch,
      getIsShapeIntersection,
      imageWrapperRef,
      mainLayerRef,
      prevImageWrapperPosition,
      prevImageWrapperScale,
      setPrevValues,
    ],
  );

  const zoomInHandler = useCallback(
    (scaleValue?: number) => {
      const imageWrapper = imageWrapperRef.current;
      const croppedImage = croppedImageRef.current;

      if (
        !imageWrapper ||
        !croppedImage ||
        !activeImageObject ||
        !selectedProductSizeOption
      )
        return;

      const scale = 0.0008;

      const finalScale = scaleValue || scale;

      const size = selectedProductSizeOption?.size;

      if (!size) return;

      const imgWrapperAbsWidth =
        (imageWrapper.scaleX() + finalScale) * imageWrapper.width();

      const nextDpi = calcCroppedImageDpi(
        activeImageObject.originalImageWidthInPixels,
        +size.width,
        imgWrapperAbsWidth,
        croppedImage.width(),
      );

      if (nextDpi < DPI_LIMIT) return;

      scaleFromCenterPoint({
        scaleObject: imageWrapper,
        isZoomOut: false,
        scaleX: finalScale,
      });

      setPrevValues();

      cropUpdate();

      const currentDpi = calcDpi();

      dispatch(setCroppedImageDpi(currentDpi));
    },
    [
      calcDpi,
      dispatch,
      cropUpdate,
      setPrevValues,
      croppedImageRef,
      imageWrapperRef,
      activeImageObject,
      selectedProductSizeOption,
    ],
  );

  return [zoomInHandler, zoomOutHandler];
};

export default useImageWrapperZoom;
