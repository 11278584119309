import { FC } from 'react';

import LazyInput from 'components/LazyInput';
import { Event, Void } from 'types/general';

interface IProps {
  quantity: number;
  disabled?: boolean;
  btnClassNames: string;
  incrementQuantity: Void;
  decrementQuantity: Void;
  inputClassNames: string;
  changeQuantityHandler: (e: Event<HTMLInputElement>) => void;
}

const Counter: FC<IProps> = ({
  disabled,
  quantity,
  btnClassNames,
  inputClassNames,
  incrementQuantity,
  decrementQuantity,
  changeQuantityHandler,
}: IProps) => (
  <>
    <button
      className={btnClassNames}
      onClick={decrementQuantity}
      disabled={disabled}
    >
      -
    </button>
    <LazyInput
      disabled={disabled}
      defaultValue={quantity}
      className={inputClassNames}
      changeHandler={changeQuantityHandler}
    />
    <button
      className={btnClassNames}
      onClick={incrementQuantity}
      disabled={disabled}
    >
      +
    </button>
  </>
);

Counter.defaultProps = {
  disabled: false,
};

export default Counter;
