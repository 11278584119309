const moveElemToEndOfArray = (
  elem: string,
  array: string[],
): string[] => {
  const selectedOptionsWithoutRepeatOption = array.filter(
    (item: string) => item !== elem,
  );

  return [...selectedOptionsWithoutRepeatOption, elem];
};

export default moveElemToEndOfArray;
