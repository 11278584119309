import { ISummary } from 'types/checkout/summary';

import axios from 'axios/config';
import urls from 'api/urls';

class SummaryService {
  async getOrderSummary(sessionId: string): Promise<ISummary | void> {
    try {
      const config = {
        params: {
          CartSessionId: sessionId,
        },
      };

      const { data } = await axios.get(
        urls.cart.getOrderSummary,
        config,
      );

      return data;
    } catch (error) {
      return console.log(error);
    }
  }

  async checkoutCart(
    sessionId: string,
    cartId: number,
  ): Promise<void> {
    try {
      const requestConfig = {
        params: {
          CartSessionId: sessionId,
        },
      };

      const requestBody = {
        cartid: cartId,
      };

      await axios.post(
        urls.cart.checkoutCartFunction,
        requestBody,
        requestConfig,
      );
    } catch (error) {
      console.log(error);
    }
  }
}

export default new SummaryService();
