import { ICoordinates, ISize } from 'types/editor';

const getNewIntersectionPos = (
  currentPos: ICoordinates,
  internalSize: ISize,
  externalSize: ISize,
): ICoordinates => {
  let newPos = { ...currentPos };

  if (newPos.x < 0) {
    newPos = { x: 0, y: newPos.y };
  }

  if (newPos.y < 0) {
    newPos = { x: newPos.x, y: 0 };
  }

  if (newPos.x + internalSize.width > externalSize.width) {
    newPos = {
      x: externalSize.width - internalSize.width,
      y: newPos.y,
    };
  }

  if (newPos.y + internalSize.height > externalSize.height) {
    newPos = {
      x: newPos.x,
      y: externalSize.height - internalSize.height,
    };
  }

  return newPos;
};

export default getNewIntersectionPos;
