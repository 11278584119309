import { FC, useEffect } from 'react';
import cn from 'classnames';

import useCanvasResponsive from 'hooks/editor/view/useCanvasResponsive';
import useCroppedImageDpi from 'hooks/editor/view/useCroppedImageDpi';
import useMainLayerZoom from 'hooks/editor/zoom/useMainLayerZoom';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';
import useEditMode from 'hooks/editor/useEditMode';

import ContentControlButton from 'components/Buttons/ContentControlButton';
import ContentControlIcon from 'components/Icons/ContentControlIcon';
import Icon from 'components/Icons/Icon';
import ThinkBubble from 'components/ThinkBubble';

import {
  setEyeDropperMode,
  colorSelector,
  setColor,
  showColorPickerSelector,
} from 'redux/editor/colorPicker';

import {
  edgeColorSelector,
  isSelectedProductSizeOptionSelector,
  selectedEdgeSelector,
} from 'redux/sidePanel';
import { DPI_LIMIT } from 'constants/editor/general';
import { ZOOM_VALUES } from 'constants/editor/zoom';

import { edgeOptionsId } from 'constants/editor/canvasWraps';
import Canvas from '../Canvas';

const EditorControls: FC = () => {
  const showColorPicker = useAppSelector(showColorPickerSelector);
  const selectedEdge = useAppSelector(selectedEdgeSelector);
  const edgeColor = useAppSelector(edgeColorSelector);
  const color = useAppSelector(colorSelector);
  const isSelectedProductSizeOption = useAppSelector(
    isSelectedProductSizeOptionSelector,
  );

  const dispatch = useAppDispatch();

  const [zoomInMainLayer, zoomOutMainLayer] = useMainLayerZoom();
  const [croppedImageDpi] = useCroppedImageDpi();
  const [dpi] = useCroppedImageDpi();
  const [isEditMode] = useEditMode();

  useCanvasResponsive();

  const isShowViewControls =
    isEditMode && isSelectedProductSizeOption;

  const [, COLOR] = edgeOptionsId;

  const isColorEdge = selectedEdge?.productOptionId === COLOR;

  const defaultViewHandler = () => {};

  const roomViewHandler = () => {};

  const zoomInMainLayerHandler = () =>
    zoomInMainLayer(ZOOM_VALUES.ZOOM_DESKTOP_VALUE);

  const zoomOutMainLayerHandler = () =>
    zoomOutMainLayer(ZOOM_VALUES.ZOOM_DESKTOP_VALUE);

  const showDpiBorder = dpi < DPI_LIMIT;

  useEffect(() => {
    if (color || !edgeColor) return;

    const { productOptions } = edgeColor;

    const defaultColor = productOptions[0].productOptionDynamicValue;

    if (!defaultColor) return;

    dispatch(setColor(defaultColor));
  }, [color, edgeColor, dispatch]);

  useEffect(() => {
    if (isColorEdge && showColorPicker) {
      dispatch(setEyeDropperMode(true));
      return;
    }

    dispatch(setEyeDropperMode(false));
  }, [dispatch, isColorEdge, showColorPicker]);

  return (
    <>
      <Icon className="icon-landscape" />
      {isShowViewControls && (
        <>
          <ContentControlButton
            className={cn('dpi', { border: showDpiBorder })}
          >
            {croppedImageDpi} dpi
            {showDpiBorder && (
              <ThinkBubble
                className={cn('think-bubble', {
                  border: showDpiBorder,
                })}
              >
                Cropped image area is currently too small
              </ThinkBubble>
            )}
          </ContentControlButton>
          <ContentControlButton
            clickHandler={roomViewHandler}
            className="room"
          >
            <ContentControlIcon className="icon-room-view" />
          </ContentControlButton>
          <ContentControlButton
            className="default"
            clickHandler={defaultViewHandler}
          >
            <ContentControlIcon className="icon-default-view" />
          </ContentControlButton>
        </>
      )}
      <ContentControlButton
        className="zoomin"
        clickHandler={zoomInMainLayerHandler}
      >
        <ContentControlIcon className="icon-zoom-in" />
      </ContentControlButton>
      <ContentControlButton
        className="zoomout"
        clickHandler={zoomOutMainLayerHandler}
      >
        <ContentControlIcon className="icon-zoom-out" />
      </ContentControlButton>
      <Canvas />
    </>
  );
};

export default EditorControls;
