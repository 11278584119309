import { useState, FC, ChangeEvent } from 'react';
import cn from 'classnames';

import { statesList } from 'constants/checkout/shippingAddress/statesList';
import Icon from 'components/Icons/Icon';

interface IProps {
  stateValue: string;
  setStateValue: (value: string) => void;
}

const StateDropdown: FC<IProps> = ({ stateValue, setStateValue }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStateValue(e.target.value);

    if (isOpen) return;

    setIsOpen(true);
  };

  const handleArrowClick = () => setIsOpen(!isOpen);

  const onStateSelect = (state: string) => {
    setStateValue(state);
    setIsOpen(false);
  };

  const filteredStatesList = statesList.filter(
    (state) =>
      state.name
        .toLowerCase()
        .startsWith(stateValue.trim().toLowerCase()) ||
      state.abbreviation
        .toLowerCase()
        .startsWith(stateValue.trim().toLowerCase()),
  );

  const StatesList = filteredStatesList.map((state) => (
    <li className="state-item-wrapper" key={state.name}>
      <span
        className="state-item"
        onClick={() =>
          onStateSelect(`${state.name}, ${state.abbreviation}`)
        }
      >
        {`${state.name}, ${state.abbreviation}`}
      </span>
    </li>
  ));

  return (
    <div
      className={cn('col-14 col-lg-7 order-2 check-col', {
        space: isOpen,
      })}
    >
      <label htmlFor="state" className="dropdown-label">
        State *
      </label>
      <div className="dropdown-container">
        <input
          id="state"
          className="check-input"
          type="text"
          value={stateValue}
          onChange={handleInputChange}
          autoComplete="off"
          placeholder="Type state"
        />
        <span
          className="dropdown-arrow-wrapper"
          onClick={handleArrowClick}
        >
          <Icon className="dropdown-arrow icon-arrow" />
        </span>
        {isOpen && <ul className="state-list">{StatesList}</ul>}
      </div>
    </div>
  );
};

export default StateDropdown;
