import { FC, useState, useCallback, useEffect } from 'react';

import { SidebarPopupContext } from 'context/contexts/editor/general';
import { IContextProps } from 'types/contextProps';

const SidebarPopupContextProvider: FC<IContextProps> = ({
  children,
}) => {
  const [showSidebarPopup, setShowSidebarPopup] = useState(false);

  const showSidebarPopupHandler = useCallback(
    (isShow: boolean) => setShowSidebarPopup(isShow),
    [],
  );

  const hideSidebarPopup = useCallback(
    () => setShowSidebarPopup(false),
    [],
  );

  const sidebarPopupContextValue = {
    showSidebarPopup,
    showSidebarPopupHandler,
  };

  useEffect(() => {
    document.addEventListener('click', hideSidebarPopup);

    return () => {
      document.removeEventListener('click', hideSidebarPopup);
    };
  }, [hideSidebarPopup]);

  return (
    <SidebarPopupContext.Provider value={sidebarPopupContextValue}>
      {children}
    </SidebarPopupContext.Provider>
  );
};

export default SidebarPopupContextProvider;
