import { useContext } from 'react';

import LoaderContext, {
  ILoaderContext,
} from 'context/contexts/loaderContext';

const useLoader = (): ILoaderContext => {
  const context = useContext(LoaderContext);

  if (!context) {
    throw new Error('useLoader used outside LoaderContext');
  }

  return context;
};

export default useLoader;
