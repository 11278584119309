import { FC } from 'react';

const CartTableHead: FC = () => (
  <thead className="cart-head">
    <tr>
      <th className="cart-th">product information</th>
      <th className="cart-th">price</th>
      <th className="cart-th">quantity</th>
      <th className="cart-th" colSpan={10}>
        total
      </th>
    </tr>
  </thead>
);

export default CartTableHead;
