import { BrowserRouter } from 'react-router-dom';
import { FC } from 'react';
import Konva from 'konva';

import useAppSelector from 'hooks/redux/useAppSelector';
import { isDarkThemeSelector } from 'redux/theme';
import Layout from 'Layout';

import './assets/scss/main.scss';

Konva.pixelRatio = 1;

const App: FC = () => {
  const isDarkTheme = useAppSelector(isDarkThemeSelector);

  const themeStyle = isDarkTheme ? 'theme-dark' : 'theme-light';

  return (
    <div className={`theme ${themeStyle}`}>
      <div className="body">
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </div>
    </div>
  );
};

export default App;
