import { IField } from 'types/ui/checkout';

export const studio = {
  id: 'studio',
  labelContent: 'Studio Reference # *',
  className: 'col-14 col-lg-7 order-1',
};

export const description = {
  id: 'description',
  labelContent: 'Order Description *',
  className: 'col-14 check-col-middle order-2',
};

export const coupon = {
  id: 'coupon',
  labelContent: 'Coupon Code',
  className: 'col-14 col-lg-7 order-3',
};

export const orderInformationCols: IField[] = [
  studio,
  description,
  coupon,
];
