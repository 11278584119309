import React, { useState } from 'react';
import cn from 'classnames';

import Submenu from './Submenu';

const MenuList: React.FC = () => {
  const [isOpened, setOpened] = useState(true);

  const toggleMenu = () => {
    setOpened(!isOpened);
  };

  return (
    <ul className="menu-list-body">
      <li>
        <div
          role="presentation"
          onClick={toggleMenu}
          className={cn('menu-list-item', {
            'is-active': isOpened,
          })}
        >
          <i className="menu-list-icon icon icon-wall" />
          Wall Displays
          <i className="menu-dropdown icon icon-arrow" />
        </div>
        {isOpened && (
          <div className="submenu-container">
            <Submenu />
          </div>
        )}
      </li>

      <li>
        <div className="menu-list-item">
          <i className="menu-list-icon icon icon-user" />
          Customer Service
        </div>
      </li>
    </ul>
  );
};

export default MenuList;
