import { useCallback, useContext } from 'react';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useEditMode from 'hooks/editor/useEditMode';

import scaleFromCenterPoint from 'utils/editor/scale/scaleFromCenterPoint';
import { CropUpdateContext } from 'context/contexts/editor/canvas';
import { ZOOM_VALUES } from 'constants/editor/zoom';

import useFixedValues from '../intersections/usePrevValues';

type Func = (zoomValue: number) => void | null;

type ZoomHandlers = [Func, Func];

const useMainLayerZoom = (): ZoomHandlers => {
  const cropUpdate = useContext(CropUpdateContext);
  const { mainLayerRef } = useEditorRefs();

  const { setPrevValues } = useFixedValues();
  const [isEditMode] = useEditMode();

  const zoomInHandler = useCallback(
    (zoomValue: number) => {
      const mainLayer = mainLayerRef.current;

      if (!mainLayer) return null;

      const nextScale = mainLayer.scaleX() + zoomValue;

      if (!isEditMode) {
        if (nextScale > 3) return null;

        return scaleFromCenterPoint({
          scaleObject: mainLayer,
          isZoomOut: false,
          scaleX: zoomValue,
        });
      }

      scaleFromCenterPoint({
        scaleObject: mainLayer,
        isZoomOut: false,
        scaleX: zoomValue,
      });

      setPrevValues();
      return cropUpdate();
    },
    [cropUpdate, mainLayerRef, isEditMode, setPrevValues],
  );

  const zoomOutHandler = useCallback(
    (zoomValue: number) => {
      const mainLayer = mainLayerRef.current;

      if (!mainLayer) return null;

      const nextScale = mainLayer.scaleX() - zoomValue;

      if (nextScale < ZOOM_VALUES.START_ZOOM) return null;

      if (!isEditMode) {
        return scaleFromCenterPoint({
          scaleObject: mainLayer,
          isZoomOut: true,
          scaleX: zoomValue,
        });
      }

      scaleFromCenterPoint({
        scaleObject: mainLayer,
        isZoomOut: true,
        scaleX: zoomValue,
      });

      setPrevValues();
      return cropUpdate();
    },
    [cropUpdate, isEditMode, mainLayerRef, setPrevValues],
  );

  return [zoomInHandler, zoomOutHandler];
};

export default useMainLayerZoom;
