import { useCallback, useEffect, useState } from 'react';
import { KonvaEventObject } from 'konva/lib/Node';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';

import { ICoordinates } from 'types/editor';
import { setIsTouchZoom } from 'redux/editor/editorReducer';

import useImageWrapperZoom from './useImageWrapperZoom';

const useImageWrapperTouchZoom = (): void => {
  const [touchPoint1, setTouchPoint1] = useState<ICoordinates | null>(
    null,
  );
  const [touchPoint2, setTouchPoint2] = useState<ICoordinates | null>(
    null,
  );

  const { imageWrapperRef } = useEditorRefs();

  const dispatch = useAppDispatch();

  const { isDesktop } = useDefineDeviceByWindowSize();
  const [zoomInImageWrapper, zoomOutImageWrapper] =
    useImageWrapperZoom();

  const touchZoomHandler = useCallback(
    (e: KonvaEventObject<TouchEvent>) => {
      e.evt.preventDefault();

      const imageWrapper = imageWrapperRef.current;

      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];

      if (!touch1 || !touch2 || !imageWrapper) return;

      imageWrapper.draggable(false);

      const currentTouchPoint1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      };

      const currentTouchPoint2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      };

      const xDiff = Math.abs(
        currentTouchPoint1.x - currentTouchPoint2.x,
      );
      const yDiff = Math.abs(
        currentTouchPoint1.y - currentTouchPoint2.y,
      );

      if (xDiff > yDiff) {
        const leftPoint =
          currentTouchPoint1.x < currentTouchPoint2.x
            ? currentTouchPoint1
            : currentTouchPoint2;

        const rightPoint =
          currentTouchPoint1.x > currentTouchPoint2.x
            ? currentTouchPoint1
            : currentTouchPoint2;

        if (!touchPoint1 && !touchPoint2) {
          setTouchPoint1(leftPoint);
          setTouchPoint2(rightPoint);
        }

        if (!touchPoint1 || !touchPoint2) return;

        const isDecreasedLeftPoint = leftPoint.x < touchPoint1.x;
        const isIncreasedRightPoint = rightPoint.x > touchPoint2.x;

        if (isDecreasedLeftPoint && isIncreasedRightPoint) {
          zoomInImageWrapper();
        }

        if (!isDecreasedLeftPoint && !isIncreasedRightPoint) {
          zoomOutImageWrapper();
        }

        setTouchPoint1(leftPoint);
        setTouchPoint2(rightPoint);
      }

      if (xDiff < yDiff) {
        const leftPoint =
          currentTouchPoint1.y < currentTouchPoint2.y
            ? currentTouchPoint1
            : currentTouchPoint2;

        const rightPoint =
          currentTouchPoint1.y > currentTouchPoint2.y
            ? currentTouchPoint1
            : currentTouchPoint2;

        if (!touchPoint1 && !touchPoint2) {
          setTouchPoint1(leftPoint);
          setTouchPoint2(rightPoint);
        }

        if (!touchPoint1 || !touchPoint2) return;

        const isDecreasedLeftPoint = leftPoint.y < touchPoint1.y;
        const isIncreasedRightPoint = rightPoint.y > touchPoint2.y;

        if (isDecreasedLeftPoint && isIncreasedRightPoint) {
          zoomInImageWrapper();
        }

        if (!isDecreasedLeftPoint && !isIncreasedRightPoint) {
          zoomOutImageWrapper();
        }

        setTouchPoint1(leftPoint);
        setTouchPoint2(rightPoint);
      }

      dispatch(setIsTouchZoom(true));
    },
    [
      dispatch,
      imageWrapperRef,
      touchPoint1,
      touchPoint2,
      zoomInImageWrapper,
      zoomOutImageWrapper,
    ],
  );

  const clearTouchPoints = useCallback(() => {
    const imageWrapper = imageWrapperRef.current;

    if (!imageWrapper) return;

    imageWrapper.draggable(true);

    setTouchPoint1(null);
    setTouchPoint2(null);
  }, [imageWrapperRef]);

  useEffect(() => {
    const imageWrapper = imageWrapperRef.current;

    if (!imageWrapper || isDesktop) return () => {};

    imageWrapper.on('touchmove', touchZoomHandler);
    imageWrapper.on('touchend', clearTouchPoints);

    return () => {
      imageWrapper.off('touchmove', touchZoomHandler);
      imageWrapper.off('touchend', clearTouchPoints);
    };
  }, [
    isDesktop,
    clearTouchPoints,
    imageWrapperRef,
    touchZoomHandler,
  ]);
};

export default useImageWrapperTouchZoom;
