import { FC } from 'react';
import {
  DropzoneRootProps,
  DropzoneInputProps,
} from 'react-dropzone';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import { Event } from 'types/general';

import MobileBlock from './components/MobileBlock';
import DesktopBlock from './components/DesktopBlock';

interface IProps {
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
  hidePopupsHandler: (e: Event<HTMLDivElement>) => void;
}

const Upload: FC<IProps> = ({
  rootProps,
  inputProps,
  hidePopupsHandler,
}) => {
  const { isDesktop } = useDefineDeviceByWindowSize();

  return (
    <div
      {...rootProps}
      className="content-upload"
      onClick={hidePopupsHandler}
    >
      <h2 className="content-title">Add images</h2>
      {isDesktop ? (
        <DesktopBlock inputProps={inputProps} />
      ) : (
        <MobileBlock inputProps={inputProps} />
      )}
    </div>
  );
};

export default Upload;
