import { useCallback } from 'react';

import { activeImageObjectSelector } from 'redux/gallery';
import { sessionIdSelector } from 'redux/auth';
import { lineItemsSelector } from 'redux/cart';
import {
  optionGroupsSelector,
  productCategorySelector,
} from 'redux/sidePanel';
import {
  cropObjectSelector,
  visualRotationSelector,
} from 'redux/editor';

import productOptionService from 'utils/sidePanel/ProductOptionService';
import useAppSelector from 'hooks/redux/useAppSelector';
import { ILineItemBody } from 'types/cart';

type FindExistLineItem = (
  currentQuantity: number,
) => ILineItemBody | null;

const useExistLineItem = (): FindExistLineItem => {
  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const visualRotation = useAppSelector(visualRotationSelector);
  const optionGroups = useAppSelector(optionGroupsSelector);
  const cropObject = useAppSelector(cropObjectSelector);
  const lineItems = useAppSelector(lineItemsSelector);
  const sessionId = useAppSelector(sessionIdSelector);
  const currentProductCategory = useAppSelector(
    productCategorySelector,
  );

  const findExistLineItem = useCallback(
    (currentQuantity: number) => {
      if (
        !activeImageObject ||
        !sessionId ||
        !cropObject ||
        !currentProductCategory
      )
        return null;

      const currentSelectedOptions =
        productOptionService.getSelectedProductOptions(optionGroups);

      const existLineItem = lineItems.find((lineItem) => {
        const { sides, selectedOptions, productCategory } = lineItem;

        if (!sides || !productCategory) return false;

        const side = sides[0];

        const convertedLineItemCropObject = Object.values(side.crop);
        const convertedCropObject = Object.values(cropObject);

        const isEqualSelectedOptions = currentSelectedOptions.every(
          (selectedOption, index) =>
            selectedOption.productOptionId ===
            selectedOptions[index].productOptionId,
        );

        const isEqualCrop = convertedLineItemCropObject.every(
          (field, index) => convertedCropObject[index] === field,
        );

        const isEqualRotation = visualRotation === side.rotation;

        const isEqualProductCategory =
          productCategory ===
          currentProductCategory.productCategoryType;

        const isEqualImageGuid =
          activeImageObject.imageGuid === side.imageGuid;

        return (
          isEqualCrop &&
          isEqualImageGuid &&
          isEqualProductCategory &&
          isEqualProductCategory &&
          isEqualRotation &&
          isEqualSelectedOptions
        );
      });

      if (!existLineItem) return null;

      const { productId, lineItemId, sides } = existLineItem;

      if (!productId || !lineItemId || !sides) return null;

      const isEqualQuantity =
        existLineItem.quantity === currentQuantity;

      const lineItemInfo = {
        productId,
        lineItemId,
        productCategory: currentProductCategory.productCategoryType,
        lineItemJson: {
          selectedOptions: currentSelectedOptions,
          sides,
        },
      };

      if (isEqualQuantity) {
        return {
          ...lineItemInfo,
          quantity: existLineItem.quantity + 1,
        };
      }

      return {
        ...lineItemInfo,
        quantity: currentQuantity,
      };
    },
    [
      activeImageObject,
      cropObject,
      currentProductCategory,
      lineItems,
      optionGroups,
      sessionId,
      visualRotation,
    ],
  );

  return findExistLineItem;
};

export default useExistLineItem;
