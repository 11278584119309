import { useEffect } from 'react';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppSelector from 'hooks/redux/useAppSelector';
import { eyeDropperModeSelector } from 'redux/editor/colorPicker';

import eyeDropperIcon from 'assets/img/EyeDropperIcon.png';

const useImageWrapperPointers = (): void => {
  const { stageRef, imageWrapperRef } = useEditorRefs();

  const eyeDropperMode = useAppSelector(eyeDropperModeSelector);

  const { isDesktop } = useDefineDeviceByWindowSize();

  useEffect(() => {
    const imageWrapper = imageWrapperRef.current;
    const stage = stageRef.current;

    if (!stage || !imageWrapper || !isDesktop) return () => {};

    const setGrabbingCursor = () => {
      stage.container().style.cursor = 'grabbing';
    };

    const setGrabCursor = () => {
      if (eyeDropperMode) {
        stage.container().style.cursor = `url(${eyeDropperIcon}), auto`;
        return;
      }

      stage.container().style.cursor = 'grab';
    };

    const setDefaultCursor = () => {
      stage.container().style.cursor = 'default';
    };

    imageWrapper.on('mouseleave', setDefaultCursor);
    imageWrapper.on('dragstart', setGrabbingCursor);
    imageWrapper.on('mouseenter', setGrabCursor);
    imageWrapper.on('dragend', setGrabCursor);

    return () => {
      imageWrapper.off('mouseleave', setDefaultCursor);
      imageWrapper.off('dragstart', setGrabbingCursor);
      imageWrapper.off('mouseenter', setGrabCursor);
      imageWrapper.off('dragend', setGrabCursor);
    };
  }, [imageWrapperRef, eyeDropperMode, isDesktop, stageRef]);
};

export default useImageWrapperPointers;
