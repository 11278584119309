import { FC } from 'react';
import cn from 'classnames';

import { IButtonProps } from '../types';
import { ButtonType } from '../enums';

const Button: FC<IButtonProps> = ({
  type,
  children,
  isActive,
  className,
  isDisabled,
  clickHandler,
  isSecondary,
}: IButtonProps) => (
  <button
    type={type}
    disabled={isDisabled}
    onClick={clickHandler}
    className={cn('btn', className, {
      'is-active': isActive,
      'btn-secondary': isSecondary,
    })}
  >
    {children}
  </button>
);

Button.defaultProps = {
  type: ButtonType.BUTTON,
  clickHandler: () => {},
  isDisabled: false,
  isActive: false,
};

export default Button;
