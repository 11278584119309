import { FC, memo } from 'react';

import { InputTypes } from 'types/input';

interface IProps {
  id: string;
  labelContent: string;
  shippingType: string;
  setShippingType: (id: string) => void;
}

const ShippingOption: FC<IProps> = memo(
  ({ id, labelContent, shippingType, setShippingType }: IProps) => {
    const handleChange = () => setShippingType(id);

    const checked = shippingType === id;

    return (
      <label className="check-radio-label" htmlFor={id}>
        <input
          id={id}
          name="shipping"
          checked={checked}
          type={InputTypes.RADIO}
          onChange={handleChange}
          className="check-radio-input hidden"
        />
        <span className="check-radio-icon" />
        {labelContent}
      </label>
    );
  },
);

export default ShippingOption;
