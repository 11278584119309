import { Route, Switch } from 'react-router-dom';
import { FC } from 'react';

import checkoutRouteList from 'constants/routes/checkout/checkoutRouteList';

const CheckoutRouter: FC = () => (
  <Switch>
    {checkoutRouteList.map(({ path, component }) => (
      <Route key={path} path={path} component={component} />
    ))}
  </Switch>
);

export default CheckoutRouter;
