import { FC, memo } from 'react';

interface IProps {
  studioName: string;
  accountNumber?: number;
}

const ConfirmHead: FC<IProps> = memo(
  ({ studioName, accountNumber }: IProps) => (
    <div className="confirm-head">
      <span className="confirm-title">
        Studio Name:&nbsp;
        <span className="confirm-subtitle">{studioName}</span>
      </span>
      <span className="confirm-title">
        Studio Acct:&nbsp;
        <span className="confirm-subtitle">
          {accountNumber && `# ${accountNumber}`}
        </span>
      </span>
    </div>
  ),
);

ConfirmHead.defaultProps = {
  accountNumber: undefined,
};

export default ConfirmHead;
