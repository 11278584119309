import { ChangeEvent, FC, useState } from 'react';
import { Field } from 'formik';

import { InputTypes } from 'types/input';

interface IProps {
  id: string;
  value: string;
  changeHandler: (e: ChangeEvent<string>) => void;
}

const PasswordField: FC<IProps> = ({ id, value, changeHandler }) => {
  const [passVisible, isPassVisible] = useState(false);

  const passToggler = () => {
    isPassVisible(!passVisible);
  };

  const iconEye = passVisible ? 'icon-eye' : 'icon-eye-closed';

  const fieldType = passVisible
    ? InputTypes.TEXT
    : InputTypes.PASSWORD;

  return (
    <>
      <Field
        id={id}
        name={id}
        type={fieldType}
        className="check-input"
        onChange={changeHandler}
        value={value}
      />
      <i
        className={`check-pass icon  ${iconEye}`}
        onClick={passToggler}
      />
    </>
  );
};

export default PasswordField;
