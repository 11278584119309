interface IShapeValues {
  rotation: number;
  scaleX: number;
  scaleY: number;
  x: number;
  y: number;
}

class EditorCoreService {
  getGroupCoords(
    productMatrix: number[],
    imageMatrix: number[],
  ): IShapeValues {
    const [M, N, O, P, R, S] = imageMatrix;
    const [A, B, C, D, E, F] = productMatrix;

    const AD = A * D;
    const BC = B * C;
    const G = (C * N - M * D) / (BC - AD);
    const H = (A * N - M * B) / (AD - BC);
    const I = (C * P - O * D) / (BC - AD);
    const J = (A * P - O * B) / (AD - BC);
    const K = (C * (S - F) + D * (E - R)) / (BC - AD);
    const L = (A * (S - F) + B * (E - R)) / (AD - BC);

    const matrix = [G, H, I, J, K, L];
    const options = this.qrDecompose(matrix);

    return options;
  }

  qrDecompose(matrix: number[]): IShapeValues {
    const [A, B, C, D, E, F] = matrix;

    const angle = Math.atan2(B, A);
    const denom = A ** 2 + B ** 2;

    const scaleX = Math.sqrt(denom);
    const scaleY = (A * D - C * B) / scaleX;

    const rotation = angle / (Math.PI / 180);

    return {
      rotation,
      scaleX,
      scaleY,
      x: E,
      y: F,
    };
  }
}

export default new EditorCoreService();
