import { FC, useCallback } from 'react';

import useAppSelector from 'hooks/redux/useAppSelector';
import useAppDispatch from 'hooks/redux/useAppDispatch';

import { isDarkThemeSelector, setIsDarkTheme } from 'redux/theme';
import { SECOND_FAZE } from 'constants/temp';
import Switch from 'components/Switch';
import { Event } from 'types/general';

import FooterRoomSettings from './FooterRoomSettings';

const FooterSettings: FC = () => {
  const isDarkTheme = useAppSelector(isDarkThemeSelector);

  const dispatch = useAppDispatch();

  const stopPropagationHandler = (e: Event<HTMLDivElement>) =>
    e.stopPropagation();

  const changeThemeHandler = useCallback(() => {
    dispatch(setIsDarkTheme(!isDarkTheme));
  }, [dispatch, isDarkTheme]);

  return (
    <div className="footer-settings" onClick={stopPropagationHandler}>
      {SECOND_FAZE && (
        <>
          <span className="footer-settings-title">Show / Hide</span>
          <div className="footer-settings-body">
            <Switch
              label="Filenames"
              id="filenames"
              changeHandler={() => {}}
              checked={false}
            />
            <Switch
              label="Prices"
              id="prices"
              changeHandler={() => {}}
              checked={false}
            />
            <Switch
              label="Branding"
              id="branding"
              changeHandler={() => {}}
              checked={false}
            />
          </div>
        </>
      )}
      <Switch
        id="darktheme"
        label="Dark Theme"
        checked={isDarkTheme}
        changeHandler={changeThemeHandler}
      />
      {SECOND_FAZE && <FooterRoomSettings />}
    </div>
  );
};

export default FooterSettings;
