import { animated } from '@react-spring/web';
import cn from 'classnames';
import { FC } from 'react';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';

import productOptionService from 'utils/sidePanel/ProductOptionService';
import OPTION_STATES from 'constants/sidePanel/optionStates';
import { IProductOption } from 'types/general';
import Icon from 'components/Icons/Icon';

interface IProps {
  disable: boolean;
  isClicked: boolean;
  animations: any;
  setIsClicked: (isClicked: boolean) => void;
  setCardId: (id: string) => void;
  productOption: IProductOption;
  selectProductOptionHandler: (productOption: IProductOption) => void;
}

const Card: FC<IProps> = ({
  disable,
  isClicked,
  animations,
  setCardId,
  setIsClicked,
  productOption,
  selectProductOptionHandler,
}) => {
  const {
    optionsStates,
    imageUrl,
    price,
    displayName,
    productOptionId,
  } = productOption;

  const { isDesktop, isTablet } = useDefineDeviceByWindowSize();

  const isActive =
    productOptionService.getOptionStateValue(
      optionsStates,
      OPTION_STATES.IS_ACTIVE,
    ) && !disable;

  const isSelected = productOptionService.getOptionStateValue(
    optionsStates,
    OPTION_STATES.IS_SELECTED,
  );

  const imagePreviewStyle = {
    backgroundImage: imageUrl ? `url(${imageUrl})` : '',
  };

  const finalPrice = price && `$${price.formattedPrice}`;

  const onClickHandler = () => {
    if (!isActive) return;

    setCardId(productOptionId);
    setIsClicked(true);

    setTimeout(() => {
      setIsClicked(false);
      selectProductOptionHandler(productOption);
    }, 700);
  };

  return (
    <animated.div
      style={{ ...animations }}
      onClick={onClickHandler}
      className={cn('product-card-container', {
        'is-active': isSelected,
        'is-disable': !isActive,
        'is-mounted': !isDesktop,
        'is-animated': isClicked,
      })}
    >
      {isDesktop || isTablet ? (
        <div className="product-wrapper">
          <div className="product-card">
            <div
              className={cn('product-card-img', {
                disabled: !imageUrl,
              })}
              style={imagePreviewStyle}
            />
            <Icon className="product-card-checked icon-checked" />
            {imageUrl && (
              <Icon className="product-card-info icon-info" />
            )}
          </div>
          <span className="product-card-title">{displayName}</span>
          <span className="product-card-price">{finalPrice}</span>
        </div>
      ) : (
        <div className="product-wrapper">
          <div
            className="product-card-mounted"
          >
            <span className="product-card-title">{displayName}</span>
            <span className="product-card-price">{finalPrice}</span>
          </div>
          <Icon
            className={cn('product-card-info icon-info', {
              'is-active': isSelected,
            })}
          />
        </div>
      )}
    </animated.div>
  );
};

export default Card;
