import { useCallback } from 'react';

import getActualShapeSize from 'utils/editor/intersectionChecks/getActualShapeSize';
import shapePointsService from 'utils/editor/coordinates/ShapePointsService';
import rotateService from 'utils/editor/rotate/RotateService';

import { setCropObject } from 'redux/editor/editorReducer';
import { activeImageObjectSelector } from 'redux/gallery';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import cropService from 'utils/editor/crop/CropService';
import { ICropObject } from 'types/editor';

type Result = () => ICropObject | null;

const useCreateCropObject = (): Result => {
  const { imageWrapperRef, croppedImageRef, mainLayerRef } =
    useEditorRefs();

  const activeImageObject = useAppSelector(activeImageObjectSelector);

  const dispatch = useAppDispatch();

  const createCropObject = useCallback(() => {
    const imageWrapper = imageWrapperRef.current;
    const croppedImage = croppedImageRef.current;
    const mainLayer = mainLayerRef.current;

    if (
      !croppedImage ||
      !imageWrapper ||
      !mainLayer ||
      !activeImageObject
    )
      return null;

    const {
      originalImageHeightInPixels,
      originalImageWidthInPixels,
    } = activeImageObject;

    const rotation = imageWrapper.rotation();

    const rad = rotateService.getRadians(-rotation);

    const imgWrapAbsPos = imageWrapper.getAbsolutePosition();
    const croppedImageAbsPos = croppedImage.getAbsolutePosition();

    const mainLayerScale = {
      x: mainLayer.scaleX(),
      y: mainLayer.scaleY(),
    };

    const imgWrapScale = {
      x: imageWrapper.scaleX(),
      y: imageWrapper.scaleY(),
    };

    const absImgWrapSize = getActualShapeSize(
      imageWrapper.size(),
      imgWrapScale,
      mainLayerScale,
    );

    const croppedImageScale = {
      x: croppedImage.scaleX(),
      y: croppedImage.scaleY(),
    };

    const actualCropSize = getActualShapeSize(
      croppedImage.size(),
      croppedImageScale,
      mainLayerScale,
    );

    const croppedImageCenter = {
      x: actualCropSize.width / 2 + croppedImageAbsPos.x,
      y: actualCropSize.height / 2 + croppedImageAbsPos.y,
    };

    const imgWrapperPos =
      shapePointsService.getRotatedPointByAnArbitraryAngle(
        imgWrapAbsPos,
        croppedImageCenter,
        rad,
      );

    const croppedImgAbsSize = getActualShapeSize(
      croppedImage.size(),
      croppedImageScale,
      mainLayerScale,
    );

    const imgWrapClientRect = {
      ...imgWrapperPos,
      ...absImgWrapSize,
    };

    const croppedImgClientRect = {
      ...croppedImgAbsSize,
      ...croppedImage.getAbsolutePosition(),
    };

    const cropObject = cropService.getCropObject(
      {
        width: originalImageWidthInPixels,
        height: originalImageHeightInPixels,
      },
      imgWrapClientRect,
      croppedImgClientRect,
    );


    dispatch(setCropObject(cropObject));

    return cropObject;
  }, [
    croppedImageRef,
    dispatch,
    imageWrapperRef,
    mainLayerRef,
    activeImageObject,
  ]);

  return createCropObject;
};

export default useCreateCropObject;
