import { ICoordinates } from 'types/editor';
import getLineExpression from './getLineExpression';

const isPointMoreLineExpression = (
  inP: ICoordinates,
  exP: ICoordinates,
  k: number,
): boolean => inP.y > getLineExpression(inP, exP, k);

export default isPointMoreLineExpression;
