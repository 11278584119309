import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";

import productOptionsService from 'utils/sidePanel/ProductOptionService';
import moveElemToEndOfArray from 'utils/general/moveElemToEndOfArray';
import getNextStepTitle from 'utils/general/getNextStepTitle';

import { ORIENTATION, SIZE } from 'constants/sidePanel/general';
import { SidebarPopupContext } from 'context/contexts/editor/general';
import useAppSelector from 'hooks/redux/useAppSelector';
import { optionGroupsSelector } from 'redux/sidePanel';
import StepControls from 'components/StepControls';

import SidebarHeaderMobile from '../components/SidebarHeaderMobile';
import SidebarBodyMobile from '../components/SidebarBodyMobile';
import SidebarFooter from '../components/SidebarFooter';
import FooterMobile from '../../FooterMobile';
import SidebarPopup from '../components/SidebarPopup';

const SidebarMobileContent: FC = () => {
  const [optionsSteps, setOptionsSteps] = useState<string[]>([SIZE]);
  const [showMenuOptions, setShowMenuOptions] = useState(true);

  const { showSidebarPopup } = useContext(SidebarPopupContext);

  const optionGroups = useAppSelector(optionGroupsSelector);

  const productOptionTitles = productOptionsService
    .getFilteredProductOptionGroups(optionGroups, [ORIENTATION])
    .map(({ displayName }) => displayName);

  const isEqualLength =
    optionsSteps.length === productOptionTitles.length;

  const nextStepDisplayName = getNextStepTitle(
    optionsSteps[optionsSteps.length - 1],
    productOptionTitles,
  );

  const returnToProductOptions = () => {
    setShowMenuOptions(true);
  }

  const isHideNextStep =
    isEqualLength &&
    optionsSteps[optionsSteps.length - 1] ===
    productOptionTitles[productOptionTitles.length - 1];

  const nextStepHandler = useCallback(() => {

    if (isHideNextStep) {
      returnToProductOptions()
      return
    }

    const newStepsOfOptions = moveElemToEndOfArray(
      nextStepDisplayName,
      optionsSteps,
    );

    setOptionsSteps(newStepsOfOptions);
    setShowMenuOptions(false);
  }, [nextStepDisplayName, optionsSteps, isHideNextStep]);

  const handleSelectStep = useCallback(
    (optionTitle: string, index: number) => {
      if (optionsSteps.includes(optionTitle)) {
        const newStepsOfOptions = moveElemToEndOfArray(
          optionTitle,
          optionsSteps,
        );
        setOptionsSteps(newStepsOfOptions);
        setShowMenuOptions(false);
        return;
      }

      const isAvailableToSelectOption =
        optionsSteps.length === index || isEqualLength;

      if (!isAvailableToSelectOption) return;

      setShowMenuOptions(false);
      setOptionsSteps([...optionsSteps, optionTitle]);
    },
    [isEqualLength, optionsSteps],
  );

  const handleBackToMenu = useCallback(() => {
    setShowMenuOptions(true);
  }, []);

  const nextStepContent = isHideNextStep ? 'Finish' : `NEXT TO ${nextStepDisplayName}`;

  return (
    <>
      <aside className="sidebar col col-lg-3">
        {showSidebarPopup && <SidebarPopup />}
        {showMenuOptions && (
          <SidebarHeaderMobile
            showOptionsListHandler={() => {}}
            optionsSteps={optionsSteps}
            handleSelectStep={handleSelectStep}
            productOptionTitles={productOptionTitles}
          />
        )}

        {!showMenuOptions && (
          <SidebarBodyMobile optionsSteps={optionsSteps} />
        )}
        {showMenuOptions && <FooterMobile />}
      </aside>
      {!showMenuOptions && (
        <StepControls
          className="step-controls steps"
          nextStepHandler={nextStepHandler}
          previousStepHandler={handleBackToMenu}
          nextStepContent={nextStepContent}
          previousStepContent="BACK TO MENU"
        />
      )}
      {showMenuOptions && <SidebarFooter />}
    </>
  );
};

export default SidebarMobileContent;
