import { ICoordinates, ISize } from 'types/editor';

const getActualShapeSize = (
  size: ISize,
  shapeScale: ICoordinates,
  layerScale: ICoordinates,
): ISize => ({
  width: size.width * shapeScale.x * layerScale.x,
  height: size.height * shapeScale.y * layerScale.y,
});

export default getActualShapeSize;
