import React from 'react';

import { InputTypes } from 'types/input';

interface IProps {
  id: string;
  label: string;
  checked: boolean;
  changeHandler: () => void;
}

const Switch: React.FC<IProps> = ({
  id,
  label,
  checked,
  changeHandler,
}) => (
  <div className="switch">
    <label className="switch-container" htmlFor={id}>
      {label}
      <input
        id={id}
        checked={checked}
        className="switch-input"
        type={InputTypes.CHECKBOX}
        onChange={changeHandler}
      />
      <div className="switch-slider" />
    </label>
  </div>
);

export default Switch;
