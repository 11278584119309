import cn from 'classnames';
import { FC } from 'react';

import { InputTypes } from 'types/input';

interface IProps {
  id: string;
  checked: boolean;
  isActive: boolean;
  labelContent: string;
}

const StepperItem: FC<IProps> = ({
  id,
  isActive,
  checked,
  labelContent,
}) => (
  <li
    className={cn('stepper-step', {
      'is-active': isActive,
    })}
  >
    <input
      id={id}
      type={InputTypes.RADIO}
      className="stepper-input"
      checked={checked}
      readOnly
    />
    <label htmlFor={id} className="stepper-label">
      {labelContent}
    </label>
  </li>
);

export default StepperItem;
