import { useCallback, useEffect } from 'react';
import { KonvaEventObject } from 'konva/lib/Node';

import { isSelectedProductSizeOptionSelector } from 'redux/sidePanel';
import { setIsTouchZoom } from 'redux/editor/editorReducer';
import { isTouchZoomSelector } from 'redux/editor';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import rotateIcons from 'constants/editor/rotateIcons';

import useEditMode from './useEditMode';

const useStageInteractions = (disabled = false): void => {
  const { stageRef } = useEditorRefs();

  const isTouchZoom = useAppSelector(isTouchZoomSelector);
  const isSelectedProductSizeOption = useAppSelector(
    isSelectedProductSizeOptionSelector,
  );

  const dispatch = useAppDispatch();

  const { isDesktop } = useDefineDeviceByWindowSize();
  const [, setIsEditMode] = useEditMode();

  const isEditModeController = useCallback(
    (id: string) => {
      const isProduct = id === 'product';

      const rotateIconsId = rotateIcons.map(
        (rotateIconData) => rotateIconData.id,
      );

      const isRotateIcon = rotateIconsId.includes(id);

      if (
        isSelectedProductSizeOption &&
        (isProduct || isRotateIcon)
      ) {
        return setIsEditMode(true);
      }

      return setIsEditMode(false);
    },
    [isSelectedProductSizeOption, setIsEditMode],
  );

  const stageTapHandler = useCallback(
    (e: KonvaEventObject<TouchEvent>) => {
      const id = e.target.id();

      if (isTouchZoom) {
        return dispatch(setIsTouchZoom(false));
      }

      return isEditModeController(id);
    },
    [dispatch, isEditModeController, isTouchZoom],
  );

  const onStageClick = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const id = e.target.id();

      isEditModeController(id);
    },
    [isEditModeController],
  );

  useEffect(() => {
    const stage = stageRef.current;

    if (!stage) return;

    const container = stage.container();

    if (!container) return;

    const containerParent = container.parentElement;

    if (!containerParent) return;

    const stageSize = {
      width: containerParent.offsetWidth,
      height: containerParent.offsetHeight,
    };

    stage.size(stageSize);
  }, [stageRef]);

  useEffect(() => {
    const stage = stageRef.current;

    if (!stage || !isDesktop || disabled) return () => {};

    stage.on('click', onStageClick);

    return () => {
      stage.off('click', onStageClick);
    };
  }, [onStageClick, stageRef, stageTapHandler, isDesktop, disabled]);

  useEffect(() => {
    const stage = stageRef.current;

    if (!stage || isDesktop || disabled) return () => {};

    stage.on('tap', stageTapHandler);

    return () => {
      stage.off('tap', stageTapHandler);
    };
  }, [stageRef, isDesktop, stageTapHandler, disabled]);
};

export default useStageInteractions;
