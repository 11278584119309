import { createSelector } from 'reselect';

import { State } from 'redux/types';

import { ICartState } from './types';

export const cartStateSelector = (state: State): ICartState =>
  state.cart;

export const cartSelector = createSelector(
  cartStateSelector,
  ({ cart }) => cart,
);

export const cartIdSelector = createSelector(
  cartSelector,
  ({ cartId }) => cartId,
);

export const lineItemsSelector = createSelector(
  cartSelector,
  ({ lineItems }) => lineItems,
);

export const lastLineItemSelector = createSelector(
  cartSelector,
  ({ lineItems }) => lineItems[lineItems.length - 1],
);

export const cartTotalsSelector = createSelector(
  cartSelector,
  ({ cartTotals }) => cartTotals,
);

export const isEmptyCartSelector = createSelector(
  lineItemsSelector,
  (lineItems) => !lineItems.length,
);

export const lineItemsLengthSelector = createSelector(
  lineItemsSelector,
  (lineItems) => lineItems.length,
);

export const isCartContainerSelector = createSelector(
  cartStateSelector,
  ({ isCartContainer }) => isCartContainer,
);
