import { DropzoneInputProps } from 'react-dropzone';
import { FC } from 'react';

import ContentButton from 'components/Buttons/ContentButton';
import { SECOND_FAZE } from 'constants/temp';
import useAlertModal from 'hooks/context/general/useAlertModal';

interface IProps {
  inputProps: DropzoneInputProps;
}

const MobileBlock: FC<IProps> = ({ inputProps }) => {
  const handleShowModal = useAlertModal();

  return (
    <>
      <span className="content-subtitle">
        Add Images from Photo Library to Continue
      </span>
      <label
        className="content-btn-file btn btn-main"
        htmlFor="drag-drop"
      >
        Choose File
        {/* add inputProps */}
        <input
          type="button"
          style={{ display: 'none' }}
          id="drag-drop"
          onClick={() =>
            handleShowModal(
              'Mobile coming soon. Please use tablet or desktop',
            )
          }
        />
      </label>
      {SECOND_FAZE && (
        <>
          <span className="content-subtitle">
            or Select from Cloud Services
          </span>
          <div className="content-drag">
            <ContentButton>
              <i className="icon-google-drive" />
            </ContentButton>
            <ContentButton>
              <i className="icon-dropbox" />
            </ContentButton>
          </div>
        </>
      )}
    </>
  );
};

export default MobileBlock;
