import { IField } from 'types/ui/checkout';

interface ITouchedField {
  [key: string]: boolean;
}

const defaultAcc: ITouchedField = {};

const setTouchedFields = (fields: IField[]): ITouchedField => {
  const touchedFields = fields.reduce(
    (acc, { id }) => ({
      ...acc,
      [id]: true,
    }),
    defaultAcc,
  );

  return touchedFields;
};

export default setTouchedFields;
