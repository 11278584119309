import { FC } from 'react';

import FooterControlsRotation from './components/FooterControlsRotation';
import FooterControlsZoom from './components/FooterControlsZoom';

const FooterControls: FC = () => (
  <div className="footer-controls">
    <FooterControlsRotation />
    <FooterControlsZoom />
  </div>
);

export default FooterControls;
