import { FC } from 'react';

import cartSidebarItems from 'constants/cart/cartSidebarItems';
import useAppSelector from 'hooks/redux/useAppSelector';
import { cartTotalsSelector } from 'redux/cart';
import { Children } from 'types/general';

import CartSidebarFooter from './CartSidebarFooter';
import CartSidebarItem from './CartSidebarItem';

interface IProps {
  children?: Children;
  isShowContent?: boolean;
}

const SidebarOrderInfo: FC<IProps> = ({
  children,
  isShowContent,
}: IProps) => {
  const cartTotals = useAppSelector(cartTotalsSelector);

  const updatedCartSidebarItems = cartSidebarItems.map(
    (cartSidebarItem) => ({
      ...cartSidebarItem,
      value: cartTotals?.[cartSidebarItem.id] || 0,
    }),
  );

  const CartSidebarItemsList = updatedCartSidebarItems.map(
    ({ id, title, value }, index, arr) => {
      const isLastItem = index === arr.length - 1;

      if (!children && isLastItem) {
        return (
          <CartSidebarFooter key={id} title={title} value={value} />
        );
      }

      const cartSidebarItemValue = isLastItem ? `$ ${value}` : value;

      return (
        <CartSidebarItem
          key={id}
          title={title}
          value={cartSidebarItemValue}
        />
      );
    },
  );

  return (
    <aside className="cart-sidebar sidebar col col-lg-3">
      {isShowContent && (
        <>
          <h2 className="cart-sidebar-title">order summary</h2>
          <div className="order-list">{children}</div>
          <ul className="cart-sidebar-list">
            {CartSidebarItemsList}
          </ul>
        </>
      )}
    </aside>
  );
};

export default SidebarOrderInfo;

SidebarOrderInfo.defaultProps = {
  children: undefined,
  isShowContent: true,
};
