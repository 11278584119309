import { FC } from 'react';

import Tooltip from 'components/Tooltip';
import Icon from 'components/Icons/Icon';

import { InputTypes } from 'types/input';
import { Void } from 'types/general';

interface IProps {
  isRemake: boolean;
  handleChangeRemake: Void;
}

const PayCheck: FC<IProps> = ({
  isRemake,
  handleChangeRemake,
}: IProps) => (
  <div className="check-col-middle pay-check col-14 order-3">
    <input
      id="billing"
      type={InputTypes.CHECKBOX}
      className="pay-check-input hidden"
      checked={isRemake}
      onChange={handleChangeRemake}
    />
    <label htmlFor="billing" className="pay-check-label pay-col">
      Remake
    </label>
    <Tooltip tooltip="Check “Remake” if there is a quality problem from H&#38;H.">
      <Icon className="check-info icon-info" />
    </Tooltip>
  </div>
);

export default PayCheck;
