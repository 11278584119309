/* eslint-disable react/prop-types */
import { Range, getTrackBackground } from 'react-range';
import { FC } from 'react';

import { ISliderProps } from 'types/slider';

export const RangeSlider: FC<ISliderProps> = ({
  min,
  max,
  value,
  onChange,
}: ISliderProps) => (
  <div className="range">
    <Range
      values={[value]}
      min={min}
      max={max}
      onChange={(el) => {
        if (el[0] <= min || el[0] >= max) return;

        onChange(el[0]);
      }}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          className="range-track"
          style={{
            ...props.style,
          }}
        >
          <div
            className="range-line"
            ref={props.ref}
            style={{
              background: getTrackBackground({
                values: [value],
                colors: ['#ffc600', '#b7b7b7'],
                min,
                max,
              }),
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          className="range-thumb"
          {...props}
          style={{
            ...props.style,
          }}
        />
      )}
    />
    {/* <output className="range-value">{value}</output> */}
  </div>
);
