/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IImageObject } from 'types/gallery';

import { IGalleryState } from './types';

const initialState: IGalleryState = {
  imageObjects: [],
  isGalleryPending: false,
  isShowGallery: false,
  activeImageObject: null,
  basedOrientationId: null,
};

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setActiveImageObject: (
      state,
      { payload }: PayloadAction<IImageObject>,
    ) => {
      state.activeImageObject = payload;
    },
    setImageObjects: (
      state,
      { payload }: PayloadAction<IImageObject[]>,
    ) => {
      state.imageObjects = payload;
    },
    addImageObject: (
      state,
      { payload }: PayloadAction<IImageObject>,
    ) => {
      state.imageObjects.push(payload);
    },
    deleteImageObject: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      const filteredImageObjects = state.imageObjects.filter(
        ({ imageGuid }) => imageGuid !== payload,
      );

      state.imageObjects = filteredImageObjects;
    },
    setIsGalleryPending: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isGalleryPending = payload;
    },
    removeActiveImageObject: (state) => {
      state.activeImageObject = null;
    },
    deleteImageObjects: (state) => {
      state.imageObjects = [];
    },
    setIsShowGallery: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowGallery = payload;
    },
    setBasedOrientationId: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.basedOrientationId = payload;
    },
  },
});

export const {
  setImageObjects,
  setIsShowGallery,
  setIsGalleryPending,
  setActiveImageObject,
  setBasedOrientationId,
  deleteImageObject,
  deleteImageObjects,
  addImageObject,
  removeActiveImageObject,
} = gallerySlice.actions;

export default gallerySlice.reducer;
