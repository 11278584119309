import { createContext } from 'react';

import { IConfirmPopupState } from 'types/editor/confirmPopupState';

export const EditorWindowContext = createContext({
  showEditorWindow: false,
  showEditorWindowHandler: (isShow: boolean) => {},
});

export const SidebarPopupContext = createContext({
  showSidebarPopup: false,
  showSidebarPopupHandler: (isShow: boolean) => {},
});

export const SettingsContext = createContext({
  showSettings: false,
  showSettingsHandler: (isShow: boolean) => {},
});

export const ConfirmPopupContext = createContext<
  (state: IConfirmPopupState) => void
>(() => {});
