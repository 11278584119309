import { useCallback, useEffect, useRef } from 'react';

import getActualShapePoints from 'utils/editor/intersectionChecks/getActualShapePoints';
import getActualShapeSize from 'utils/editor/intersectionChecks/getActualShapeSize';
import rotateService from 'utils/editor/rotate/RotateService';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { setIsResetCustomCorners } from 'redux/editor/editorReducer';
import { isResetCustomCornersSelector } from 'redux/editor';

import { ImageType, RectType } from 'types/editor/konvaTypes';
import { ImageRefType, RectRefType } from 'types/editor/refTypes';

import useRotateIcons from './useRotateIcons';
import useEditMode from '../useEditMode';

interface Result {
  customCornersRefs: RectRefType[];
  rotateIconsRefs: ImageRefType[];
}

const useSetupRotateIcons = (): Result => {
  const bottomRightCornerRef = useRef<RectType>(null);
  const bottomLeftCornerRef = useRef<RectType>(null);
  const topRightCornerRef = useRef<RectType>(null);
  const topLeftCornerRef = useRef<RectType>(null);

  const rightBottomRotateIconRef = useRef<ImageType>(null);
  const leftBottomRotateIconRef = useRef<ImageType>(null);
  const rightTopRotateIconRef = useRef<ImageType>(null);
  const leftTopRotateIconRef = useRef<ImageType>(null);

  const customCornersRefs = [
    topLeftCornerRef,
    topRightCornerRef,
    bottomRightCornerRef,
    bottomLeftCornerRef,
  ];

  const rotateIconsRefs = [
    leftTopRotateIconRef,
    rightTopRotateIconRef,
    rightBottomRotateIconRef,
    leftBottomRotateIconRef,
  ];

  const { imageWrapperRef, mainLayerRef } = useEditorRefs();

  const isResetCustomCorners = useAppSelector(
    isResetCustomCornersSelector,
  );

  const [isEditMode] = useEditMode();
  const dispatch = useAppDispatch();

  useRotateIcons(rotateIconsRefs, customCornersRefs);

  const getImageWrapperPoints = useCallback(() => {
    const imageWrapper = imageWrapperRef.current;
    const mainLayer = mainLayerRef.current;

    if (!mainLayer || !imageWrapper) return null;

    const imageWrapperScale = imageWrapper.scale();
    const mainLayerScale = mainLayer.scale();

    if (!imageWrapperScale || !mainLayerScale) return false;

    const rotation = imageWrapper.rotation();
    const rad = rotateService.getRadians(rotation);

    const imageSize = getActualShapeSize(
      imageWrapper.size(),
      imageWrapperScale,
      mainLayerScale,
    );

    const points = getActualShapePoints({
      rad,
      ...imageSize,
      ...imageWrapper.getAbsolutePosition(),
    });

    return points;
  }, [imageWrapperRef, mainLayerRef]);

  const setCustomCornersPosition = useCallback(() => {
    const topLeftCorner = topLeftCornerRef.current;
    const topRightCorner = topRightCornerRef.current;
    const bottomRightCorner = bottomRightCornerRef.current;
    const bottomLeftCorner = bottomLeftCornerRef.current;

    if (
      !topLeftCorner ||
      !topRightCorner ||
      !bottomRightCorner ||
      !bottomLeftCorner
    )
      return;

    const imageWrapperPoints = getImageWrapperPoints();

    if (!imageWrapperPoints) return;

    const { p1, p2, p3, p4 } = imageWrapperPoints;

    const topRightAbsPos = {
      x: p2.x - topRightCorner.width(),
      y: p2.y,
    };

    const bottomRightAbsPos = {
      x: p3.x - topRightCorner.width(),
      y: p3.y - topRightCorner.height(),
    };

    const bottomLeftAbsPos = {
      x: p4.x,
      y: p4.y - topRightCorner.height(),
    };

    topLeftCorner.setAbsolutePosition(p1);
    topRightCorner.setAbsolutePosition(topRightAbsPos);
    bottomRightCorner.setAbsolutePosition(bottomRightAbsPos);
    bottomLeftCorner.setAbsolutePosition(bottomLeftAbsPos);
  }, [
    getImageWrapperPoints,
    topLeftCornerRef,
    topRightCornerRef,
    bottomRightCornerRef,
    bottomLeftCornerRef,
  ]);

  const setRotateIconsPosition = useCallback(() => {
    const rightBottomRotateIcon = rightBottomRotateIconRef.current;
    const leftBottomRotateIcon = leftBottomRotateIconRef.current;
    const rightTopRotateIcon = rightTopRotateIconRef.current;
    const leftTopRotateIcon = leftTopRotateIconRef.current;

    if (
      !rightBottomRotateIcon ||
      !leftBottomRotateIcon ||
      !rightTopRotateIcon ||
      !leftTopRotateIcon
    )
      return;

    const imageWrapperPoints = getImageWrapperPoints();

    if (!imageWrapperPoints) return;

    const { p1, p2, p3, p4 } = imageWrapperPoints;

    const topLeftAbsPos = {
      x: p1.x,
      y: p1.y + leftTopRotateIcon.height(),
    };

    const topRightAbsPos = {
      x: p2.x - rightTopRotateIcon.width(),
      y: p2.y,
    };

    const bottomRightAbsPos = {
      x: p3.x,
      y: p3.y - rightBottomRotateIcon.height(),
    };

    const bottomLeftAbsPos = {
      x: p4.x + leftBottomRotateIcon.width(),
      y: p4.y,
    };

    leftTopRotateIcon.setAbsolutePosition(topLeftAbsPos);
    rightTopRotateIcon.setAbsolutePosition(topRightAbsPos);
    rightBottomRotateIcon.setAbsolutePosition(bottomRightAbsPos);
    leftBottomRotateIcon.setAbsolutePosition(bottomLeftAbsPos);
  }, [
    getImageWrapperPoints,
    rightBottomRotateIconRef,
    leftBottomRotateIconRef,
    rightTopRotateIconRef,
    leftTopRotateIconRef,
  ]);

  const setupRotateIcons = useCallback(() => {
    if (!isResetCustomCorners) return;

    setCustomCornersPosition();
    setRotateIconsPosition();

    dispatch(setIsResetCustomCorners(false));
  }, [
    dispatch,
    isResetCustomCorners,
    setCustomCornersPosition,
    setRotateIconsPosition,
  ]);

  useEffect(() => {
    if (!isEditMode) return;

    setupRotateIcons();
  }, [isEditMode, setupRotateIcons]);

  return {
    rotateIconsRefs,
    customCornersRefs,
  };
};

export default useSetupRotateIcons;
