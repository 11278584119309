import {
  ShapeType,
  LayerType,
  GroupType,
} from 'types/editor/konvaTypes';

interface ScaleOptions {
  scaleObject: ShapeType | LayerType | GroupType;
  isZoomOut: boolean;
  scaleX: number;
  scaleY?: number;
}

const scaleFromCenterPoint = ({
  scaleObject,
  isZoomOut,
  scaleX,
  scaleY = scaleX,
}: ScaleOptions): void => {
  const offsetX = scaleObject.width() / 2;
  const offsetY = scaleObject.height() / 2;

  const positionBeforeOffset = scaleObject
    .getAbsoluteTransform()
    .point({ x: 0, y: 0 });

  const { x, y } = scaleObject.getAbsolutePosition();

  scaleObject.offset({
    x: offsetX,
    y: offsetY,
  });

  scaleObject.setAbsolutePosition({ x, y });

  const positionAfterOffset = scaleObject
    .getAbsoluteTransform()
    .point({ x: 0, y: 0 });

  scaleObject.setAbsolutePosition({
    x: x - (positionAfterOffset.x - positionBeforeOffset.x),
    y: y - (positionAfterOffset.y - positionBeforeOffset.y),
  });

  if (isZoomOut) {
    scaleObject.scale({
      x: scaleObject.scaleX() - scaleX,
      y: scaleObject.scaleY() - scaleY,
    });
  }

  if (!isZoomOut) {
    scaleObject.scale({
      x: scaleObject.scaleX() + scaleX,
      y: scaleObject.scaleY() + scaleY,
    });
  }

  const actualPoint = scaleObject
    .getAbsoluteTransform()
    .point({ x: 0, y: 0 });

  scaleObject.offset({
    x: 0,
    y: 0,
  });

  scaleObject.setAbsolutePosition(actualPoint);
};

export default scaleFromCenterPoint;
