import { FC } from 'react';

import SidebarControlIcon from 'components/Icons/SidebarControlIcon';
import ControlButton from 'components/Buttons/ControlButton';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useAppSelector from 'hooks/redux/useAppSelector';

import productOptionService from 'utils/sidePanel/ProductOptionService';
import { optionGroupsSelector } from 'redux/sidePanel';

interface Props {
  iconName: string;
  displayName: string;
  index: number;
  optionsSteps: string[];
  handleSelectStep: (selectedOption: string, index: number) => void;
}

const SidebarOptionStep: FC<Props> = ({
  iconName,
  displayName,
  index,
  optionsSteps,
  handleSelectStep,
}: Props) => {
  const optionGroups = useAppSelector(optionGroupsSelector);

  const { isTablet } = useDefineDeviceByWindowSize();

  const isActive =
    optionsSteps[optionsSteps.length - 1] === displayName;

  const selectedProductOption =
    productOptionService.getSelectedProductOptionByType(
      optionGroups,
      displayName,
    );

  return (
    <ControlButton
      className="sidebar-control-btn"
      isActive={isActive}
      clickHandler={() => {
        handleSelectStep(displayName, index);
      }}
    >
      <SidebarControlIcon className={iconName} />
      <span className="sidebar-control-title">{displayName}</span>
      {isTablet && selectedProductOption && (
        <span className="sidebar-control-value">
          {selectedProductOption.displayName}
        </span>
      )}
    </ControlButton>
  );
};

export default SidebarOptionStep;
