import { FourPoints, IBoxValues } from 'types/editor';

import shapePointsService from '../coordinates/ShapePointsService';
import getDefaultPointsOfShape from './getDefaultPointsOfShape';

const getActualShapePoints = ({
  x,
  y,
  width,
  height,
  rad,
}: IBoxValues): FourPoints => {
  const pivot = { x, y };

  const {
    p1: defaultP1,
    p2: defaultP2,
    p3: defaultP3,
    p4: defaultP4,
  } = getDefaultPointsOfShape({
    width,
    height,
  });

  const p1 = shapePointsService.getAbsCornerPoint(
    pivot,
    defaultP1,
    rad,
  );

  const p2 = shapePointsService.getAbsCornerPoint(
    pivot,
    defaultP2,
    rad,
  );

  const p3 = shapePointsService.getAbsCornerPoint(
    pivot,
    defaultP3,
    rad,
  );

  const p4 = shapePointsService.getAbsCornerPoint(
    pivot,
    defaultP4,
    rad,
  );

  return {
    p1: { x: Math.round(p1.x), y: Math.round(p1.y) },
    p2: { x: Math.round(p2.x), y: Math.round(p2.y) },
    p3: { x: Math.round(p3.x), y: Math.round(p3.y) },
    p4: { x: Math.round(p4.x), y: Math.round(p4.y) },
  };
};

export default getActualShapePoints;
