import { FC } from 'react';
import cn from 'classnames';

import { Children, Event, Void } from 'types/general';

import GalleryUpload from '../GalleryUpload';

interface IProps {
  isOpen: boolean;
  children: Children;
  resetEditorPage: (callback?: Void) => void;
  hidePopupsHandler: (e: Event<HTMLElement>) => void;
}

const GalleryBody: FC<IProps> = ({
  isOpen,
  children,
  resetEditorPage,
  hidePopupsHandler,
}) => (
  <div className={cn('gallery-body', { 'is-opened': isOpen })}>
    <GalleryUpload
      resetEditorPage={resetEditorPage}
      hidePopupsHandler={hidePopupsHandler}
    />
    {children}
  </div>
);

export default GalleryBody;
