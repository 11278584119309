import { FC, ReactNode } from 'react';
import { Form } from 'formik';

import shippingAddressCols from 'constants/checkout/shippingAddress/shippingAddressCols';

import CheckoutTitle from 'pages/Checkout/components/CheckoutTitle';
import ColumnList from 'pages/Checkout/components/ColumnList';

interface IProps {
  isDropShip: boolean;
  children: ReactNode[];
}

const CheckBody: FC<IProps> = ({ isDropShip, children }: IProps) => {
  const [shippingOptionList, dropDownsList, StateDropdown] = children;

  return (
    <div className="check-body">
      <CheckoutTitle title="Shipping Address" />
      <span className="check-subtitle">
        Please, select a shipping option:
      </span>
      <Form>
        {shippingOptionList}
        {isDropShip && (
          <div className="check-shipping-inputs">
            <ColumnList columnList={shippingAddressCols} />
            {dropDownsList}
            {StateDropdown}
          </div>
        )}
      </Form>
    </div>
  );
};

export default CheckBody;
