import { forwardRef, useImperativeHandle, useState } from 'react';
import { IRef } from 'types/ref';

interface IProps {
  className: string;
}

const Loader = forwardRef<IRef, IProps>(({ className }, ref) => {
  const [state, setState] = useState(false);

  useImperativeHandle<IRef, IRef>(ref, () => ({
    show() {
      setState(true);
    },

    hide() {
      setState(false);
    },

    state,
  }));

  return state ? (
    <div className={className}>
      <div className="loader" />
    </div>
  ) : (
    <></>
  );
});

export default Loader;
