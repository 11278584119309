import urls from 'api/urls';
import axios from 'axios/config';

class ShippingAddressService {
  async getOrderShipping(sessionId: string): Promise<any | void> {
    try {
      const { data } = await axios.get(urls.cart.getOrderShipping, {
        params: {
          CartSessionId: sessionId,
        },
      });

      return data;
    } catch (error) {
      return console.log(error);
    }
  }

  async putOrderShipping(
    sessionId: string,
    cartId: number,
    body: any,
  ): Promise<any> {
    try {
      const response = await axios.put<any>(
        urls.cart.putOrderShipping,
        body,
        {
          params: {
            CartSessionId: sessionId,
            CartId: cartId,
          },
        },
      );

      return response.data;
    } catch (error) {
      return console.log(error);
    }
  }
}

export default new ShippingAddressService();
