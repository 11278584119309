import { FC } from 'react';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import ControlButton from 'components/Buttons/ControlButton';

import SidebarOptionStep from './SidebarOptionStep';

const temporaryTitles = [
  'icon-size',
  'icon-finish',
  'icon-mount',
  'icon-drilling',
  'icon-shaping',
];

interface IProps {
  optionsSteps: string[];
  handleSelectStep: (selectedOption: string, index: number) => void;
  showOptionsListHandler: () => void;
  productOptionTitles: string[];
}

const SidebarHeaderMobile: FC<IProps> = ({
  optionsSteps,
  handleSelectStep,
  showOptionsListHandler,
  productOptionTitles,
}) => {
  const { isTablet } = useDefineDeviceByWindowSize();

  const ProductOptionsTitlesList = productOptionTitles.map(
    (displayName, index) => (
      <SidebarOptionStep
        key={displayName}
        iconName={temporaryTitles[index]}
        displayName={displayName}
        optionsSteps={optionsSteps}
        handleSelectStep={handleSelectStep}
        index={index}
      />
    ),
  );

  return (
    <header className="sidebar-header-container">
      <div className="sidebar-header">{ProductOptionsTitlesList}</div>
      {isTablet && (
        <ControlButton
          className="sidebar-btn-expand"
          clickHandler={showOptionsListHandler}
        >
          <i className="sidebar-icon-expand icon-expand" />
        </ControlButton>
      )}
    </header>
  );
};

export default SidebarHeaderMobile;
