/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FC, useCallback } from 'react';
import cn from 'classnames';

import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { productCategories } from 'constants/sidePanel/productCategories';
import {
  getProductAsync,
  productCategoriesStatusSelector,
  productCategorySelector,
  productQuantitySelector,
  selectedProductOptionsSelector,
} from 'redux/sidePanel';

const Submenu: FC = () => {
  const dispatch = useAppDispatch();

  const productCategory = useAppSelector(productCategorySelector);
  const productQuantity = useAppSelector(productQuantitySelector);
  const productCategoriesStatus = useAppSelector(
    productCategoriesStatusSelector,
  );
  const selectedProductOptions = useAppSelector(
    selectedProductOptionsSelector,
  );

  const handleClick = useCallback(
    (productCategoryTypeParam: string) => {
      const requestBodyParam = {
        quantity: productQuantity,
        selectedOptions: selectedProductOptions,
      };

      dispatch(
        getProductAsync({
          requestBodyParam,
          productCategoryTypeParam,
        }),
      );
    },
    [dispatch, productQuantity, selectedProductOptions],
  );

  const filteredProductCategories = productCategories.filter(
    ({ productCategoryType }, index) =>
      productCategoryType ===
        productCategoriesStatus[index].productCategoryType &&
      productCategoriesStatus[index].isActive,
  );

  return (
    <ul className="submenu">
      {filteredProductCategories.map(
        ({ displayName, productCategoryId, productCategoryType }) => {
          const isSelected =
            productCategory?.productCategoryId === productCategoryId;

          return (
            <li
              key={productCategoryId}
              className={cn('submenu-item', {
                'is-active': isSelected,
              })}
              onClick={() => handleClick(productCategoryType)}
            >
              {displayName}
            </li>
          );
        },
      )}
      <li className="submenu-item">All Wall Displays</li>
    </ul>
  );
};

export default Submenu;
