import { FC } from 'react';

interface IProps {
  title: string;
  value: number;
}

const CartSidebarFooter: FC<IProps> = ({ title, value }) => (
  <footer className="cart-sidebar-footer">
    <span className="cart-sidebar-total">{title}</span>
    <span className="cart-sidebar-total">{`$ ${value}`}</span>
  </footer>
);

export default CartSidebarFooter;
