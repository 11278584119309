import OrderInformation from 'pages/Checkout/pages/OrderInformation';
import ShippingAddress from 'pages/Checkout/pages/ShippingAddress';
import Summary from 'pages/Checkout/pages/Summary';

import CHECKOUT_ROUTES from './routes';

const checkoutRouteList = [
  {
    path: CHECKOUT_ROUTES.ORDER_INFORMATION,
    component: OrderInformation,
  },
  {
    path: CHECKOUT_ROUTES.SHIPPING_ADDRESS,
    component: ShippingAddress,
  },
  {
    path: CHECKOUT_ROUTES.SUMMARY,
    component: Summary,
  },
];

export default checkoutRouteList;
