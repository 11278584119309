import { useContext } from 'react';

import { EditorRefsContext } from 'context/contexts/editor/canvas';
import { EditorRefsType } from 'types/editor/refTypes';

const useEditorRefs = (): EditorRefsType => {
  const context = useContext(EditorRefsContext);

  if (!context) {
    throw new Error('Editor refs context used outside');
  }

  return context;
};

export default useEditorRefs;
