import { FC } from 'react';

interface IProps {
  shipping?: any;
}

const ShipTo: FC<IProps> = ({ shipping }: IProps) => (
  <div className="confirm-ship">
    <span className="confirm-info">
      <b>Ship To: </b>
      {shipping?.firstName &&
        `${shipping.firstName}, ${shipping.lastName},
        ${shipping.address1}, ${shipping.city},
        ${shipping.state}, ${shipping.zip}`}
    </span>
  </div>
);

ShipTo.defaultProps = {
  shipping: null,
};

export default ShipTo;
