import {
  PIXELS_PER_METER,
  ROOM_SIZE_IN_METERS,
} from 'constants/editor/room';

const convertMetersToPixels = (...meters: number[]): number[] =>
  meters.map(
    (meter) => (meter / ROOM_SIZE_IN_METERS) * PIXELS_PER_METER,
  );

export default convertMetersToPixels;
