import { FC } from 'react';
import cn from 'classnames';

import { Children, Event, IPrice, Void } from 'types/general';

interface ISelectedOption {
  price?: IPrice | null;
  displayName: string;
}

interface IProps {
  title: string;
  color?: string;
  disable: boolean;
  isColor?: boolean;
  children: Children;
  showSidebarSettings?: boolean;
  showColorPickerHandler?: Void;
  selectedOption: ISelectedOption | null;
  showSidebarSettingsHandler?: (isShow: boolean) => void;
}

const SidebarSettings: FC<IProps> = ({
  color,
  title,
  isColor,
  disable,
  children,
  selectedOption,
  showSidebarSettings,
  showColorPickerHandler,
  showSidebarSettingsHandler,
}) => {
  const onEventHandler = (e: Event<HTMLDivElement>) => {
    if (!showSidebarSettingsHandler) return;

    const elem = e.target as HTMLElement;

    if (isColor && elem.tagName === 'SPAN') {
      if (!showColorPickerHandler) return;

      showColorPickerHandler();
      return;
    }

    showSidebarSettingsHandler(!showSidebarSettings);
  };

  const price = `$${selectedOption?.price?.formattedPrice || ''}`;

  const displayName = selectedOption
    ? selectedOption.displayName
    : 'Unselected';

  return (
    <div
      className={cn('sidebar-settings', {
        'is-disabled': disable,
        'is-open': showSidebarSettings,
      })}
    >
      <div
        tabIndex={0}
        role="button"
        onClick={onEventHandler}
        onKeyDown={onEventHandler}
        className="sidebar-settings-header"
      >
        <h2 className="sidebar-settings-title">
          {showSidebarSettings && 'Choose '}
          {title}
        </h2>
        {!showSidebarSettings && (
          <span
            className="sidebar-settings-checked"
            style={{ backgroundColor: color }}
          >
            {!isColor && displayName}
            {!isColor && (
              <span className="sidebar-settings-price">{price}</span>
            )}
          </span>
        )}
      </div>
      {showSidebarSettings && children}
    </div>
  );
};

SidebarSettings.defaultProps = {
  color: '',
  isColor: false,
  showSidebarSettings: true,
  showColorPickerHandler: () => {},
  showSidebarSettingsHandler: () => {},
};

export default SidebarSettings;
