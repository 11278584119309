import { FC, useContext } from 'react';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import { SidebarPopupContext } from 'context/contexts/editor/general';

import SidebarPopup from './components/SidebarPopup';
import SidebarContent from './SidebarContent';
import SidebarTabletContent from './SidebarTabletContent';
import SidebarMobileContent from './SidebarMobileContent';

const Sidebar: FC = () => {
  const { showSidebarPopup } = useContext(SidebarPopupContext);

  const { isDesktop, isTablet, isMobile } =
    useDefineDeviceByWindowSize();

  if (isMobile) {
    return <SidebarMobileContent />;
  }

  return (
    <aside className="sidebar col col-lg-3">
      {showSidebarPopup && <SidebarPopup />}
      {isDesktop && <SidebarContent />}
      {isTablet && <SidebarTabletContent />}
    </aside>
  );
};

export default Sidebar;
