import {
  FourPoints,
  IClientRect,
  ICoordinates,
  ISize,
} from 'types/editor';

class ShapePointsService {
  getShapeCenterPoint({ p1, p2, p3, p4 }: FourPoints): ICoordinates {
    return {
      x: Math.round((p1.x + p2.x + p3.x + p4.x) / 4),
      y: Math.round((p1.y + p2.y + p3.y + p4.y) / 4),
    };
  }

  getAbsCornerPoint(
    pivot: ICoordinates,
    diff: ICoordinates,
    rad: number,
  ): ICoordinates {
    const distance = Math.sqrt(diff.x * diff.x + diff.y * diff.y);

    const radDiff = rad + Math.atan2(diff.y, diff.x);

    const absCornerPoint = {
      x: pivot.x + distance * Math.cos(radDiff),
      y: pivot.y + distance * Math.sin(radDiff),
    };

    return absCornerPoint;
  }

  getShapeAlignmentPoint(
    parentSize: ISize,
    shapeSize: ISize,
  ): ICoordinates {
    const shapeAlignmentPoint = {
      x: parentSize.width / 2 - shapeSize.width / 2,
      y: parentSize.height / 2 - shapeSize.height / 2,
    };

    return shapeAlignmentPoint;
  }

  getTotalBox(box: IClientRect): IClientRect {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    minX = Math.min(minX, box.x);
    minY = Math.min(minY, box.y);
    maxX = Math.max(maxX, box.x + box.width);
    maxY = Math.max(maxY, box.y + box.height);

    const clientRect = {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    };

    return clientRect;
  }

  getFreeCenterPoint({ width, height }: ISize) {
    const freeCenterPoint = {
      x: width / 2,
      y: height / 2,
    };

    return freeCenterPoint;
  }

  getCoordinatesDiff(
    firstPoint: ICoordinates,
    secondPoint: ICoordinates,
  ) {
    const xDiff = Math.abs(firstPoint.x - secondPoint.x);
    const yDiff = Math.abs(firstPoint.y - secondPoint.y);

    return {
      x: xDiff,
      y: yDiff,
    };
  }

  getRotatedPointByAnArbitraryAngle(
    startPoint: ICoordinates,
    centerOfShape: ICoordinates,
    rad: number,
  ) {
    return {
      x:
        (startPoint.x - centerOfShape.x) * Math.cos(rad) -
        (startPoint.y - centerOfShape.y) * Math.sin(rad) +
        centerOfShape.x,
      y:
        (startPoint.x - centerOfShape.x) * Math.sin(rad) +
        (startPoint.y - centerOfShape.y) * Math.cos(rad) +
        centerOfShape.y,
    };
  }
}

export default new ShapePointsService();
