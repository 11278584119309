import { useCallback, useLayoutEffect, useState } from 'react';

import { WINDOW_SIZES } from 'constants/windowSizes';

interface Devices {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

const useDefineDeviceByWindowSize = (): Devices => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const updateSize = useCallback(
    () =>
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    [],
  );

  const isDesktop = size.width >= WINDOW_SIZES.TABLET;

  const isTablet =
    size.width < WINDOW_SIZES.TABLET &&
    size.width >= WINDOW_SIZES.MOBILE;

  const isMobile = size.width < WINDOW_SIZES.MOBILE;

  useLayoutEffect(() => {
    updateSize();

    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  return {
    isDesktop,
    isTablet,
    isMobile,
  };
};

export default useDefineDeviceByWindowSize;
