import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import shapePointsService from 'utils/editor/coordinates/ShapePointsService';
import imageSizeService from 'utils/editor/sizes/ImageSizeService';
import productOptionService from 'utils/sidePanel/ProductOptionService';

import { ORIENTATION, SIZE } from 'constants/sidePanel/general';
import { STAGE_PADDING } from 'constants/editor/general';

import StepControls from 'components/StepControls';

import { originalCroppedImageSizeSelector } from 'redux/editor';
import { optionGroupsSelector } from 'redux/sidePanel';
import {
  activeImageObjectSelector,
  isShowGallerySelector,
} from 'redux/gallery';

import useEditorRefs from 'hooks/context/editor/useEditorRefs';
import useAppSelector from 'hooks/redux/useAppSelector';
import useEditMode from 'hooks/editor/useEditMode';

import SidebarHeaderMobile from '../components/SidebarHeaderMobile';
import SidebarBodyMobile from '../components/SidebarBodyMobile';
import SidebarFooter from '../components/SidebarFooter';
import Footer from '../../Footer';

const SidebarTabletContent: FC = () => {
  const [optionsSteps, setOptionsSteps] = useState<string[]>([SIZE]);
  const [startContainerHeight, setStartContainerHeight] = useState<
    null | number
  >(null);
  const [showOptionsList, setOptionsList] = useState(true);

  const { stageRef, imageWrapperRef, croppedImageRef } =
    useEditorRefs();

  const activeImageObject = useAppSelector(activeImageObjectSelector);
  const isShowGallery = useAppSelector(isShowGallerySelector);
  const optionGroups = useAppSelector(optionGroupsSelector);
  const originalCroppedImageSize = useAppSelector(
    originalCroppedImageSizeSelector,
  );

  const [isEditMode] = useEditMode();

  const showOptionsListHandler = useCallback(
    () => setOptionsList(!showOptionsList),
    [showOptionsList],
  );

  const isDisableNextStep =
    !productOptionService.getSelectedProductOptionByType(
      optionGroups,
      optionsSteps[optionsSteps.length - 1],
    );

  const filteredProductOptionGroups =
    productOptionService.getFilteredProductOptionGroups(
      optionGroups,
      [ORIENTATION],
    );

  const productOptionTitles = filteredProductOptionGroups.map(
    ({ displayName }) => displayName,
  );

  const nextStepHandler = useCallback(() => {
    const nextProductOption =
      productOptionTitles[optionsSteps.length];

    setOptionsSteps([...optionsSteps, nextProductOption]);
  }, [optionsSteps, productOptionTitles]);

  const previousStepHandler = useCallback(() => {
    const indexOfPreviousOption = optionsSteps.length - 2;

    setOptionsSteps([
      ...optionsSteps.filter(
        (_, index) => indexOfPreviousOption >= index,
      ),
    ]);
  }, [optionsSteps]);

  const nextStepContent = `Continue to ${
    productOptionTitles[optionsSteps.length]
  }`;

  const isHideNextStep =
    optionsSteps.length === productOptionTitles.length;

  const isHidePreviousStep = optionsSteps.length === 1;

  const isShowSidePanel = !activeImageObject && !isShowGallery;

  useEffect(() => {
    const contentContainer = document.querySelector(
      '.content-container',
    );

    const containerHeight = contentContainer?.clientHeight;

    if (!containerHeight || !activeImageObject) return;

    setStartContainerHeight(containerHeight);
  }, [activeImageObject]);

  useEffect(() => {
    const imageWrapper = imageWrapperRef.current;
    const croppedImage = croppedImageRef.current;
    const stage = stageRef.current;

    if (
      !stage ||
      !croppedImage ||
      !imageWrapper ||
      !activeImageObject ||
      !startContainerHeight ||
      !originalCroppedImageSize
    )
      return;

    const contentContainer = document.querySelector(
      '.content-container',
    );

    const containerWidth = contentContainer?.clientWidth;
    const containerHeight = contentContainer?.clientHeight;

    if (!containerWidth || !containerHeight) return;

    const stageSize = showOptionsList
      ? {
          width: containerWidth,
          height: startContainerHeight,
        }
      : {
          width: containerWidth,
          height: containerHeight,
        };

    stage.size(stageSize);

    if (!isEditMode) {
      const responsiveImageSize =
        imageSizeService.getResponsiveImageSizeByOrientation(
          originalCroppedImageSize,
          stageSize,
          STAGE_PADDING,
        );

      croppedImage.size(responsiveImageSize);

      const centerPosition =
        shapePointsService.getShapeAlignmentPoint(
          stageSize,
          responsiveImageSize,
        );

      croppedImage.setAbsolutePosition(centerPosition);
    }
  }, [
    stageRef,
    isEditMode,
    imageWrapperRef,
    croppedImageRef,
    showOptionsList,
    activeImageObject,
    startContainerHeight,
    originalCroppedImageSize,
  ]);

  return (
    <>
      {(isShowSidePanel || !isShowGallery) && (
        <>
          <SidebarHeaderMobile
            handleSelectStep={() => {}}
            optionsSteps={optionsSteps}
            showOptionsListHandler={showOptionsListHandler}
            productOptionTitles={productOptionTitles}
          />
          {showOptionsList && (
            <>
              <SidebarBodyMobile optionsSteps={optionsSteps} />
              <StepControls
                isLeftSide={!isHideNextStep}
                className="step-controls option-list"
                nextStepHandler={nextStepHandler}
                previousStepHandler={previousStepHandler}
                isDisableNextStep={isDisableNextStep}
                nextStepContent={nextStepContent}
                previousStepContent="Back"
                isHideNextStep={isHideNextStep}
                isHidePreviousStep={isHidePreviousStep}
              />
            </>
          )}
        </>
      )}
      <Footer />
      <SidebarFooter />
    </>
  );
};

export default SidebarTabletContent;
