import cn from 'classnames';
import { FC, memo } from 'react';

import { ButtonType } from 'components/Buttons/enums';
import Button from 'components/Buttons/Button';

import { IStepValues } from 'types/steps';

const StepControls: FC<IStepValues> = memo(
  ({
    isSubmit,
    className,
    isLeftSide,
    isRightSide,
    nextStepHandler,
    nextStepContent,
    isHideNextStep,
    isHidePreviousStep,
    previousStepHandler,
    previousStepContent,
    isDisableNextStep,
  }: IStepValues) => {
    return (
      <div className={className}>
        {!isHidePreviousStep && (
          <Button
            isSecondary
            clickHandler={previousStepHandler}
            className={cn('step-controls-btn', {
              'left-side': isLeftSide,
            })}
          >
            {previousStepContent}
          </Button>
        )}
        {!isHideNextStep && (
          <Button
            clickHandler={nextStepHandler}
            isDisabled={isDisableNextStep}
            className={cn('step-controls-btn btn-main', {
              'right-side': isRightSide,
            })}
            type={isSubmit ? ButtonType.SUBMIT : ButtonType.BUTTON}
          >
            {nextStepContent}
          </Button>
        )}
      </div>
    )
  },
);

StepControls.defaultProps = {
  isHideNextStep: false,
  isHidePreviousStep: false,
  isDisableNextStep: false,
  isSubmit: false,
};

export default StepControls;
