import { createContext } from 'react';
import { Void } from 'types/general';

export interface ILoaderContext {
  handleShowLoader: Void;
  handleHideLoader: Void;
}

const LoaderContext = createContext<ILoaderContext | null>(null);

export default LoaderContext;
