import { useCallback, useContext } from 'react';

import getNewPosOfShapeDuringDragIntersectWithoutRotation from 'utils/editor/intersectionChecks/shapeIntersectionChecks/getNewPosOfShapeDuringDragIntersectWithoutRotation';
import { CropUpdateContext } from 'context/contexts/editor/canvas';
import useEditorRefs from 'hooks/context/editor/useEditorRefs';

import useShapeIntersectionChecks from './intersections/useShapeIntersectionChecks';
import usePrevValues from './intersections/usePrevValues';

type DragMoveHandler = () => null | void;

const usePreventDragIntersections = (): DragMoveHandler => {
  const { stageRef, mainLayerRef, croppedImageRef, imageWrapperRef } =
    useEditorRefs();
  const cropUpdate = useContext(CropUpdateContext);

  const getIsShapeIntersection = useShapeIntersectionChecks();
  const { setPrevValues, prevImageWrapperPosition } = usePrevValues();

  const dragMoveHandler = useCallback(() => {
    const imageWrapper = imageWrapperRef.current;
    const croppedImage = croppedImageRef.current;
    const mainLayer = mainLayerRef.current;
    const stage = stageRef.current;

    if (
      !mainLayer ||
      !imageWrapper ||
      !croppedImage ||
      !stage ||
      !prevImageWrapperPosition
    )
      return null;

    const rotation = imageWrapper.rotation();

    if (!rotation) {
      const newPos =
        getNewPosOfShapeDuringDragIntersectWithoutRotation(
          imageWrapper.getClientRect(),
          croppedImage.getClientRect(),
        );

      imageWrapper.setAbsolutePosition(newPos);

      setPrevValues();

      return cropUpdate();
    }

    const isShapeIntersection = getIsShapeIntersection();

    if (isShapeIntersection) {
      imageWrapper.setAbsolutePosition(prevImageWrapperPosition);
    } else {
      setPrevValues();
    }

    return cropUpdate();
  }, [
    cropUpdate,
    croppedImageRef,
    imageWrapperRef,
    getIsShapeIntersection,
    mainLayerRef,
    prevImageWrapperPosition,
    setPrevValues,
    stageRef,
  ]);

  return dragMoveHandler;
};

export default usePreventDragIntersections;
