import React from 'react';

import { IIconProps } from '../types';

const Icon: React.FC<IIconProps> = ({
  className,
  clickHandler,
}: IIconProps) => (
  <i className={`icon ${className}`} onClick={clickHandler} />
);

Icon.defaultProps = {
  clickHandler: () => {},
};

export default Icon;
