import { useHistory } from 'react-router-dom';
import { FC, useEffect } from 'react';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';
import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { setIsCartContainer } from 'redux/cart/cartReducer';
import { lineItemsSelector } from 'redux/cart';

import CHECKOUT_ROUTES from 'constants/routes/checkout/routes';
import SidebarOrderInfo from 'components/SidebarOrderInfo';
import CheckoutRouter from 'routes/CheckoutRouter';
import { ILineItem } from 'types/cart';

import OrderSummary from './components/OrderSummary';
import Stepper from './components/Stepper';

const Checkout: FC = () => {
  const lineItems = useAppSelector(lineItemsSelector);

  const { location } = useHistory();
  const dispatch = useAppDispatch();

  const { isMobile } = useDefineDeviceByWindowSize();

  const isSummaryPage = location.pathname === CHECKOUT_ROUTES.SUMMARY;

  const LineItemsList = lineItems.map((lineItem: ILineItem) => (
    <OrderSummary key={lineItem.lineItemId} {...lineItem} />
  ));

  useEffect(() => {
    dispatch(setIsCartContainer(false));
  }, [dispatch]);

  return (
    <>
      <div className="col checkout">
        {!isMobile && <Stepper />}
        <CheckoutRouter />
      </div>
      <SidebarOrderInfo isShowContent={!isSummaryPage}>
        {LineItemsList}
      </SidebarOrderInfo>
    </>
  );
};

export default Checkout;
