import { FC, useEffect } from 'react';
import cn from 'classnames';

import AlertModalContextProvider from 'context/providers/general/AlertModalContextProvider';
import LoaderContextProvider from 'context/providers/general/LoaderContextProvider';

import useAppDispatch from 'hooks/redux/useAppDispatch';
import useAppSelector from 'hooks/redux/useAppSelector';

import { getProductCatalogAsync } from 'redux/sidePanel';
import { getImageObjectsAsync } from 'redux/gallery';
import {
  sessionIdSelector,
  getSessionIdAsync,
  isCreatedCartSelector,
} from 'redux/auth';
import {
  createCartAsync,
  getCartAsync,
  isCartContainerSelector,
} from 'redux/cart';

import Header from 'components/Header';
import AppRouter from 'routes/AppRouter';
import EditorCoreContextProvider from "context/providers/canvas/EditorCoreContextProvider";

const Layout: FC = () => {
  const isCartContainer = useAppSelector(isCartContainerSelector);
  const isCreatedCart = useAppSelector(isCreatedCartSelector);
  const sessionId = useAppSelector(sessionIdSelector);

  const dispatch = useAppDispatch();

  const mainClassName = cn('main-container', {
    'cart-container': isCartContainer,
  });

  // get sessionId
  useEffect(() => {
    if (sessionId) return;

    dispatch(getSessionIdAsync());
  }, [dispatch, sessionId]);

  // get gallery images
  useEffect(() => {
    if (!sessionId) return;

    dispatch(getImageObjectsAsync(sessionId));
  }, [dispatch, sessionId]);

  // create cart
  useEffect(() => {
    if (isCreatedCart || !sessionId) return;

    dispatch(createCartAsync(sessionId));
  }, [dispatch, isCreatedCart, sessionId]);

  // if cart already exist, get cart
  useEffect(() => {
    if (!isCreatedCart) return;

    dispatch(getCartAsync());
  }, [dispatch, isCreatedCart]);

  useEffect(() => {
    if (!sessionId) return;

    dispatch(getProductCatalogAsync(sessionId));
  }, [sessionId, dispatch]);

  return (
    <AlertModalContextProvider>
      <LoaderContextProvider>
        <EditorCoreContextProvider>
          <Header />
          <main className={mainClassName}>
            <AppRouter />
          </main>
        </EditorCoreContextProvider>

      </LoaderContextProvider>
    </AlertModalContextProvider>
  );
};

export default Layout;
