import { KeyboardEvent, Ref } from 'react';

import { Event } from './general';

export interface IInputDropZoneProps {
  accept: string;
  autoComplete: string;
  multiply: boolean;
  onChange: () => void;
  onClick: () => void;
  ref: Ref<HTMLInputElement>;
  style: { display: string };
  tabIndex: number;
  type: InputTypes;
}

export interface IInputProps {
  id?: string;
  className?: string;
  isHidden?: boolean;
  type?: InputTypes | undefined;
  value?: string | number;
  keyPressHandler?: (e: KeyboardEvent) => void;
  changeHandler?: (e: Event<HTMLInputElement>) => void;
  uploadProps?: IInputDropZoneProps;
  placeholder?: string;
  checked?: boolean;
  name?: string;
  readOnly?: boolean;
  disabled?: boolean;
}

export enum InputTypes {
  TEXT = 'text',
  PASSWORD = 'password',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  NUMBER = 'number',
}
