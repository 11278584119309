import * as Yup from 'yup';

import {
  password,
  userName,
} from 'constants/checkout/orderInformation/accountInformationCols';
import {
  description,
  studio,
} from 'constants/checkout/orderInformation/orderInformationCols';

import isEmptyField from '../errorMessages/isEmptyField';

const OrderInformationSchema = Yup.object().shape({
  [password.id]: isEmptyField(),
  [userName.id]: isEmptyField(),
  [studio.id]: isEmptyField(),
  [description.id]: isEmptyField(),
});

export default OrderInformationSchema;
