import cn from 'classnames';
import { FC, memo } from 'react';
import { Event } from 'types/general';

type HandleCLick = (
  event: Event<HTMLDivElement>,
  imageGuid: string,
) => void;

interface GalleryCardProps {
  imageGuid: string;
  imageUrl: string;
  selectedImages: string[];
  originalFileName: string;
  isSelected: boolean;
  handleSelectImage: HandleCLick;
  countInCart: number;
}

const GalleryCard: FC<GalleryCardProps> = memo(
  ({
    imageGuid,
    imageUrl,
    isSelected,
    countInCart,
    selectedImages,
    originalFileName,
    handleSelectImage,
  }: GalleryCardProps) => (
    <div
      id="gallery-card-container"
      className={cn('gallery-card multiple', {
        selected: isSelected,
        'multi-selected': selectedImages.includes(imageGuid),
      })}
      onClick={(e) => handleSelectImage(e, imageGuid)}
    >
      <div className="gallery-cart">{countInCart}</div>
      <div className="gallery-img-container">
        <img src={imageUrl} alt="img" className="gallery-img" />
      </div>
      <span className="gallery-card-title">{originalFileName}</span>
    </div>
  ),
);

export default GalleryCard;
