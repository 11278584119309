import { FC } from 'react';

import { IButtonProps } from '../types';
import { ButtonType } from '../enums';

const ContentControlButton: FC<IButtonProps> = ({
  type,
  children,
  className,
  isDisabled,
  clickHandler,
}: IButtonProps) => (
  <button
    type={type}
    disabled={isDisabled}
    onClick={clickHandler}
    className={`content-control-btn ${className}`}
  >
    {children}
  </button>
);

ContentControlButton.defaultProps = {
  type: ButtonType.BUTTON,
  isDisabled: false,
  clickHandler: () => {},
};

export default ContentControlButton;
