import { FC } from 'react';

import Button from 'components/Buttons/Button';
import Icon from 'components/Icons/Icon';

import { Void } from 'types/general';

interface IProps {
  hidePopupHandler: Void;
  continueOrderHandler: Void;
}

const SuccessPopup: FC<IProps> = ({
  hidePopupHandler,
  continueOrderHandler,
}) => (
  <>
    <div className="popup">
      <button className="popup-close" onClick={hidePopupHandler}>
        <Icon className="popup-icon icon-times" />
      </button>
      <Icon className="popup-img icon-order" />
      <span className="popup-message">
        Thank you! Your order has been sent and you should receive a
        confirmation email shortly
      </span>
      <Button
        className="popup-apply-btn btn-main"
        clickHandler={continueOrderHandler}
      >
        Continue ordering
      </Button>
    </div>
    <div className="popup-shadow" />
  </>
);

export default SuccessPopup;
