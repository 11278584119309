import { FC, memo } from 'react';

import useDefineDeviceByWindowSize from 'hooks/useDefineDeviceByWindowSize';

interface IProps {
  title: string;
}

const CheckoutTitle: FC<IProps> = memo(({ title }: IProps) => {
  const { isMobile } = useDefineDeviceByWindowSize();

  return (
    <>
      {!isMobile ? (
        <h2 className="check-title">{title}</h2>
      ) : (
        <span className="check-mob-title">
          <b>Checkout</b> ({title})
        </span>
      )}
    </>
  );
});

export default CheckoutTitle;
